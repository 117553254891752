import http from "./Endpoint";
import appConstant from "constant";

class UserService {

  projection = {
    "order_by": {
      "name": "ASC"
    },
    "limit": 100000,
    "offset": 0
  }


  userlist(data) {
    let article = {
      "filter": {
          "customer_id": appConstant.CUSTOMER_ID,
          "is_active": 1,
          "first_name": data.first_name,
          "last_name": data.last_name,
          "email": null,
          "representation": data.representation,
          "user_level_id": data.user_level_id,
          "department_id": data.department_id
      },
      "projection": {
          "order_by": {
              "id" : "DESC",
              "created": "ASC"
          },
          "limit": data.limit ,
          "offset": data.offset
      },
      "return" : [
          "id",
          "first_name",
          "last_name",
          "email",
          "is_approver",
          "user_level_id",
          "user_level",
          "department_list",
          "addresses"
      ],
      "extra": {
          "found_rows": 1
      }
  }
    return http().post("/user/list", article);
  }
  UserGet(userid) {
    
    let article = {
      "filter": {
        "id": userid
      },
      "return": [
        "id",
        "customer_id",
        "first_name",
        "last_name",
        "email",
        "title",
        "representation",
        "password",
        // Optional
        "department_list",
        "addresses"
        // "user_variables"
      ]
    }
    return http().post("/user/query", article);
  }

  UserUpdate(data) {
    return http().post("/user/update", data);
  }
  UserDelete(data) {
    return http().post("/user/delete", data);
  }
  UserAdd(data) {
    return http().post("/user/add", data);
  }
  UserAddressAdd(data) {
    return http().post("/address/add", data);
  }
 

}

export default new UserService();