import React, { useState, useEffect } from "react";
import { Button, Col, Row, Form, InputGroup, Card, Breadcrumb, ListGroup, Select } from "react-bootstrap";
import { useNavigate, useParams } from 'react-router-dom';
import CostCenterService from "../../api/precision/CostCenterService";
import { FieldValidation } from "common/functions";
import appConstant from "constant";
import swal from 'sweetalert';
import UsertService from "../../api/precision/UserService";

function CostCenterDetail() {

  const navigation = useNavigate();
  let { pagekey } = useParams();
  const [deptoptions, setdeptoptions] = useState([]);
  const [buttonname, Setbuttonname] = useState('Save');
  const [selected, setSelected] = useState([]);
  const breadcrumbText = pagekey === 0 || pagekey === undefined ? 'Add a New Cost Center' : `Modify Cost Center / ${pagekey}`;
  const headertext = pagekey === 0 || pagekey === undefined ? 'Add a New Cost Center' : `Modify Cost Center Name`;
  useEffect(() => {
    document.title = pagekey === 0 || pagekey === undefined ? 'Add a New Cost Center' : `Modify Cost Center`;
  }, []);

  const [formData, SetFormData] = useState({
    "id": 0,
    "name": null,
    "is_active": 1,
    "department_list": []
  });

  //#region Bind DropDown
  useEffect(() => {
    DepartmentBind();
  }, []);
  const DepartmentBind = () => {
    UserService.departmentdropdownlist()
      .then((response) => {
        // Extract the deptoptions from the API response
        const { data } = response.data;
        const departmentOptions = data.map((department) => ({
          value: department.id.toString(),
          label: department.name,
        }));

        // Prepend the "ALL" option to the list
        //const allOption = { value: '0', label: 'ALL' };
        setdeptoptions([...departmentOptions]);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }
  //#endregion

  //#region GET
  useEffect(() => {
    if (pagekey > 0) {

      setCost();
      SetFormData({ ...formData, id: pagekey })
      Setbuttonname('Update');
    }
  }, [pagekey]);

  function setCost() {
    if (pagekey === undefined) {
      return;
    }

    CostCenterService.CostGet(pagekey)
      .then((response) => {
        if (response.data.result == 1 && response.data.data.id > 0) {
          SetFormData((response.data.data));
          if (Array.isArray(response.data.data.department_list) && response.data.data.department_list.length > 0) {
            const selectedValues = response.data.data.department_list.map((dept) => dept.id.toString()); // Assuming ID is a string
            setSelected(selectedValues);
            SetFormData({ ...response.data.data, department_list: selectedValues });
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching Cost  data:", error);
      });
  }
  //#endregion 
  //#region  Update/Add
  const handleChanged = (evt) => {

    const value = evt.target.value;
    const field = evt.target.name;
    if (field == "department_list") {
      const selectedValues = Array.from(evt.target.selectedOptions, (option) => option.value);
      setSelected(selectedValues);
      SetFormData({ ...formData, department_list: selectedValues });
    }
    else {
      SetFormData({
        ...formData,
        [evt.target.name]: value,
      });
    }
  };

  const CostSave = (ev) => {
    ev.stopPropagation();

    let requiredFields = [
      "name",
      "department_list"
    ]
    let res = FieldValidation(requiredFields);
    if (res.result) {

      if (pagekey > 0) {//update
        if (res.result) {
          const article = {
            "id": formData.id,
            "name": formData.name,
            "is_active": 1,
            "department_list": formData.department_list
          }
          CostCenterService.CostUpdate(article)
            .then((result) => {

              if (result.data.result === 1) {
                navigation('/CostCenter/CostCenterReport/');
                swal('Cost Center Updated!', '', 'success');
              }
            })
            .catch((error) => {
              console.error("Error in  Cost Center update api:", error);
            });
        }
      }
      else {//Add
        if (res.result) {
          const article = {
            "customer_id": appConstant.CUSTOMER_ID,
            "name": formData.name,
            "is_active": 1,
            "department_list": formData.department_list
          }
          CostCenterService.CostAdd(article)
            .then((result) => {

              if (result.data.result === 1) {
                navigation('/CostCenter/CostCenterReport/');
                console.log("Cost Center Added Successfully.");
                swal('Cost Center Added Successfully!', '', 'success');
              }
            })
            .catch((error) => {
              console.error("Error in  Cost Center added api:", error);
            });
        }
      }
    }
  };
  //#endregion


  return (
    <>
      <Breadcrumb className="p-3">
        <Breadcrumb.Item href="/CostCenter/CostCenterReport">Cost Center</Breadcrumb.Item>
        <Breadcrumb.Item active>{breadcrumbText}</Breadcrumb.Item>
      </Breadcrumb>
      <h3 className="userheader">{headertext}</h3>
      <Card>
        <Card.Body className="col-lg-12 row">
          <Col lg={3}></Col>
          <Col lg={9}>

            <Col className="pb-4 order-report-form small">

              <Row className="mb-2">
                <Col lg={3} className="align-self-sm-center or-form-label" htmlFor="name">
                  <Form.Label htmlFor="name">
                    Cost Center Name
                  </Form.Label>
                 
                </Col>
                <Col lg={9} className="">
                  <Form.Control
                    type="text"
                    className="col-sm-5 form-control form-control-sm"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChanged}
                    required
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={3} className="align-self-sm-center or-form-label" htmlFor="department_list">
                <Form.Label htmlFor="department_list">
                  Departments
                </Form.Label>
                  
                </Col>
                <Col lg={9} className="">
                  <Form.Select
                    multiple
                    className="col-md-9"
                    id="department_list"
                    name="department_list"
                    selected={selected}
                    value={formData.department_list}
                    onChange={handleChanged}
                  >
                    {deptoptions && deptoptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
              <Row className="row mt-4">
                <Col
                  lg={9}
                  className=""
                >
                  &nbsp;
                </Col>
                <Col lg={2} className="d-flex ">
                  <div className="d-flex gap-3 flex-end">
                    <Button
                      className="btn btn-primary"
                      onClick={CostSave}
                    >
                      {buttonname}
                    </Button>
                  </div>
                </Col>
              </Row>

            </Col>
          </Col>

        </Card.Body>
      </Card>
    </>
  );
}

export default CostCenterDetail;
