import http from "./Endpoint";
import appConstant from "constant";

class ProductService {
  projection = {
    "order_by": {
      "name": "ASC"
    },
    "limit": 100,
    "offset": 0
  }

  addpractice(article) {
    return http().post("/user/addpractice", article);
  }

  updatepractice(article) {
    return http().post("/user/updatepractice", article);
  }

  query(id) {
    let article = {
      "filter": {
        "id": id
      },
      "return": [
        "id",
        "created",
        "customer_id",
        "name",
        "quickbooks_type_id",
        "customer_sku",
        "min_quantity",
        "max_quantity",
        "additional_info",
        "package_quantity",
        "is_customizable",
        "is_mailable",
        "no_upload",
        "show_inventory",
        "use_quantities_dropdown",
        "is_in_inventory",
        "no_order_below_zero_inventory",
        "pick_fees",
        "quantity_list",
        "modifier",
        "category",
        "actual_inventory",
        "estimate_delivery_date",
        "apparel_sizes",
        "price_ranges"
      ]
    }
    return http().post("/product/query", article);
  }

  addsupport(article) {
    return http().post("/support/add", article);
  }

  productcategory(data) {
    let article = { "filter": data }
    return http().post("/product/categories", article);
  }

  productDestroy(article) {
    return http().post("/product/destroy", article);
  }

  productlist(data) {
    let article = {
      "filter": {
        // required
        "customer_id": appConstant.CUSTOMER_ID,
        "product_category_id": data.product_category_id,
        "quickbooks_type_id": data.quickbooks_type_id,
        "name": data.name,
        "is_active": 1,
        "exclude_no_product": 1
      },
      // Optional
      "projection": {
        "order_by": {
          "name": "ASC"
        },
        "limit": 500,
        "offset": 0
      },
      // Optional
      "return": [
        "id",
        "name",
        "product_category_id",
        "customer_sku",
        "is_in_inventory",
        "quickbooks_type_id",
        "actual_inventory",
        "additional_info"
        // "min_quantity", 
        // "max_quantity", 
        // "additional_info", 
        // "package_quantity", 
        // "is_customizable", 
        // "is_mailable", 
        // "no_upload", 
        // "show_inventory",
        // "use_quantities_dropdown", 
        // "quickbooks_type_id", 
        // "customer_sku",
        // "no_order_below_zero_inventory",
        // "estimate"
      ]
    }
    return http().post("/product/list", article);
  }

  categoriesdropdownlist() {
    let article = {
      "filter": {
        "customer_id": appConstant.CUSTOMER_ID,
        // exclude product category that does not have product 
        "exclude_no_product": 0
      },
      "return": [
        "id",
        "name"
      ]
    }
    return http().post("/product/categories", article);
  }

  productDelete(data) {
    return http().post("/product/delete", data);
  }

  productUpdate(data) {
    return http().post("/product/update", data);
  }

  productEnumdropdown() {
    let article = {
      "category": "quickbook_types"
    }
    return http().post("/product/enum", article);
  }

}

export default new ProductService();