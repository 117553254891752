import React, { useState, useEffect } from "react";
import { useShoppingCart } from './ShoppingCartContext';
import "./cart.css";
import { Link, useNavigate } from 'react-router-dom';
import { removeSpecialCharacters } from '../../common/functions'

const Preview_Order = () => {

  const navigation = useNavigate();
  const { addToCart, incrementQuantity, decrementQuantity, totalItem, totalQty, cartItems, calculateGrandTotal, removeFromCart, emptyCart } = useShoppingCart();

  const handleRemoveItem = (itemId) => {
    removeFromCart(itemId);
  };

  useEffect(() => {
    document.title = 'Cart';

    console.log(cartItems);
  }, []);
  const handleEmptyCart = () => {
    emptyCart();
    navigation('/');
  };
  const handleContinueShopping = () => {
    navigation('/');
  };
  const handleincrementQuantity = (pid) => {
    incrementQuantity(pid);
  };
  const handleCheckOut = () => {
    navigation('/cart/shipping/');
  };

  const handleImageError = (e) => {
    e.target.src = '/dist/img/noimages.png'; // Provide the path to your default or empty image
  };

  return (
    <>
      <section className="preview_order_section login_section vh-100">
        <div className='container'>
          <div className="row">
            <div className="col-lg-12">
              <div className="preview_order_main">
                <h1>Preview Order</h1>
                <br></br>
                {cartItems != "" ? (
                  <>
                    <table className="table-blue"  >
                      <thead>
                        <tr>
                          <th className="" style={{ width: "50%", paddingleft: "5%!important" }}>Product/Description</th>
                          <th className="text-center" style={{ width: "30%" }}>Image</th>
                          <th className="text-center" style={{ width: "15%" }}>Quantity</th>
                          <th className="text-center" style={{ width: "5%" }}></th>
                        </tr>
                      </thead>
                    </table>

                    <table className="table table-borderless rounded-table ">
                      <tbody>

                        {cartItems.map((product) => (
                          <>
                            <tr key={product.id}>
                              <td className="table-data" style={{ width: "50%" }}>
                                <Link
                                  className="education_a text-black"
                                  to={'/Product/' + product.id}
                                >
                                  {removeSpecialCharacters(product.name)}
                                </Link>
                              </td>
                              <td className="table-data text-center" style={{ width: "30%" }}> <img className="grid-image" src={"https://www.precisionservicesgroup.com/img/products/thumbs/" + product.id + ".jpg"}
                                onError={handleImageError}
                                alt="iamge" /></td>
                              <td className="table-data text-center" style={{ width: "15%" }}>{totalItem(product.id)} </td>
                              <td className="table-data text-center" style={{ width: "5%" }}>
                                <i
                                  className="fas fa-trash-alt remove" onClick={() => handleRemoveItem(product.id)}
                                ></i>
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <>
                    <br></br>
                    <br></br>
                    <div className="col-lg-12 text-center">
                      <h1>Your cart is empty!</h1>
                    </div><hr />
                  </>
                )}

                <div className="row">
                  <div className="col-12 d-flex justify-content-between">
                    <div className="button-group">  {
                      totalQty() > 0 &&
                      <button onClick={() => handleEmptyCart()} className="button-product m-1">Empty Cart</button>
                    }
                      <button onClick={() => handleContinueShopping()} className="button-product m-1">Continue Shopping</button>
                    </div>
                    {
                      totalQty() > 0 &&
                      <button onClick={() => handleCheckOut()} className="ml-auto button-product m-1">Check Out</button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Preview_Order;