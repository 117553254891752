import React, { useState, useEffect } from "react";
import { Button, Col, Breadcrumb, Row, Form, InputGroup, Card } from "react-bootstrap";
import { useParams, useNavigate, Link } from 'react-router-dom';
import UserService from "../../api/precision/UserService";
import DepartmentService from "../../api/precision/DepartmentService";
import { FieldValidation, isValidEmail, validatePasswords } from "common/functions";
import appConstant from "constant";
import swal from 'sweetalert';
import InputMask from 'react-input-mask';
import ProductService from "api/precision/ProductService";

function UserEdit() {

  const navigation = useNavigate();
  let { pagekey } = useParams();
  const [deptoptions, setdeptoptions] = useState([]);
  const breadcrumbText = pagekey === 0 || pagekey === undefined ? 'Add User' : `ModifyUser / ${pagekey}`;
  const headertext = pagekey === 0 || pagekey === undefined ? 'Add a New User' : `Modify User`;

  useEffect(() => {
    document.title = pagekey === 0 || pagekey === undefined ? 'Add a New User' : `Modify User`;
  }, []);

  const [formData, SetFormData] = useState({
    id: '',
    practice_name: "",
    email: "",
    phone: "",
    contact: "",
    line1: "",
    city: "",
    state: "",
    zip_code: "",
  });


  //#regionGET
  useEffect(() => {
    if (pagekey > 0) {
      setUser();
      SetFormData({ ...formData, id: pagekey })
    }
  }, [pagekey]);

  function setUser() {
    if (pagekey === undefined) {
      return;
    }
    UserService.UserGet(pagekey)
      .then((response) => {
        if (response.data.result == 1 && response.data.data.id > 0) {
          const practice = {
            id: response.data.data.id,
            practice_name: response.data.data.representation ? response.data.data.representation : response.data.data.addresses[0].title,
            email: response.data.data.email,
            phone: response.data.data.phone,
            contact: response.data.data.addresses[0].name,
            line1: response.data.data.addresses[0].line1,
            city: response.data.data.addresses[0].city,
            state: response.data.data.addresses[0].state,
            zip_code: response.data.data.addresses[0].zip_code,
          }
          SetFormData(practice);
        }
      })
      .catch((error) => {
        console.error("Error fetching Userdata:", error);
      });
  }
  //#endregion 

  //#regionUpdate/Add
  const handleChanged = (evt) => {
    const value = evt.target.value;
    const name = evt.target.name;
    SetFormData({
      ...formData,
      [evt.target.name]: value,
    });
  };


  const UserUpdate = (ev) => {
    ev.stopPropagation();
    let requiredFields = [
      "practice_name",
      "email",
      "phone",
      "contact",
      "line1",
      "city",
      "state",
      "zip_code",
    ];

    let res = FieldValidation(requiredFields);
    if (res.result) {
      if (pagekey > 0) {//update
        if (res.result) {
          ProductService.updatepractice(formData)
            .then((res) => {
              if (res.data.result == 1) {
                navigation('/userreport/');
              }
              else {
                alert("Error occurs while saving practice");
              }
            })
            .catch((error) => {
              console.error("Error inUser update api:", error);
            });
        }
      }
      else {//Add
        if (res.result) {
          ProductService.addpractice(formData)
            .then((res) => {
              if (res.data.result == 1) {
                navigation('/userreport/');
              }
              else {
                alert("Error occurs while saving practice");
              }
            })
            .catch((error) => {
              console.error("Error inUser added api:", error);
            });
        }
      }
    }

  };

  //#endregion

  return (
    <div className="container-lg">
      <Breadcrumb className="p-3">
        <Breadcrumb.Item href="/userreport">Users</Breadcrumb.Item>
        <Breadcrumb.Item active>{breadcrumbText}</Breadcrumb.Item>
      </Breadcrumb>
      <h5 className="userheader">{headertext}</h5>

      <Card>
        <Card.Body className="">
          <h6 className="usertitle">Practice Information</h6>
          <Row className="mb-2">
            <Col sm={6}>
              <Row className="mb-2">
                <Col sm={4} className="">
                  <Form.Label htmlFor="practice_name">
                    Practice name*
                  </Form.Label>

                </Col>
                <Col sm={8} className="">
                  <Form.Control
                    type="text"
                    className="form-control-sm"
                    id="practice_name"
                    name="practice_name"
                    value={formData.practice_name}
                    onChange={handleChanged}
                  />
                  <div className="invalid-feedback">First name is required</div>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col sm={4} className="">
                  <Form.Label htmlFor="email">
                    Email*
                  </Form.Label>
                </Col>
                <Col sm={8} className="">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="email@example.com"
                    className="form-control-sm form-control"
                    pattern="[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}"
                    value={formData.email}
                    onChange={handleChanged}
                    required
                  />
                  <div className="invalid-feedback">Invalid email address</div>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col sm={4} className="">
                  <Form.Label htmlFor="phone">
                    Phone*
                  </Form.Label>
                </Col>
                <Col sm={8} className="">
                  <InputMask
                    mask="999-999-9999"
                    name="phone"
                    id="phone"
                    className="form-control-sm form-control"
                    placeholder="Phone"
                    value={formData.phone}
                    onChange={handleChanged}
                  />
                  <div className="invalid-feedback">First name is required</div>
                </Col>
              </Row>
            </Col>

            <Col sm={6}>
              <Row className="mb-2">
                <Col sm={4} className="">
                  <Form.Label htmlFor="attention">
                    Contact*
                  </Form.Label>
                </Col>
                <Col sm={8} className="">
                  <input
                    type="text"
                    id="contact"
                    name="contact"
                    className="form-control-sm form-control"
                    value={formData.contact}
                    onChange={handleChanged}
                    required
                  />
                  <div className="invalid-feedback">Contact is required</div>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col sm={4} className="">
                  <Form.Label htmlFor="line1">
                    Address*
                  </Form.Label>
                </Col>
                <Col sm={8} className="">
                  <input
                    type="text"
                    id="line1"
                    name="line1"
                    className="form-control-sm form-control"
                    value={formData.line1}
                    onChange={handleChanged}
                    required
                  />
                  <div className="invalid-feedback">Address is required</div>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col sm={4} className="">
                  <Form.Label htmlFor="city">
                    City*
                  </Form.Label>
                </Col>
                <Col sm={8} className="">
                  <input
                    type="text"
                    id="city"
                    name="city"
                    className="form-control-sm form-control"
                    value={formData.city}
                    onChange={handleChanged}
                    required
                  />
                  <div className="invalid-feedback">City is required</div>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col sm={4} className="">
                  <Form.Label htmlFor="state">
                    State*
                  </Form.Label>
                </Col>
                <Col sm={8} className="">
                  <select className="form-control form-control-sm"
                    value={formData.state}
                    id="state" name="state"
                    required
                    onChange={handleChanged}
                  >
                    <option value="" >  </option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DC">District Columbia</option>
                    <option value="DE">Delaware</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                  <div className="invalid-feedback">State is required</div>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col sm={4} className="">
                  <Form.Label htmlFor="zip_code">
                    Postal Code*
                  </Form.Label>
                </Col>
                <Col sm={8} className="">
                  <input
                    type="text"
                    id="zip_code"
                    name="zip_code"
                    className="form-control-sm form-control"
                    value={formData.zip_code}
                    onChange={handleChanged}
                    required
                  />
                  <div className="invalid-feedback">Your address is not deliverable.</div>
                </Col>
              </Row>
            </Col>

          </Row>

          <Row className="row mt-4">
            <Col
              sm={9}
              className="d-flex justify-content-between d-none d-lg-flex"
            >
              &nbsp;
            </Col>
            <Col sm={2} className="d-flex ">
              <div className="d-flex gap-3 flex-end">
                <Button
                  className="btn btn-primary"
                  onClick={UserUpdate}
                >
                  Save
                </Button>
              </div>
            </Col>
          </Row>

        </Card.Body>
      </Card>
    </div>
  );
}

export default UserEdit;
