import React from 'react';
import { Link } from 'react-router-dom';



/**
 * Application 404 page component
 * @returns {JSX}
 */
const Page404 = () => {
    return (
        <div className="alert alert-default-danger card card-body w3-display-middle">
            <h1 className="w3-jumbo w3-animate-top w3-center"><code>Access Denied</code></h1>
            <hr className="w3-border-white w3-animate-left" style={{ margin: 'auto', width: '50%' }} />
            <h3 className="w3-center w3-animate-right">You dont have permission to view this site.</h3>
            <h3 className="w3-center w3-animate-zoom">????????</h3>
            <h6 className="w3-center w3-animate-zoom"><strong>Error Code</strong>: 403 forbidden</h6>
            <h1 className='text-center'>
                <Link to='/' className='btn btn-danger'>Go Home</Link>
            </h1>
        </div>
    );
};

export default Page404;

