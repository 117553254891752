import React, { useState, useEffect } from 'react'
import "./cart.css";
import { removeSpecialCharacters } from '../../common/functions';
import { getProfile } from '../../services/AuthService';
import moment from 'moment';
import { useParams, useNavigate } from 'react-router-dom';
import OrderService from 'api/precision/OrderService';
import { useShoppingCart } from './ShoppingCartContext';
import { OlderThan } from 'common/functions';

export default function Order_Submitted() {

  const userDetail = JSON.parse(getProfile());
  const { orderId, orderType } = useParams();
  const [pageTitle, setPageTitle] = useState("");
  const [orderDetail, setOrderDetail] = useState(null);
  const { addToCart } = useShoppingCart();
  const navigation = useNavigate();

  useEffect(() => {
    document.title = 'Order Detail';
    if (orderId) {
      if (orderType == 1) {
        setPageTitle("Your Order Has Been Submitted");
      }
      else if (orderType == 2) {
        setPageTitle("Your Order Details");
      }
      getOrderDetail(orderId);
    }
  }, []);

  const getOrderDetail = (id) => {
    OrderService.query(id)
      .then((response) => {
        if (response.data.result == 1) {
          setOrderDetail(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
      });
  }

  const handleImageError = (e) => {
    e.stopPropagation();
    e.preventDefault();
    e.target.src = '/dist/img/noimages.png'; // Provide the path to your default or empty image
  };

  const handlePrint = (e) => {
    e.stopPropagation();
    e.preventDefault();
    window.print();
  }

  const handleReorder = (e) => {
    e.stopPropagation();
    e.preventDefault();
    orderDetail.order_products.map((product) => {
      const item = {
        id: product.product_id,
        name: product.product_name,
      }
      addToCart(item, product.quantity);
    });
    navigation("/cart/preview");
  }

  const handleCancel = (e) => {
    e.stopPropagation();
    e.preventDefault();

    debugger;
    let ok = confirm("Are you sure you want to cancel order?")
    if (ok) {
      let data = {
        "order_id": orderDetail.id,
        "user_id": userDetail.user_id,
        "partial": false
      }
      OrderService.cancel(data)
        .then((response) => {
          if (response.data.result == 1) {

          }
        })
        .catch((error) => {
          console.error("Error fetching product data:", error);
        });
    }
  }

  return (
    <>
      <section className="preview_order_section order_submitted">
        <div className="px-3 py-2">
          <div className="row mb-2">
            <div className="col-12">
              <div className="preview_main">
                <h1>{pageTitle}</h1>
              </div>
            </div>
          </div>

          <div className="row justify-content-between" >
            <div className="col-5">
              <div className="">
                <h5 style={{ color:"#0047bb", "fontWeight": "bold"}}>Delivery To:</h5>
                <div>
                  {orderDetail && orderDetail.shipping_address.name}
                </div>
                <div>
                  {orderDetail && orderDetail.shipping_address.line1}
                </div>
                <div >
                  {orderDetail && orderDetail.shipping_address.city}, {orderDetail && orderDetail.shipping_address.state}, {orderDetail && orderDetail.shipping_address.zip_code}
                </div>
              </div>
            </div>

            <div className="col-6 ">
                <div className="">
                <h5 style={{ color: "#0047bb", "fontWeight": "bold" }}>Order Number: <span style={{color:"#000"}}> {orderId}
                  </span></h5>
                  <div>Order Date: {orderDetail && moment(orderDetail.order_created).format('MM/DD/YYYY H:MM A') + " PST"}</div>
                  <div>Order Status: {orderDetail && orderDetail.order_status}</div>
                <div>{orderDetail && orderDetail.shipping_instruction && "Note: " + orderDetail.shipping_instruction}</div>
                  <br></br>
                  <br></br>
                  {
                    orderType == 1 && (
                      <>
                        <h5 style={{ color: "#0047bb", "fontWeight": "bold" }}>Confirmation: </h5>
                        <p>You will receive a confirmation email.<br />
                        If you need assistance with your order, contact us by clicking Customer Service.</p>
                      </>
                    )
                  }
                  <br></br><br></br>
                </div>
            </div>

          </div>

          <div className="row">
            <div className="col-12">
              <div className="">
                <table className="table-blue">
                  <thead>
                    <tr>
                      <th style={{ width: "40%" }}>Product/Description</th>
                      <th className="text-center" style={{ width: "10%" }}>Item ID</th>
                      <th className="text-center" style={{ width: "25%" }}>Estimated Ship Date</th>
                      <th className="text-center" style={{ width: "25%" }}>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderDetail && orderDetail.order_products && orderDetail.order_products.map((product) => (
                      <tr key={product.id}>
                        <td className="p-1" style={{ width: "40%" }}>
                          {product.product_name}</td>
                        <td className="p-1 text-center" style={{ width: "10%" }}>
                          {product.id}</td>
                        <td className="p-1 text-center" style={{ width: "25%" }}>
                          {moment(product.estimated_delivery_date).format('MM/DD/YYYY')}
                        </td>
                        <td className="text-center" style={{ width: "25%" }}>{product.quantity} </td>
                      </tr>
                    ))}

                    {orderDetail && orderDetail.cancel_products && orderDetail.cancel_products.map((product) => (
                      <tr key={product.id}>
                        <td className="p-1 text-danger" style={{ width: "40%" }}>
                          {product.product_name}</td>
                        <td className="p-1 text-center text-danger" style={{ width: "10%" }}>
                          {product.order_product_id}</td>
                        <td className="p-1 text-center text-danger" style={{ width: "25%" }}>
                          {moment(product.estimated_delivery_date).format('MM/DD/YYYY')}
                        </td>
                        <td className="text-center text-danger" style={{ width: "25%" }}>{product.quantity} </td>
                      </tr>
                    ))}


                  </tbody>
                </table>

                <div className="container-1 no-print">
                  <div className="row">
                    <div className="col-12 d-flex justify-content-between">
                      <div className="button-group">
                      </div>
                      <div className="ml-auto">
                        {
                          /*
                          orderDetail && orderDetail.order_status == "Shipped" &&
                          <button title="Place order item(s) in your cart" className="button-product m-1 text-bold" onClick={handleReorder}><b>Reorder</b></button>
                          */
                        }
                        {
                          orderDetail && orderDetail.order_status == "Active" && OlderThan(orderDetail.created, 2) &&
                          <button title="Cancel Order" className="button-product m-1 text-bold" style={{ "backgroundColor": "#e7cfcf"}} onClick={handleCancel}><b>Cancel Order</b></button>
                        }
                        <button title="Print Order" className="button-product m-1 text-bold" onClick={handlePrint}><b>Print Receipt</b></button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </section>
    </>
  )
}
