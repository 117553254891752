import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import OrderService from 'api/precision/OrderService';
import { TotalFooter } from '../hooks/usePagination';

export default function ProductDetailTable({ productId, setActiveView }) {
  const [productDetail, setProductDetail] = useState(null);

  useEffect(() => {
    if (productId) {
      OrderService.listOrderProducts(productId)
        .then((response) => {
          if (response.data.result == 1) {
            // console.log(response.data.data);
            setProductDetail(response.data.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching product data:", error);
        });
    }
  }, [productId]);


  const handlePrint = (e) => {
    e.stopPropagation();
    e.preventDefault();
    window.print();
  }

  const handleNav = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveView({ view_type: "product_summary", owner_id: productId });
  }

  const columns = [
    {
      name: "Order ID",
      selector: (row) => <a href="#" onClick={(e) => { e.preventDefault(); e.stopPropagation(); setActiveView({ view_type: "order_detail", owner_id: row.order_id }) }}>{row.order_id}</a>,
      sortable: true,
    },
    {
      name: "Item ID",
      selector: (row) => row.order_product_id,
      sortable: true,
      width: "8rem",
    },
    {
      name: "Description",
      selector: (row) => row.product_name,
      sortable: true,
    },
    {
      name: "PO Number",
      selector: (row) => row.po_number,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Sold",
      selector: (row) => row.quantity,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Ending Inventory",
      selector: (row) => row.ending_inventory,
      sortable: true,
      width: "8rem",
    },
    {
      name: "PO Number",
      selector: (row) => row.po_number,
      sortable: true,
      width: "8rem",
    },
    {
      name: "Price",
      selector: (row) => row.total_price && "$" + row.total_price.toFixed(2),
      sortable: true,
      width: "8rem",
    },
    {
      name: "Shipping",
      selector: (row) => row.shipping_cost && "$" + row.shipping_cost.toFixed(2),
      sortable: true,
      width: "8rem",
    },
    {
      name: "Tax",
      selector: (row) => row.tax_amount && "$" + row.tax_amount.toFixed(2),
      sortable: true,
      width: "8rem", // Set the width to 150 pixels
    },
    {
      name: "Processing",
      selector: (row) => "$" + (row.pick_fee + row.pick_fee).toFixed(2),
      sortable: true,
      width: "8rem", // Set the width to 150 pixels
    },
    {
      name: "Total",
      selector: (row) => row.total && "$" + row.total.toFixed(2),
      sortable: true,
      width: "8rem", // Set the width to 150 pixels
    }
  ];

  if (productDetail && productDetail.length > 0) {
    return (
      <>
        <nav className="pt-3 no-print" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="#" onClick={handleNav}>Products</a></li>
            <li className="breadcrumb-item active" aria-current="page">{productId}</li>
          </ol>
        </nav>
        <DataTable
          className="overflow-visible"
          paginationPerPage={50}
          columns={columns}
          data={productDetail}
          defaultSortFieldID={1}
          responsive={true}
          pagination
          footer={
            <div>
              <TotalFooter data={productDetail} field="total" />
              <TotalFooter data={productDetail} field="total_price" />
              <TotalFooter data={productDetail} field="shipping_cost" />
              <TotalFooter data={productDetail} field="tax_amount" />
            </div>
          }
        />
      </>
    )
  }
  else {

  }
}
