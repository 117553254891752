import React, { useState } from "react";
import { Button, Col, Row, Form, Card, Breadcrumb } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import UserService from "../../api/precision/UserService";
import { FieldValidation } from "common/functions";
import appConstant from "constant";
import swal from 'sweetalert';
import InputMask from 'react-input-mask';

function DepartmentAddress() {
  const navigation = useNavigate();

  const [formData, SetFormData] = useState({
    "customer_id": 1850,
    // DEPARTMENT, USER, COMPANY
    "belong_to": "",
    // department_id, user_id, customer_id depends on belong_to
    "owner_id": 896,
    "dropdown_name": "",
    // Optional
    "company": "",
    "contact": "",
    "line1": "",
    "line2": "",
    "city": "",
    "state": "",
    "province": null,
    "zip_code": "",
    "country": "",
    "is_billing": 1,
    "is_public": 1
    // customer_id: appConstant.CUSTOMER_ID,
    // belong_to: "DEPARTMENT",
    // owner_id: 896,
    // dropdown_name: null,
    // company: '',
    // contact: null,
    // line1: null,
    // line2: null,
    // city: null,
    // state: 0,
    // province: null,
    // zip_code: null,
    // country: 0,
    // is_billing: 0,
    // is_public: 0
  });

  //#region  Address save
  const handleChanged = (ev) => {
    const field = ev.target.name;
    if (field == "is_billing" || field == "is_public") {
      SetFormData({ ...formData, [field]: ev.target.checked });
    }
    else {
      SetFormData({ ...formData, [field]: ev.target.value });
    }
  };

  const AddressAdd = (ev) => {
    ev.stopPropagation();
    let requiredFields = [
      "belong_to",
      "contact",
      "line1",
      "city",
      "state",
      "zip_code",
      "country",
    ]
    let res = FieldValidation(requiredFields);
    if (res.result) {
      const article = {
        "customer_id": appConstant.CUSTOMER_ID,
        "belong_to": formData.belong_to,
        "owner_id": formData.owner_id,
        "dropdown_name": formData.dropdown_name,
        "company": formData.company,
        "contact": formData.contact,
        "line1": formData.line1,
        "line2": formData.line2,
        "city": formData.city,
        "state": formData.state,
        "province": formData.province,
        "zip_code": formData.zip_code,
        "country": formData.country,
        "is_billing": formData.is_billing,
        "is_public": formData.is_public
      }
      UserService.UserAddressAdd(article)
        .then((res) => {

          if (res.data.result === 1) {
            console.log("Department Address Added Successfully.");
            navigation('/Departments/DepartmentReport/');
            swal('Department Address added successfully!', '', 'success');
          }
          else {
            console.log("Error in  Department address added api:");
          }
        })
        .catch((error) => {
          console.log("Error in  Department address added api:");
        });
    }
  };

  //#endregion


  return (
    <>
      <h3 className="userheader mt-4">Add a New Address</h3>
      <Card>
        <Card.Body className="col-lg-12 row">
          <Col lg={3}></Col>
          <Col lg={9}>
            <Col className="pb-4 order-report-form small">
              {/* form-control form-control-sm is-invalid */}
              <Row className="mb-2">
                <Col lg={3} className="">

                  <Form.Label htmlFor="belong_to">
                    My Address Book Name (Description Only)
                  </Form.Label>
                </Col>
                <Col lg={9} className="">
                  <Form.Control
                    type="text"
                    className="col-sm-5 form-control form-control-sm"
                    id="belong_to"
                    name="belong_to"
                    value={"DEPARTMENT"}
                    onChange={handleChanged}
                    required
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={3} className="">

                  <Form.Label htmlFor="company">
                    Company
                  </Form.Label>
                </Col>
                <Col lg={9}>
                  <Form.Control
                    type="text"
                    className="col-sm-5 "
                    id="company"
                    name="company"
                    value={formData.company}
                    onChange={handleChanged}
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={3} className="">

                  <Form.Label htmlFor="contact">
                    Contact/Attention
                  </Form.Label>
                </Col>
                <Col lg={9} className="">
                  <Form.Control
                    type="text"
                    min="0"
                    className="col-sm-5 form-control form-control-sm"
                    id="contact"
                    name="contact"
                    value={formData.contact}
                    onChange={handleChanged}
                    required
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={3} className="">

                  <Form.Label htmlFor="line1">
                    Address Line 1
                  </Form.Label>
                </Col>
                <Col lg={9} className="">
                  <Form.Control
                    type="text"
                    min="0"
                    className="col-sm-5 form-control form-control-sm"
                    id="line1"
                    name="line1"
                    value={formData.line1}
                    onChange={handleChanged}
                    required
                  />
                </Col>
              </Row>
              <Row className="mb-2">

                <Col
                  lg={3}
                  className=" mb-1 mb-lg-0"
                >

                  <Form.Label htmlFor="line2">
                    Address Line 2
                  </Form.Label>
                </Col>
                <Col lg={9} className="">
                  <Form.Control
                    type="text"
                    className="col-sm-5 "
                    id="line2"
                    name="line2"
                    value={formData.line2}
                    onChange={handleChanged}

                  />
                </Col>
              </Row>
              <Row className="mb-2">

                <Col
                  lg={3}
                  className=" mb-1 mb-lg-0"
                >

                  <Form.Label htmlFor="line3">
                    Address Line 3
                  </Form.Label>
                </Col>
                <Col lg={9} className="">
                  <Form.Control
                    type="text"
                    className="col-sm-5 "
                    id="line3"
                    name="line3"
                    //value={formData.line3}
                    onChange={handleChanged}
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={3} className="">

                  <Form.Label htmlFor="city">
                    City
                  </Form.Label>
                </Col>
                <Col lg={9} className="">
                  <Form.Control
                    type="text"
                    className="col-sm-5 form-control form-control-sm"
                    id="city"
                    name="city"
                    value={formData.city}
                    onChange={handleChanged}
                    required
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={3} className="">

                  <Form.Label htmlFor="dropdown_name">
                    dropdown_name
                  </Form.Label>
                </Col>
                <Col lg={9} className="">
                  <Form.Control
                    type="text"
                    className="col-sm-5"
                    id="dropdown_name"
                    name="dropdown_name"
                    value={formData.dropdown_name}
                    onChange={handleChanged}
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col
                  lg={3}
                  className=" mb-1 mb-lg-0"
                >

                  <Form.Label htmlFor="state">
                    State
                  </Form.Label>
                </Col>
                <Col lg={9} className="">
                  <Form.Select
                    type="text"
                    className="col-sm-5 form-control form-control-sm"
                    id="state"
                    name="state"
                    value={formData.state}
                    onChange={handleChanged}
                    required
                  >
                    <option key={0} value={0}>Select One</option>
                    <option key="1" value="1">Alabama</option>
                    <option key="2" value="2">Alaska</option>
                    <option key="3" value="3">Arizona</option>
                    <option key="4" value="4">Arkansas</option>
                    <option key="5" value="5">California</option>
                    <option key="6" value="6">Colorado</option>
                    <option key="7" value="7">Connecticut</option>
                    <option key="8" value="8">Delaware</option>
                    <option key="9" value="9">District of Columbia</option>
                    <option key="10" value="10">Florida</option>
                    <option key="11" value="11">Georgia</option>
                    <option key="12" value="12">Hawaii</option>
                    <option key="13" value="13">Idaho</option>
                    <option key="14" value="14">Illinois</option>
                    <option key="15" value="15">Indiana</option>
                    <option key="16" value="16">Iowa</option>
                    <option key="17" value="17">Kansas</option>
                    <option key="18" value="18">Kentucky</option>
                    <option key="19" value="19">Louisiana</option>
                    <option key="20" value="20">Maine</option>
                    <option key="21" value="21">Maryland</option>
                    <option key="22" value="22">Massachusetts</option>
                    <option key="23" value="23">Michigan</option>
                    <option key="24" value="24">Minnesota</option>
                    <option key="25" value="25">Mississippi</option>
                    <option key="26" value="26">Missouri</option>
                    <option key="27" value="27">Montana</option>
                    <option key="28" value="28">Nebraska</option>
                    <option key="29" value="29">Nevada</option>
                    <option key="30" value="30">New Hampshire</option>
                    <option key="31" value="31">New Jersey</option>
                    <option key="32" value="32">New Mexico</option>
                    <option key="33" value="33">New York</option>
                    <option key="34" value="34">North Carolina</option>
                    <option key="35" value="35">North Dakota</option>
                    <option key="36" value="36">Ohio</option>
                    <option key="37" value="37">Oklahoma</option>
                    <option key="38" value="38">Oregon</option>
                    <option key="39" value="39">Pennsylvania</option>
                    <option key="53" value="53">Puerto Rico</option>
                    <option key="40" value="40">Rhode Island</option>
                    <option key="41" value="41">South Carolina</option>
                    <option key="42" value="42">South Dakota</option>
                    <option key="43" value="43">Tennessee</option>
                    <option key="44" value="44">Texas</option>
                    <option key="45" value="45">Utah</option>
                    <option key="46" value="46">Vermont</option>
                    <option key="52" value="52">Virgin Islands</option>
                    <option key="47" value="47">Virginia</option>
                    <option key="48" value="48">Washington</option>
                    <option key="49" value="49">West Virginia</option>
                    <option key="50" value="50">Wisconsin</option>
                    <option key="51" value="51">Wyoming</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col
                  lg={3}
                  className=" mb-1 mb-lg-0"
                >

                  <Form.Label htmlFor="zip_code">
                    Postal Code
                  </Form.Label>
                </Col>
                <Col lg={9} className="">
                  <Form.Control
                    type="text"
                    className="col-sm-5 form-control form-control-sm"
                    id="zip_code"
                    name="zip_code"
                    value={formData.zip_code}
                    onChange={handleChanged}
                    required
                  />
                </Col>
              </Row>
              <Row className="mb-2">

                <Col
                  lg={3}
                  className=" mb-1 mb-lg-0"
                >

                  <Form.Label htmlFor="country">
                    Country
                  </Form.Label>
                </Col>
                <Col lg={9} className="">
                  <Form.Select
                    type="text"
                    className="col-sm-5 form-control form-control-sm"
                    id="country"
                    name="country"
                    value={formData.country}
                    onChange={handleChanged}
                    required
                  >
                    <option key={0} value={0}>Select One</option>
                    <option key="186" value="186"  >United States	</option>
                    <option key="31" value="31"  >Canada	</option>
                    <option key="114" value="114"  >Mexico</option>
                    <option key="1" value="1"  >Afghanistan	</option>
                    <option key="2" value="2"  >Albania	</option>
                    <option key="3" value="3"  >Algeria	</option>
                    <option key="4" value="4"  >Andorra	</option>
                    <option key="5" value="5"  >Angola	</option>
                    <option key="6" value="6"  >Antigua &amp; Deps	</option>
                    <option key="7" value="7"  >Argentina	</option>
                    <option key="8" value="8"  >Armenia	</option>
                    <option key="9" value="9"  >Australia	</option>
                    <option key="10" value="10"  >Austria	</option>
                    <option key="11" value="11"  >Azerbaijan	</option>
                    <option key="12" value="12"  >Bahamas	</option>
                    <option key="13" value="13"  >Bahrain	</option>
                    <option key="14" value="14"  >Bangladesh	</option>
                    <option key="15" value="15"  >Barbados	</option>
                    <option key="16" value="16"  >Belarus	</option>
                    <option key="17" value="17"  >Belgium	</option>
                    <option key="18" value="18"  >Belize	</option>
                    <option key="19" value="19"  >Benin	</option>
                    <option key="197" value="197"  >Bermuda</option>
                    <option key="20" value="20"  >Bhutan	</option>
                    <option key="21" value="21"  >Bolivia	</option>
                    <option key="22" value="22"  >Bosnia Herzegovina	</option>
                    <option key="23" value="23"  >Botswana	</option>
                    <option key="24" value="24"  >Brazil	</option>
                    <option key="25" value="25"  >Brunei	</option>
                    <option key="26" value="26"  >Bulgaria	</option>
                    <option key="27" value="27"  >Burkina	</option>
                    <option key="28" value="28"  >Burundi	</option>
                    <option key="29" value="29"  >Cambodia	</option>
                    <option key="30" value="30"  >Cameroon	</option>
                    <option key="32" value="32"  >Cape Verde	</option>
                    <option key="33" value="33"  >Central African Rep	</option>
                    <option key="34" value="34"  >Chad	</option>
                    <option key="35" value="35"  >Chile	</option>
                    <option key="36" value="36"  >China	</option>
                    <option key="37" value="37"  >Colombia	</option>
                    <option key="38" value="38"  >Comoros	</option>
                    <option key="39" value="39"  >Congo	</option>
                    <option key="40" value="40"  >Congo (Democratic Rep)	</option>
                    <option key="41" value="41"  >Costa Rica	</option>
                    <option key="42" value="42"  >Croatia	</option>
                    <option key="43" value="43"  >Cuba	</option>
                    <option key="44" value="44"  >Cyprus	</option>
                    <option key="45" value="45"  >Czech Republic	</option>
                    <option key="46" value="46"  >Denmark	</option>
                    <option key="47" value="47"  >Djibouti	</option>
                    <option key="48" value="48"  >Dominica	</option>
                    <option key="49" value="49"  >Dominican Republic	</option>
                    <option key="50" value="50"  >East Timor	</option>
                    <option key="51" value="51"  >Ecuador	</option>
                    <option key="52" value="52"  >Egypt	</option>
                    <option key="53" value="53"  >El Salvador	</option>
                    <option key="54" value="54"  >Equatorial Guinea	</option>
                    <option key="55" value="55"  >Eritrea	</option>
                    <option key="56" value="56"  >Estonia	</option>
                    <option key="57" value="57"  >Ethiopia	</option>
                    <option key="58" value="58"  >Fiji	</option>
                    <option key="59" value="59"  >Finland	</option>
                    <option key="60" value="60"  >France	</option>
                    <option key="61" value="61"  >Gabon	</option>
                    <option key="62" value="62"  >Gambia	</option>
                    <option key="63" value="63"  >Georgia	</option>
                    <option key="64" value="64"  >Germany	</option>
                    <option key="65" value="65"  >Ghana	</option>
                    <option key="66" value="66"  >Greece	</option>
                    <option key="67" value="67"  >Grenada	</option>
                    <option key="200" value="200"  >GUAM</option>
                    <option key="68" value="68"  >Guatemala	</option>
                    <option key="69" value="69"  >Guinea	</option>
                    <option key="70" value="70"  >Guinea-Bissau	</option>
                    <option key="71" value="71"  >Guyana	</option>
                    <option key="72" value="72"  >Haiti	</option>
                    <option key="73" value="73"  >Honduras	</option>
                    <option key="74" value="74"  >Hungary	</option>
                    <option key="75" value="75"  >Iceland	</option>
                    <option key="76" value="76"  >India	</option>
                    <option key="77" value="77"  >Indonesia	</option>
                    <option key="78" value="78"  >Iran	</option>
                    <option key="79" value="79"  >Iraq	</option>
                    <option key="80" value="80"  >Ireland (Republic)	</option>
                    <option key="81" value="81"  >Israel	</option>
                    <option key="82" value="82"  >Italy	</option>
                    <option key="83" value="83"  >Ivory Coast	</option>
                    <option key="84" value="84"  >Jamaica	</option>
                    <option key="85" value="85"  >Japan	</option>
                    <option key="86" value="86"  >Jordan	</option>
                    <option key="87" value="87"  >Kazakhstan	</option>
                    <option key="88" value="88"  >Kenya	</option>
                    <option key="89" value="89"  >Kiribati	</option>
                    <option key="90" value="90"  >Korea North	</option>
                    <option key="91" value="91"  >Korea South	</option>
                    <option key="92" value="92"  >Kosovo	</option>
                    <option key="93" value="93"  >Kuwait	</option>
                    <option key="94" value="94"  >Kyrgyzstan	</option>
                    <option key="95" value="95"  >Laos	</option>
                    <option key="96" value="96"  >Latvia	</option>
                    <option key="97" value="97"  >Lebanon	</option>
                    <option key="98" value="98"  >Lesotho	</option>
                    <option key="99" value="99"  >Liberia	</option>
                    <option key="100" value="100"  >Libya	</option>
                    <option key="101" value="101"  >Liechtenstein	</option>
                    <option key="102" value="102"  >Lithuania	</option>
                    <option key="103" value="103"  >Luxembourg	</option>
                    <option key="104" value="104"  >Macedonia	</option>
                    <option key="105" value="105"  >Madagascar	</option>
                    <option key="106" value="106"  >Malawi	</option>
                    <option key="107" value="107"  >Malaysia	</option>
                    <option key="108" value="108"  >Maldives	</option>
                    <option key="109" value="109"  >Mali	</option>
                    <option key="110" value="110"  >Malta	</option>
                    <option key="111" value="111"  >Marshall Islands	</option>
                    <option key="112" value="112"  >Mauritania	</option>
                    <option key="113" value="113"  >Mauritius	</option>
                    <option key="115" value="115"  >Micronesia	</option>
                    <option key="116" value="116"  >Moldova	</option>
                    <option key="117" value="117"  >Monaco	</option>
                    <option key="118" value="118"  >Mongolia	</option>
                    <option key="119" value="119"  >Montenegro	</option>
                    <option key="120" value="120"  >Morocco	</option>
                    <option key="121" value="121"  >Mozambique	</option>
                    <option key="122" value="122"  >Myanmar, (Burma)	</option>
                    <option key="123" value="123"  >Namibia	</option>
                    <option key="124" value="124"  >Nauru	</option>
                    <option key="125" value="125"  >Nepal	</option>
                    <option key="126" value="126"  >Netherlands	</option>
                    <option key="127" value="127"  >New Zealand	</option>
                    <option key="128" value="128"  >Nicaragua	</option>
                    <option key="129" value="129"  >Niger	</option>
                    <option key="130" value="130"  >Nigeria	</option>
                    <option key="131" value="131"  >Norway	</option>
                    <option key="132" value="132"  >Oman	</option>
                    <option key="133" value="133"  >Pakistan	</option>
                    <option key="134" value="134"  >Palau	</option>
                    <option key="135" value="135"  >Panama	</option>
                    <option key="136" value="136"  >Papua New Guinea	</option>
                    <option key="137" value="137"  >Paraguay	</option>
                    <option key="138" value="138"  >Peru	</option>
                    <option key="139" value="139"  >Philippines	</option>
                    <option key="140" value="140"  >Poland	</option>
                    <option key="141" value="141"  >Portugal	</option>
                    <option key="196" value="196"  >Puerto Rico</option>
                    <option key="142" value="142"  >Qatar	</option>
                    <option key="143" value="143"  >Romania	</option>
                    <option key="144" value="144"  >Russian Federation	</option>
                    <option key="145" value="145"  >Rwanda	</option>
                    <option key="148" value="148"  >Saint Vincent &amp; the Grenadines	</option>
                    <option key="149" value="149"  >Samoa	</option>
                    <option key="150" value="150"  >San Marino	</option>
                    <option key="151" value="151"  >Sao Tome &amp; Principe	</option>
                    <option key="152" value="152"  >Saudi Arabia	</option>
                    <option key="153" value="153"  >Senegal	</option>
                    <option key="154" value="154"  >Serbia	</option>
                    <option key="155" value="155"  >Seychelles	</option>
                    <option key="156" value="156"  >Sierra Leone	</option>
                    <option key="157" value="157"  >Singapore	</option>
                    <option key="158" value="158"  >Slovakia	</option>
                    <option key="159" value="159"  >Slovenia	</option>
                    <option key="160" value="160"  >Solomon Islands	</option>
                    <option key="161" value="161"  >Somalia	</option>
                    <option key="162" value="162"  >South Africa	</option>
                    <option key="163" value="163"  >Spain	</option>
                    <option key="164" value="164"  >Sri Lanka	</option>
                    <option key="146" value="146"  >St Kitts &amp; Nevis	</option>
                    <option key="147" value="147"  >St Lucia	</option>
                    <option key="165" value="165"  >Sudan	</option>
                    <option key="166" value="166"  >Suriname	</option>
                    <option key="167" value="167"  >Swaziland	</option>
                    <option key="168" value="168"  >Sweden	</option>
                    <option key="169" value="169"  >Switzerland	</option>
                    <option key="170" value="170"  >Syria	</option>
                    <option key="171" value="171"  >Taiwan	</option>
                    <option key="172" value="172"  >Tajikistan	</option>
                    <option key="173" value="173"  >Tanzania	</option>
                    <option key="174" value="174"  >Thailand	</option>
                    <option key="175" value="175"  >Togo	</option>
                    <option key="176" value="176"  >Tonga	</option>
                    <option key="177" value="177"  >Trinidad &amp; Tobago	</option>
                    <option key="178" value="178"  >Tunisia	</option>
                    <option key="179" value="179"  >Turkey	</option>
                    <option key="180" value="180"  >Turkmenistan	</option>
                    <option key="198" value="198"  >Turks &amp; Caicos Islands</option>
                    <option key="181" value="181"  >Tuvalu	</option>
                    <option key="182" value="182"  >Uganda	</option>
                    <option key="183" value="183"  >Ukraine	</option>
                    <option key="184" value="184"  >United Arab Emirates	</option>
                    <option key="185" value="185"  >United Kingdom	</option>
                    <option key="187" value="187"  >Uruguay	</option>
                    <option key="188" value="188"  >Uzbekistan	</option>
                    <option key="189" value="189"  >Vanuatu	</option>
                    <option key="190" value="190"  >Vatican City	</option>
                    <option key="191" value="191"  >Venezuela	</option>
                    <option key="192" value="192"  >Vietnam	</option>
                    <option key="193" value="193"  >Yemen	</option>
                    <option key="194" value="194"  >Zambia	</option>
                    <option key="195" value="195"  >Zimbabwe	</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={3} className="">

                  <Form.Label htmlFor="phone">
                    Phone
                  </Form.Label>
                </Col>
                <Col lg={9} className="">
                  <InputMask
                    mask="999-999-9999"
                    name="phone"
                    id="phone"
                    className="form-control-sm form-control"
                    placeholder="phone"
                    value={formData.phone}
                    onChange={handleChanged}
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={3} className="">

                  <Form.Label htmlFor="Fax">
                    Fax
                  </Form.Label>
                </Col>
                <Col lg={9} className="">

                  <InputMask
                    mask="999-999-9999"
                    name="Fax"
                    id="Fax"
                    className="form-control-sm form-control"
                    placeholder="Fax"
                  // value={formData.Fax}
                  // onChange={handleChanged}
                  />
                </Col>
              </Row>

              <Row className="mb-2">
                <Col lg={3} className="">

                  <Form.Label htmlFor="province">
                    province
                  </Form.Label>
                </Col>
                <Col lg={9} className="">
                  <Form.Control
                    type="text"
                    className="col-sm-5"
                    id="province"
                    name="province"
                    value={formData.province}
                    onChange={handleChanged}
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={3} className="">
                </Col>
                <Col lg={6} className="d-flex flex-wrap gap-2">
                  <Form.Check
                    label="Set As Default Billing Address"
                    className="align-items-md-center"
                    inline
                    id="is_billing"
                    name="is_billing"
                    value={formData.is_billing}
                    onChange={handleChanged}
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={3} className="">

                </Col>
                <Col lg={6} className="d-flex flex-wrap gap-2">
                  <Form.Check
                    label="Set As Default Location Address"
                    className="align-items-md-center"
                    inline
                    id="is_public"
                    name="is_public"
                    value={formData.is_public}
                    onChange={handleChanged}
                  />
                </Col>
              </Row>
              <Row className="row mt-4">
                <Col
                  lg={9}
                  className=""
                >

                </Col>
                <Col lg={2} className="d-flex ">
                  <div className="d-flex gap-3 flex-end">
                    <Button
                      className="btn btn-primary"
                      onClick={AddressAdd}
                    >
                      Save
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Col>
        </Card.Body>
      </Card>
    </>
  );
}

export default DepartmentAddress;
