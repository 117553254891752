const appConstant = {
  CUSTOMER_ID: 1850,
  ADMIN_USER_ID: 217082,
  CUSTOMER_PROFILE: 'PROFILE',
  DEPARTMENTS: 'departments',
  DELIVERYMETHOD: 'delivery_methods',
  USERS: 'users',
  USER_LIST: "user_list",
  COST_CENTERS: "cost_centers",
  PRACTICE_LIST: "practice_list"
}
export default appConstant;