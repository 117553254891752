import axios from "axios";
import  secureLocalStorage  from  "react-secure-storage";

export default () => {
    let token = null;
  const userDetail = secureLocalStorage.getItem("userDetails");
  if (userDetail) {
    token = JSON.parse(userDetail).idToken;
    // console.log(token);
  }
  else {
  }
  return axios.create({
    baseURL: "https://admin.psggo.com/api",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Authorization": `Bearer ${token}`
    }
  });
}
