import http from "./Endpoint";
import appConstant from "constant";

class ReportService {

  projection = {
    "order_by": {
      "name": "ASC"
    },
    "limit": 100,
    "offset": 0
  }

  /**
   * List order for a user
   */
  orderFrontEnd(data) {
    let article = {
      "filter": {
        "id": data.id,
        "customer_id": appConstant.CUSTOMER_ID,
        "user_id": data.User_id,
        "user_text": data.user_text,
        "order_status_id_id": data.order_status_id,
        "date_from": data.date_from,
        "date_to": data.date_to,
        "cost_center_id": data.cost_center_id,
        "department_id": data.Department_id,
        "payment_method_id": data.payment_method_id,
        "date_by": data.date_by,
        "search_by": data.search_by,
        "order_type_id_id": data.order_type_id,
        "keyword_search": data.keyword_search
      },
      "projection": {
        "order_by": {
          "id": "ASC",
        },
        "limit": 500,
        "offset": 0
      },
      "return": [],
      "extra": [
        "found_rows"
      ]
    }
    if (data.date_shipped) {
      article.return.push("date_shipped");
    }
    if (data.department_code) {
      article.return.push("department_code");
    }
    if (data.ship_to) {
      article.return.push("ship_to");
    }
    if (data.tracking_number) {
      article.return.push("tracking_number");
    }
    if (data.email) {
      article.return.push("email");
    }
    if (data.phone) {
      article.return.push("phone");
    }
    if (data.title) {
      article.return.push("title");
    }
    return http().post("/report/orderFrontEnd", article);
  }

  /**
   * Get inventory details
   * @param int productId 
   * @returns 
   */
  inventoryDetails(productId) {
    let article = {
      "filter": {
        "product_id": productId,
        "date_from": "2023-09-22",
        "date_to": "2023-09-22"
      },
      "projection": {
        "order_by": {
          "order_id": "DESC",
          "created": "ASC"
        },
        "limit": 1000,
        "offset": 0
      },
      "return": [ ]
    }
    return http().post("/report/inventoryDetails", article);
  }

  inventoryFrontEnd(data) {
    let article = {
      "filter": data,
      "projection": {
        "order_by": {
          "order_id": "DESC",
          "created": "ASC"
        },
        "limit": 1000,
        "offset": 0
      },
      "return": [],
      "extra": [
        "export_code"
      ]
    };
    return http().post("/report/inventoryFrontEnd", article);
  }

  query(id) {
    let article = {
      "filter": {
        "id": id
      },
      "return": [
        "id",
        "customer_id",
        "customer_name",
        "user_id",
        "user_name",
        "order_status_name",
        "order_type_name",
        "delivery_method_name",
        "payment_method_name",
        "order_type_name",
        "created",
        "order_products",
        "shipping_address"
      ]
    }
    return http().post("/report/query", article);
  }


}

export default new ReportService();