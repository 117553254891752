import http from "./Endpoint";
import appConstant from "constant";

class DepartmentService {

  list(data) {
    let article = {
      "filter": {
        "customer_id": appConstant.CUSTOMER_ID,
        "is_active": 1,
        "name": data.name,
      },
      "return": [
        "id",
        // "customer_id",
        "name"
        //"department_code",
        // 1 for storage, 2 = storage and package
        // "billing_reports",
        // Third party shipper
        // "ups_number",
        // Third part freigh
        //"ltl_freight", 
        // Third part fedex
        //"fedex_number",
        // Department address
        //"addresses",
        //"user_list"
      ]
    }
    return http().post("/department/list", article);
  }

  DepartmentGet(deptid) {
    let article = {
      "filter": {
        "id": deptid,
        "is_active": 1
      },
      "return": [
        "id",
        "customer_id",
        "name",
        "department_code",
        "is_active",
        "billing_reports",
        // 1 for storage, 2 = storage and package
        "billing_reports",
        // Third party shipper
        "ups_number",
        // Third part freigh
        "ltl_freight",
        // Third part fedex
        "fedex_number",
        // Department address
        // "addresses",
        "user_list"
      ]
    }
    return http().post("/department/query", article);
  }

  DepartmentUpdate(data) {
    return http().post("/department/update", data);
  }

  DepartmentDelete(data) {
    return http().post("/department/delete", data);
  }

  DepartmentAdd(data) {
    return http().post("/department/add", data);
  }

  DepartmentAddCCEmails(data) {
    return http().post("/department/addCCEmails", data);
  }

}

export default new DepartmentService();