import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useShoppingCart } from "../cart/ShoppingCartContext";
import { showHideTabs } from "../../common/functions";
import Support from "../cart/Support";
import secureLocalStorage from "react-secure-storage";
import appConstant from 'constant';


const NavBarMenu = () => {
  const [showModal, setShowModal] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const handleLinkClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSearch = (ev) => {
    ev.stopPropagation();
    if (ev.keyCode == 13 && ev.target.value != "") {
      document.location = "/category/" + encodeURIComponent(ev.target.value);
    }
  };

  const handleSearchIcon = () => {
    const el = document.getElementById("search_text");
    if (el.value != "") {
      document.location = "/category/" + encodeURIComponent(el.value);
    }
  };

  const handleLogout = (ev) => {

    ev.stopPropagation();
    secureLocalStorage.removeItem("userDetails");
    secureLocalStorage.removeItem("PROFILE");
    document.location = "/login";
  };

  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  
  const navigation = useNavigate();
  const { addToCart, totalItem, totalQty } = useShoppingCart();
  const location = useLocation();

  const navigateToHome = () => {
    // Use the history object to navigate to the home page
    navigation("/");
  };

  const navigateToRegister = () => {
    navigation("/cart/UserRegistration");
  };

  const isActive = (path) => {
    if (location.pathname === path) {
      return true;
    }
    if (path !== "/") {
      return location.pathname.startsWith(path);
    }
  };

  useEffect(() => {
    isActive("/");

    const profile = JSON.parse(secureLocalStorage.getItem("PROFILE"));
    if (profile && profile.user_id == appConstant.ADMIN_USER_ID) {
      setIsAdmin(true);
    }
  }, [location]);

  return (
    <>
      {/* blue banner  */}
      <div className="top_header_section"> </div>

      <section className="header_section py-3">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="row d-flex justify-content-between align-items-center header_main_top"
              >
                <div
                  className="col-lg-3 col-md-3 col-sm-5 col-12 order-lg-1 order-1 header_Logo"
                  onClick={() => navigateToHome()}
                >
                  <img src="/dist/img/header-logo.svg" className="img-fluid" />
                </div>

                {/* Tabs */}
                <div className="col-lg-4 col-sm-12 col-md-5 col-12 order-lg-2 order-3 mt-2 mt-lg-0 no-print">
                  {showHideTabs() && (
                    <ul className="d-flex justify-content-center align-items-end navbar_menu_inner">
                      <li
                        className={
                          isActive("/") ||
                            isActive("/category") ||
                            isActive("/product")
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/" className="">
                          <span>Products</span>
                        </Link>
                      </li>

                      <li
                        className={`cart-position ${isActive("/cart/preview") ||
                            isActive("/cart/shipping")
                            ? "active"
                            : ""
                          }`}
                      >
                        <Link to="/cart/preview" className="text-center">
                          <i
                            className="bi bi-cart4 nav-cart-icon mt-0 mb-2 mx-auto"
                          // style={{ fontSize: "1.4rem" }}
                          ></i>
                          <span>Cart</span>
                        </Link>
                      </li>

                      <li className={isActive("/orders") ? "active" : ""}>
                        <Link to="/orders">
                          <span>Orders</span>
                        </Link>
                      </li>

                      {
                        isAdmin &&
                        <li className="">
                          <Dropdown show={showDropdown} onToggle={toggleDropdown}>
                            <Dropdown.Toggle
                              variant="none"
                              className="border-0 text-start p-0 mt-0"
                              style={
                                {
                                  // fontSize: "1.1rem",
                                  // marginTop: "20px",
                                  // float: "left",
                                }
                              }
                              id="admin-dropdown"
                            >
                              Reports
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item>
                                <Link to="/userreport">Users</Link>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <Link to="/Users/Account">Account</Link>
                              </Dropdown.Item>
                              <Dropdown.Item className="hidden">
                                <Link to="/Departments/DepartmentReport">Departments</Link>
                              </Dropdown.Item>
                              <Dropdown.Item className="hidden">
                                <Link to="/CostCenter/CostCenterReport">Cost Center</Link>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <Link to="/Product/ProductReport">Products</Link>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <Link to="/reports/orderreport">Orders</Link>
                              </Dropdown.Item>
                              <Dropdown.Item className="hidden">
                                <Link to="/admin/product">Inventories</Link>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </li>
                      }
                    </ul>
                  )}
                </div>

                {/* Customer service */}
                <div className="col-lg-3 col-sm-5 col-12 order-lg-3 order-2 no-print mt-3 mt-md-0">
                  <div className="text-center cs-btn">
                    <a href="#" className=" button" onClick={handleLinkClick}>
                      Customer Service
                    </a>
                    <span className="muted-text-small message">
                      Need assistance, click here
                    </span>
                  </div>
                </div>

                {
                  /* Search */
                  showHideTabs() && (
                    <div className="col-lg-2 col-md-6 text-end order-md-4 order-md-4 no-print is-desktop">
                      <div className="search-box my-0 text-center">
                        <i
                          className="fas fa-search search-icon"
                          onClick={handleSearchIcon}
                        />
                        <input
                          id="search_text"
                          type="text"
                          className="search-input"
                          placeholder="Search..."
                          onKeyUp={handleSearch}
                        />
                        <a
                          href="#"
                          className="muted-text-small message"
                          onClick={handleLogout}
                        >
                          logout
                        </a>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* end :: header */}
      {/* start :: create_account_page */}
      {/* end :: create_account_page */}
      {/* start :: bootstrap script */}
      <Support showModal={showModal} handleCloseModal={handleCloseModal} />
    </>
  );
};

export default NavBarMenu;
