import React, { useState, useRef, useEffect } from "react";
import { Button, Col, Container, Row, Form, InputGroup, Breadcrumb } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import appConstant from "constant";
import UserService from "api/precision/UserService";
import DepartmentService from "api/precision/DepartmentService";
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { moveFirst, moveLast, moveNext, movePrevious } from "icons/pagination.js";
import swal from 'sweetalert';

function UserReport() {

  const [deptoptions, setdeptoptions] = useState([
    {
      "value": 9908,
      "label": "General Practice"
    }
  ]);
  const [searchData, setSearchData] = useState([]);
  const [buttonname, Setbuttonname] = useState('Search');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(100);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  //#region Bind DropDown
  useEffect(() => {
    document.title = 'User Report';
    // DepartmentBind();
    fetchData(currentPage);
  }, []);

  const DepartmentBind = () => {
    DepartmentService.list({name: null})
      .then((response) => {
        // Extract the deptoptions from the API response
        const { data } = response.data;
        const departmentOptions = data.map((department) => ({
          value: department.id.toString(),
          label: department.name,
        }));

        // Prepend the "ALL" option to the list
        //const allOption = { value: '0', label: 'ALL' };
        setdeptoptions([...departmentOptions]);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }

  //#endregion
  //#region Delete,InActvie
  const handleInactive = (id, email) => {
    swal({
      title: 'Are you sure?',
      text: 'Are you sure you want to set the user ' + email + ' as inactive?',
      icon: 'warning',
      buttons: {
        cancel: 'No, cancel',
        confirm: 'Yes, inactivate',
      },
    }).then((result) => {
      if (result) {
        const article = {
          "id": id,
          "is_active": 0,
        }
        UserService.UserUpdate(article)
          .then((res) => {
            if (res.data.length > 10) {
              console.log("User in activate Successfully.");
            }
          })
          .catch((error) => {
            console.error("Error in  User inactive api:", error);
          });
        swal('User inactivated!', '', 'success');
      }
    });
  };

  const handleDelete = (id, email) => {
    swal({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete the user ' + email + '?',
      icon: 'warning',
      buttons: {
        cancel: 'No, cancel',
        confirm: 'Yes, Delete',
      },
    }).then((result) => {
      if (result) {
        const article = {
          "id": id,
          "customer_id": appConstant.CUSTOMER_ID,
          "permanent": 0
        }
        UserService.UserDelete(article)
          .then((res) => {
            if (res.data.length > 10) {
              console.log("User Deleted Successfully.");
            }
          })
          .catch((error) => {
            console.error("Error in  User delete api:", error);
          });
        swal('User Deleted!', '', 'success');
      }
    });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchData(page);
  };

  //#endregion
  //#region  Search
  useEffect(() => {
    // debugger
    fetchData(currentPage);
  }, [rowsPerPage]);

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
  };

  const customPagination = () => {
    return (
      <div>
        <span>Page {currentPage} of {totalPages}</span>
        <select value={rowsPerPage} onChange={handleRowsPerPageChange}>
          {[10, 15, 20, 25, 30].map((rows) => (
            <option key={rows} value={rows}>
              {rows} rows
            </option>
          ))}
        </select>
        <button type="button" className="btn btn-primary btn-sm mx-1" onClick={() => handlePageChange(1)} disabled={currentPage === 1} >
          First
        </button>
        <button className="btn btn-primary btn-sm mx-1" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
          Previous
        </button>
        <button className="btn btn-primary btn-sm mx-1" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
          Next
        </button>
        {/* <button type="button" className="btn btn-primary btn-sm mx-1" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages}>
          Last
        </button> */}
          <input type="button" className="btn btn-primary btn-sm mx-1" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages}
          value="Last"/>
      
      </div>
    );
  };
  
  const [filter, SetFilter] = useState({
    customer_id: appConstant.CUSTOMER_ID,
    is_active: 0,
    first_name: null,
    email: null,
    last_name: null,
    user_level_id: 0,
    representation: null,
    department_id: 0,
    limit: 100,
    offset: 0
  });

  const filterChanged = (ev) => {
    // ev.stopPropagation();
    // ev.preventDefault();
    const field = ev.target.name;
    if (field == "is_active") {
      SetFilter({ ...filter, [field]: ev.target.checked });
    }
    else {
      filter[field] = ev.target.value;
      SetFilter({ ...filter, [field]: ev.target.value });
    }
  };

  const fetchData = (page) => {
    Setbuttonname('Please wait...');
    const limit = 100;
    let offsetnum = (page - 1) * limit;
    if (offsetnum === 0) {
      offsetnum = 100;
    }
    //  SetFilter({ ...filter,  ["offset"]: 455 });
    filter.offset = offsetnum;
    setTotalPages(Math.ceil(5000 / limit));

    UserService.userlist(filter)
      .then((response) => {
       // debugger
        if (response.data.result == 1) {
          setSearchData(response.data.data);
          Setbuttonname('Search');
        }
        else {
          setSearchData([]);
          Setbuttonname('Search');
        }
      })
      .catch((error) => {
        console.error("Error in  User report api:", error);
      });
  };

  let columns = [
    {
      name: "Actions",
      selector: (row) => (
        <div className="">
          <Link to={"/Users/ModifyUser/" + row.id} className="me-1">Modify</Link>
          <a href="#" className="me-1" onClick={() => handleInactive(row.id, row.email)}> Inactivate </a>
          <a href="#" className="" onClick={() => handleDelete(row.id, row.email)}>Delete </a>
        </div>
      ),
      width: "12rem",
    }
  ];

  if (appConstant.CUSTOMER_ID == 1850) {
    columns.push(
      {
        name: "Practice",
        selector: (row) => row.representation,
        sortable: true,
        width: "18rem",
      },
    );
  }
  else {
    columns.push({
      name: "First Name",
      selector: (row) => row.first_name,
      sortable: true,
    },
      {
        name: "Last Name",
        selector: (row) => row.last_name,
        sortable: true,
      },
      {
        name: "Email",
        selector: (row) => row.email,
        sortable: true,
      });
  }

  columns.push(
    {
      name: "Departments",
      selector: (row) => row.department_list && row.department_list[0].name,
      sortable: true,
    },
    {
      name: "User Level",
      selector: (row) => row.user_level,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.is_active == 1 ? "Acive" : "In Active",
      sortable: true,
    });

  //#endregion
  const handleReset = () => {
    SetFilter({
      customer_id: appConstant.CUSTOMER_ID,
      is_active: 0,
      first_name: '',
      email: '',
      last_name: '',
      user_level_id: 0,
      representation: '',
      department_id: 0
    });
    setSearchData([]);
  };

  return (
    <Container className="pt-4 pb-4 order-report-form small">
      <Breadcrumb>
        <Breadcrumb.Item active>Users</Breadcrumb.Item>
      </Breadcrumb>

      <Row className="text-center">
        <Col>
          <Accordion key="0" defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Filter</Accordion.Header>

              <Accordion.Body>
                <Row className="mb-2">
                  <Col lg={2} className="">
                    <Form.Label htmlFor="first_name">
                      First Name
                    </Form.Label>
                   
                  </Col>

                  <Col lg={4}>
                    <Form.Control
                      type="text"
                      className="form-control-sm"
                      id="first_name"
                      name="first_name"
                      value={filter.first_name}
                      onChange={filterChanged}
                    />
                  </Col>

                  <Col lg={2} className="">
                  <Form.Label htmlFor="last_name">
                  Last Name
                </Form.Label>
        
                  </Col>

                  <Col lg={4}>
                    <Form.Control
                      type="text"
                      className="form-control-sm"
                      id="last_name"
                      name="last_name"
                      value={filter.last_name}
                      onChange={filterChanged}
                    />
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col lg={2} className="">
                  <Form.Label htmlFor="representation">
                  Practice
                </Form.Label>
                   
                  </Col>

                  <Col lg={4}>
                    <Form.Control
                      type="text"
                      className="form-control-sm"
                      id="representation"
                      name="representation"
                      value={filter.representation}
                      onChange={filterChanged}
                    />
                  </Col>

                  <Col lg={2} className="">
                  <Form.Label htmlFor="department_id">
                  Department
                </Form.Label>
                   
                  </Col>

                  <Col lg={4}>
                    <Form.Select
                      className="col-md-9"
                      id="department_id"
                      name="department_id"
                      value={filter.department_id}
                      onChange={filterChanged}
                    >
                      <option key={0} value="">ALL</option>
                      {deptoptions && deptoptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col lg={2} className="mb-1">
                  <Form.Label htmlFor="user_level_id">
                  Level:
                </Form.Label>
                   
                  </Col>

                  <Col lg={4}>
                    <Form.Select
                      id="user_level_id"
                      name="user_level_id"
                      className="form-control-sm"
                      value={filter.user_level_id}
                      onChange={filterChanged}
                    >
                      <option key={0} value="" selected>ALL</option>
                      <option key={1} value={1}>User</option>
                      <option key={2} value={2}>Manager</option>
                      <option key={3} value={3}>Administrator</option>
                    </Form.Select>
                  </Col>

                  <Col lg={2} className="">
                  <Form.Label htmlFor="is_active">
                  Show Inactive
                </Form.Label>
                    
                  </Col>

                  <Col lg={4} className="">
                    <Form.Check
                      id="is_active"
                      name="is_active"
                      value={filter.is_active}
                      onChange={filterChanged}
                    />
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col lg={10} className="text-end">
                    <Button
                      className="btn btn-primary mx-1"
                      onClick={fetchData}
                    >
                      {buttonname}
                    </Button>
                    <Button
                      className="btn btn-primary mx-1"
                      onClick={handleReset}
                    >
                      Reset
                    </Button>
                    <Link to={"/Users/AddUser"} className="btn btn-primary mx-1"> Add User</Link>
                  </Col>

                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <DataTable
            columns={columns}
            data={searchData}
            defaultSortFieldID={1}
            responsive={false}
            pagination
            paginationPerPage={rowsPerPage}
            // paginationRowsPerPageOptions={[20]}
            paginationComponent={customPagination}
          />

        </Col>
      </Row>
    </Container>

  );
}

export default UserReport;
