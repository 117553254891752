import React, { useState, useEffect } from 'react'
import "./cart.css";
import { removeSpecialCharacters } from '../../common/functions';
import { getProfile } from '../../services/AuthService';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import OrderService from 'api/precision/OrderService';
import appConstant from "constant";

export default function Orders() {

  const userDetail = JSON.parse(getProfile());
  const { orderId, orderType } = useParams();
  const [pageTitle, setPageTitle] = useState("");
  const [orders, setOrders] = useState(null);

  useEffect(() => {
    document.title = 'Orders';
    OrderService.list({ customer_id: appConstant.CUSTOMER_ID, user_id: userDetail.user_id })
      .then((response) => {
        if (response.data.result == 1) {
          setOrders(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching orders:", error);
      });
  }, []);


  const handlePrint = (e) => {
    e.stopPropagation();
    window.print();
  }

  return (
    <>
      <section className="preview_order_section order_submitted">
        <div className="container">

          <div className="row">
            <div className="col-12">
              <div className="preview_main">
                <h1>My Orders</h1>
              </div>
            </div>

            <div className="container-1" >
              <div className="col-11 d-flex justify-content-between">
                <div className="button-group">
                  <div className="preview_delivery">
                    <h3> </h3>
                  </div>
                </div>
              </div>

            </div>
            <div className="col-lg-12">
              <div className="preview_order_main">
                <table className="table table-responsive table-blue"  >
                  <thead>
                    <tr>
                      <th className="text-center">Order #</th>
                      <th className="text-center">Created By</th>
                      <th className="text-center">Personal Notes</th>
                      <th className="text-center">Order Date</th>
                      <th className="text-center">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders && orders.map((order) => (
                      <tr key={order.id}>
                        <td className="table-data">
                          <Link className="education_a" to={'/order/' + order.id + "/2"}>
                            {order.id}
                          </Link>
                        </td>
                        <td className="table-data text-center">
                          {order.user_first_name + ' ' + order.user_last_name}</td>
                        <td className="table-data">{order.shipping_instruction == 0 ? "" : order.shipping_instruction}</td>
                        <td className="table-data text-center">
                          {moment(order.created).format('MM/DD/YYYY')}
                        </td>
                        <td className="table-data text-center">{order.order_status}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
