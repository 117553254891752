import React, { useState, useEffect } from "react";
import { Button, Col, Row, Form, Card, Breadcrumb, ListGroup, InputGroup, FormControl, Container } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import DepartmentService from "../../api/precision/DepartmentService";
import { FieldValidation } from "common/functions";
import appConstant from "constant";
import swal from 'sweetalert';

function DepartmentDetails({ deptkey }) {
  const navigation = useNavigate();
  const pagekey = deptkey;

  const headertext = pagekey === 0 || pagekey === undefined ? 'Add new department' : 'Modify department';

  useEffect(() => {
    document.title = pagekey === 0 || pagekey === undefined ? 'Add new department' : 'Modify department';
  }, []);

  const [formData, SetFormData] = useState({
    id: 0,
    // customer_id:
    name: null,
    department_code: null,
    is_active: 0,
    billing_reports: null,
    password: '',
    phone: null,
    cell: null,
    // 1 = user, 2 = manager, 3 = admin
    user_level_id: 0,
    title: null,

    representation: null,
    department_id: 0

  });

  //#region Dept GET
  useEffect(() => {
    if (pagekey > 0) {
      setDepartment();
      SetFormData({ ...formData, id: pagekey })
    }
  }, [pagekey]);

  function setDepartment() {
    if (pagekey === undefined) {
      return;
    }
    DepartmentService.DepartmentGet(pagekey)
      .then((response) => {
        if (response.data.result == 1 && response.data.data.id > 0) {
          SetFormData(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching Department  data:", error);
      });
  }
  //#endregion 
  //#region  Update/Add
  const handleChanged = (evt) => {
    const value = evt.target.value;
    SetFormData({
      ...formData,
      [evt.target.name]: value,
    });

  };

  const DepartmentSave = (ev) => {
    ev.stopPropagation();
    let requiredFields = [
      "name",
    ]
    let res = FieldValidation(requiredFields);
    if (res.result) {
      const article = {
        "id": formData.id,
        "customer_id": appConstant.CUSTOMER_ID,
        "name": formData.name,
        "department_code": formData.department_code,
        "cc_order_emails": emailList
      }
      DepartmentService.DepartmentUpdate(article)
        .then((result) => {
          if (result.data.result === 1) {
            navigation('/Departments/DepartmentReport/');
            swal('Department Updated Successfully!', '', 'success');
          }
        })
        .catch((error) => {
          console.error("Error in  Department update api:", error);
        });
    }
  };
  //#endregion

  const [currentEmail, setCurrentEmail] = useState('');
  const [emailList, setEmailList] = useState([]);

  //#region  Update/Add Email
  const handleEmailChange = (event) => {
    setCurrentEmail(event.target.value);
  };

  const addEmail = () => {
    let requiredFields = [
      "cc_order_emails",
    ]
    let res = FieldValidation(requiredFields);
    if (res.result) {
      if (currentEmail && !emailList.includes(currentEmail)) {
        setEmailList(prevList => [...prevList, currentEmail]);
        setCurrentEmail('');
      }
    }
  };

  const deleteEmail = (index) => {
    setEmailList(prevList => {
      const newList = [...prevList];
      newList.splice(index, 1);
      return newList;
    });
  };

  const DepartmentAddCCEmails = (ev) => {
    ev.stopPropagation();
    if (emailList.length > 0) {
      const article = {
        "id": 1,
        "customer_id": appConstant.CUSTOMER_ID,
        "cc_order_emails": emailList
      }
      DepartmentService.DepartmentAddCCEmails(article)
        .then((result) => {

          if (result.data.result === 1) {
            navigation('/Departments/DepartmentReport/');
            console.log("Department CC Updated Successfully.");
            swal('Department CC Updated Successfully!', '', 'success');
          }
        })
        .catch((error) => {
          console.error("Error in  Department CC Updated api:", error);
        });
    }
    else {
      swal('Please add at least one email for  Department CC!', '', 'error');
    }
  }
  //#endregion

  return (
    <>
      <h3 className="userheader mt-4">{headertext}</h3>
      <Card>
        <Card.Body className="">
          <h3 className="usertitle">Department Information</h3>
          <Row className="mb-2">
            <Col sm={4} className="">
              <Form.Label htmlFor="name">
                Department Name*
              </Form.Label>
            </Col>
            <Col sm={8} className="">
              <Form.Control
                type="text"
                className="col-sm-5 form-control form-control-sm"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChanged}
                required
              />
              <div className="invalid-feedback">Department name is required</div>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col sm={4} className="">
              <Form.Label htmlFor="department_code">
                Department Code (optional)
              </Form.Label>
            </Col>
            <Col sm={8} className="">
              <Form.Control
                type="text"
                className="col-sm-5"
                id="department_code"
                name="department_code"
                value={formData.department_code}
                onChange={handleChanged}
              />
            </Col>
          </Row>

          <br></br>
          <br></br>
          {pagekey > 0 &&
            <>
              <h3 className="usertitle">Department Properties</h3>

              <Row className="mb-2">
                <Col sm={3} className="">

                  <Form.Label htmlFor="cc_order_emails">
                    Order CC Email Addresses
                  </Form.Label>
                </Col>
                <Col sm={5}>
                  <Form.Control
                    type="cc_order_emails"
                    className="col-sm-5 form-control form-control-sm"
                    id="cc_order_emails"
                    name="cc_order_emails"
                    value={currentEmail}
                    required
                    onChange={handleEmailChange}
                  />
                </Col>
                <Col sm={4}>
                <Button className="btn btn-secondary mx-1" onClick={addEmail}>Add Email</Button>
                </Col>
              </Row>
              <ListGroup>
              {emailList.length > 0 && emailList.map((email, index) => (
                    <ListGroup.Item key={index}>
                      {/* <Button variant="info" size="sm" onClick={() => startEditing(index)} className="btn btn-primary mx-1">Edit</Button> */}
                      <Button variant="danger" size="sm" onClick={() => deleteEmail(index)} className="btn btn-secondary mx-2">Delete</Button>
                      {email}
                    </ListGroup.Item>
                ))}
            </ListGroup>
            
            <Row className="row mt-4">
              <Col sm={4} className="">
                &nbsp;
              </Col>
              <Col sm={8} className="text-end">
                <Button
                  className="btn btn-primary"
                  onClick={DepartmentSave}
                > Save</Button>
              </Col>
            </Row>
            </>
          }
        </Card.Body >
      </Card >
    </>
  );
}

export default DepartmentDetails;
