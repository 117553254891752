import React from "react";
import { Container, Breadcrumb, Card, Tabs, Tab } from "react-bootstrap";
import { useParams, useNavigate, Link } from 'react-router-dom';
import DepartmentDetails from '../department/DepartmentDetails';
import DepartmentAddress from "./DepartmentAddress";
// import DepartmentAddressReport from "./DepartmentAddressReport";

function DepartmentTabs() {
  let { pagekey } = useParams();
  return (
    <Container className="pt-4 pb-4 small">
      <Breadcrumb className="p-3">
        <Breadcrumb.Item href="/Departments/Departmentreport">Departments</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <Card.Body className="col-lg-12">
          <Container className="pb-4 order-report-form small">
            <Tabs
              defaultActiveKey="depDetails"
              transition={false}
              id="uncontrolled-tab-example"
              className="mb-3 no-print"
            >
              <Tab eventKey="depDetails" title="Details">
                <DepartmentDetails deptkey={pagekey}></DepartmentDetails>
              </Tab>

              <Tab eventKey="deptAddresses" title="Addresses" className="pb-5">
                <DepartmentAddress></DepartmentAddress>
                {/* <DepartmentAddressReport></DepartmentAddressReport> */}
              </Tab>

            </Tabs>
          </Container>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default DepartmentTabs;
