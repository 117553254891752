import React, { useState, useRef, useEffect } from "react";
import ReportService from "api/precision/ReportService";
import ProductService from "api/precision/ProductService";
import appConstant from "constant";
import { FormatMysqlDate, ShowToast } from 'common/functions';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { TotalFooter } from '../hooks/usePagination';


function InventoryDetailTable({ productId, setActiveView }) {
  const [productInfo, setProductInfo] = useState(null);
  const [inventoryLog, setInventoryLog] = useState([]);

  // Fetch data from your API using Axios when the component mounts
  useEffect(() => {
    if (productId) {
      ProductService.query(productId)
        .then((res) => {
          if (res.data.result == 1) {
            setProductInfo(res.data.data);

            ReportService.inventoryDetails(productId)
              .then((response) => {
                if (response.data.result == 1) {
                  setInventoryLog(response.data.data);
                }
              })
              .catch((error) => {
                console.error("Error fetching report data:", error);
              });
          }
        })
        .catch((error) => {
          console.error("Error fetching report data:", error);
        });
    }
  }, [productId]);

  const columns = [
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      width: "8rem",
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      sortable: true,
      width: "8rem",
    },
    {
      name: "Ending Quantity",
      selector: (row) => row.ending_inventory,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Palettes",
      selector: (row) => row.palettes,
      sortable: true,
      width: "8rem",
    },
    {
      name: "Order ID",
      selector: (row) => row.order_id,
      sortable: true,
      width: "8rem",
    },
    {
      name: "Comments",
      selector: (row) => row.comment,
      sortable: true,
      width: "8rem",
    },
    {
      name: "Date",
      selector: (row) => moment(row.date).format('MM/DD/YYYY'),
      sortable: true,
      width: "8rem",
    }
  ];

  const handleNav = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveView({ view_type: "inventory_summary", owner_id: productId });
  }


  if (inventoryLog && inventoryLog.length) {
    return (
      <>
        <nav className="pt-3" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="#" onClick={handleNav}>Inventories</a></li>
            <li className="breadcrumb-item active" aria-current="page">{productId}</li>
          </ol>
        </nav>

        <DataTable
          paginationPerPage={50}
          columns={columns}
          data={inventoryLog}
          defaultSortFieldID={1}
          responsive={true}
          pagination={true}
          footer={
            <div>
              <TotalFooter data={inventoryLog} field="total_order_amount" />{" "}
              <TotalFooter data={inventoryLog} field="total_items_amount" />
              <TotalFooter data={inventoryLog} field="shipping_amount" />
              <TotalFooter data={inventoryLog} field="tax_amount" />
            </div>
          } />
      </>

    );
  }
  else {
    return (
      <p className="bg-body-secondary text-center">No data found. Please select a search filters.</p>
    )
  }

}

export default InventoryDetailTable;
