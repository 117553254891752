import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import swal from "sweetalert";
import ProductService from "api/precision/ProductService";
import AddressService from "api/precision/AddressService";
import { FieldValidation } from "common/functions";
import { getProfile } from "../../services/AuthService";
import { isAuthenticated } from "../../store/selectors/AuthSelectors";
import { useSelector, connect, useDispatch } from "react-redux";

import {
  loadingToggleAction,
  loginAction,
} from "../../store/actions/AuthActions";

import Support from "../cart/Support"; // Adjust the path accordingly

const UserRegistration = () => {
  const [showModal, setShowModal] = useState(false);

  const handleLinkClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [isInvalidPostalCode, setIsInvalidPostalCode] = useState(true);
  const username = useSelector((state) => state.auth.auth.username);
  useEffect(() => {
    document.title = "Account"; // Set your desired page title here
  }, []);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setdata] = useState({
    practice_name: "",
    email: "",
    phone: "",
    contact: "",
    line1: "",
    city: "",
    state: "",
    zip_code: "",
  });

  // Fetch report data when search button is clicked
  const handleRegister = (e) => {
    e.stopPropagation();
    e.preventDefault();

    let requiredFields = [
      "practice_name",
      "email",
      "phone",
      "contact",
      "line1",
      "city",
      "state",
      "zip_code",
    ];
    let res = FieldValidation(requiredFields);
    if (res.result) {
      AddressService.addressValidate(data)
        .then((response) => {
          if (response.data.result == 1) {
            // Validate filter
            ProductService.addpractice(data)
              .then((response) => {
                if (response.data.result == 1) {
                  dispatch(loadingToggleAction(true));
                  dispatch(
                    loginAction(
                      data.practice_name,
                      data.line1,
                      data.zip_code,
                      navigate
                    )
                  );
                }
              })
              .catch((error) => {
                console.error("Error fetching report data:", error);
              });
          }
          else {
            setIsInvalidPostalCode(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching report data:", error);
        });
    }
    else {

      return false;
    }

  };

  function handleChange(evt) {
    const value = evt.target.value;
    const name = evt.target.name;
    setdata({
      ...data,
      [evt.target.name]: value,
    });
  }

  return (
    <>
      <div className="top_header_section"> </div>
      {username.length < 1 && (
        <section className="header_section">
          <div
            className="container"
          // style={{ paddingBottom: "35px", paddingTop: "20px" }}
          >
            <div className="row">
              <div className="col-12">
                <div className="row header_main_top align-items-center justify-content-between">
                  <div className="col-12 col-sm-4 col-lg-3 header_Logo">
                    <img
                      src="/dist/img/header-logo.svg"
                      alt="Nationwide"
                      className="img-fluid"
                    />
                  </div>
                  {/* <div className="col-lg-4"></div> */}
                  <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end mt-4 mt-sm-0">
                    <div className="text-center mt-2 mt-sm-0">
                      <a href="#" className=" button" onClick={handleLinkClick}>
                        Customer Services
                      </a>
                      <span className="muted-text-small message">
                        Need Assistance, click here
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <section className="login_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="login_main_left">
                <h1>Create an Account</h1>
                <form name="login_form" id="login_form">
                  <div className="account_main_wrapper">
                    <div className="account_main_left">
                      <p className="acount_info">Step 1: Contact Information</p>
                      <div className="login_wrapper form-group-sm">
                        <label htmlFor="practice_name">Practice Name*</label>

                        <input
                          id="practice_name"
                          name="practice_name"
                          className="form-control form-control-sm"
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="login_wrapper">
                        <label htmlFor="email">Email*</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control form-control-sm"
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="login_wrapper">
                        <label htmlFor="phone">Phone*</label>
                        <input
                          type="tel"
                          id="phone"
                          name="phone"
                          className="form-control form-control-sm"
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="img-fluid no-vs-search">
                        <img
                          src="/dist/img/2-28002_cute-corgi-dog-dog-transparent-background.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="account_main_left">
                      <p className="acount_info">Step 2: Delivery Address</p>
                      <div className="login_wrapper">
                        <label htmlFor="attention">Contact / Attention*</label>
                        <input
                          type="text"
                          id="contact"
                          name="contact"
                          className="form-control form-control-sm"
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="login_wrapper">
                        <label htmlFor="line1">Address*</label>
                        <input
                          type="text"
                          id="line1"
                          name="line1"
                          className="form-control form-control-sm"
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="login_wrapper">
                        <label htmlFor="city">City*</label>
                        <input
                          type="text"
                          id="city"
                          name="city"
                          className="form-control form-control-sm"
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="login_wrapper">
                        <label htmlFor="state">State*</label>
                        <select className="form-control form-control-sm" id="state" name="state" required
                          onChange={handleChange}
                        >
                          <option value="" >  </option>
                          <option value="AL">Alabama</option>
                          <option value="AK">Alaska</option>
                          <option value="AZ">Arizona</option>
                          <option value="AR">Arkansas</option>
                          <option value="CA">California</option>
                          <option value="CO">Colorado</option>
                          <option value="CT">Connecticut</option>
                          <option value="DC">District Columbia</option>
                          <option value="DE">Delaware</option>
                          <option value="FL">Florida</option>
                          <option value="GA">Georgia</option>
                          <option value="HI">Hawaii</option>
                          <option value="ID">Idaho</option>
                          <option value="IL">Illinois</option>
                          <option value="IN">Indiana</option>
                          <option value="IA">Iowa</option>
                          <option value="KS">Kansas</option>
                          <option value="KY">Kentucky</option>
                          <option value="LA">Louisiana</option>
                          <option value="ME">Maine</option>
                          <option value="MD">Maryland</option>
                          <option value="MA">Massachusetts</option>
                          <option value="MI">Michigan</option>
                          <option value="MN">Minnesota</option>
                          <option value="MS">Mississippi</option>
                          <option value="MO">Missouri</option>
                          <option value="MT">Montana</option>
                          <option value="NE">Nebraska</option>
                          <option value="NV">Nevada</option>
                          <option value="NH">New Hampshire</option>
                          <option value="NJ">New Jersey</option>
                          <option value="NM">New Mexico</option>
                          <option value="NY">New York</option>
                          <option value="NC">North Carolina</option>
                          <option value="ND">North Dakota</option>
                          <option value="OH">Ohio</option>
                          <option value="OK">Oklahoma</option>
                          <option value="OR">Oregon</option>
                          <option value="PA">Pennsylvania</option>
                          <option value="RI">Rhode Island</option>
                          <option value="SC">South Carolina</option>
                          <option value="SD">South Dakota</option>
                          <option value="TN">Tennessee</option>
                          <option value="TX">Texas</option>
                          <option value="UT">Utah</option>
                          <option value="VT">Vermont</option>
                          <option value="VA">Virginia</option>
                          <option value="WA">Washington</option>
                          <option value="WV">West Virginia</option>
                          <option value="WI">Wisconsin</option>
                          <option value="WY">Wyoming</option>
                        </select>
                      </div>
                      <div className="login_wrapper">
                        <label htmlFor="zip_code">Postal Code*</label>
                        <input
                          type="text"
                          id="zip_code"
                          name="zip_code"
                          className="form-control form-control-sm"
                          onChange={handleChange}
                          required
                        />
                        {data.zip_code.length > 0 && !isInvalidPostalCode && (
                          <p style={{ color: "red" }}>Your address is not deliverable.</p>
                        )}
                      </div>
                      <p className="ship_para" >
                        * All fields required.
                        Can not ship to any countries outside of the US nor can we ship to US territories.
                      </p>
                    </div>
                  </div>
                  <div className="wrapper_button create_button">
                    <input
                      type="button"
                      className="log_in"
                      onClick={handleRegister}
                      value="Create An Account"
                    ></input>
                  </div>
                  {/* <div className="col-sm-8">
                    <button className="btn btn-info btn-sm mr-2" >Search</button>

                  </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="no-print">
        <footer className="main-footer fixed-bottom"></footer>
      </div>

      <Support showModal={showModal} handleCloseModal={handleCloseModal} />
    </>
  );
};

export default UserRegistration;
