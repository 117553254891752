import http from "./Endpoint";
import appConstant from "constant";
class UserAdminService {

  projection = {
    "order_by": {
      "first_name": "ASC"
    },
    /* "limit": 100, */
    "offset": 0
  };

  schema() {
  }

  /**
   * Call to get a bearer token
   * @param {*} username 
   * @param {*} password 
   * @returns 
   */
  // login(username, password) {
  //   const loginFormData = new FormData();
  //   loginFormData.append("username", username)
  //   loginFormData.append("password", password)
  //   return http().post("/auth", loginFormData);
  // }
  login(practiceame, address, postalCode) {
    const loginForm = new FormData();
    loginForm.append("customer_id", appConstant.CUSTOMER_ID);
    loginForm.append("address_name", practiceame);
    loginForm.append("line1", address);
    loginForm.append("zip_code", postalCode);
    loginForm.append("method", "address");
    return http().post("/auth", loginForm);
  }

  list(data) {
    const article = { ...data, projection: this.projection };
    return http().post("/useradmin/list", article);
  }

  update() {

  }

  /**
   * @param {*} role 
   * @returns 
   */
  async privilege(data) {
    return http().post("/useradmin/previlege", data);
  }

  add(data) {

  }

  query(data) {
    return http().post("/useradmin/query", data);
  }


}

// eslint-disable-next-line  
export default new UserAdminService();