import React, { useState, useRef, useEffect } from "react";
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { TotalFooter } from '../hooks/usePagination'; // Path to your custom hook

export default function UserReport({ searchData }) {

  const columns = [
    {
      name: "Name",
      selector: (row) => row.user_name,
      sortable: true,
    },
    {
      name: "Department(s)",
      selector: (row) => row.department_name,
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => row.total_price,
      sortable: true,
      width: "7rem", // Set the width to 150 pixels
    },
    {
      name: "Shipping",
      selector: (row) => row.shipping_cost,
      sortable: true,
      width: "10rem", // Set the width to 150 pixels
    },
    {
      name: "Tax",
      selector: (row) => row.tax_amount,
      sortable: true,
      width: "10rem", // Set the width to 150 pixels
    },
    {
      name: "Handling",
      selector: (row) => row.processing_amount && row.processing_amount.toFixed(2),
      sortable: true,
      width: "8rem", // Set the width to 150 pixels
    },
    {
      name: "Total",
      selector: (row) => row.subtotal && "$" + row.subtotal.toFixed(2),
      sortable: true,
      width: "8rem", // Set the width to 150 pixels
    }
  ];

  if (searchData && searchData[0].hasOwnProperty("first_name")) {
    columns.push({
      name: "First Name",
      selector: (row) => row.first_name ? row.first_name : "",
      sortable: true,
      width: "8rem",
    });
  }
  if (searchData && searchData[0].hasOwnProperty("last_name")) {
    columns.push({
      name: "Last Name",
      selector: (row) => row.last_name ? row.last_name : "",
      sortable: true,
      width: "8rem",
    });
  }
  if (searchData && searchData[0].hasOwnProperty("email")) {
    columns.push({
      name: "Email",
      selector: (row) => row.email ? row.email : "",
      sortable: true,
      width: "8rem",
    });
  }

  if (searchData && searchData.length) {
    return (<DataTable
      className="overflow-visible"
      paginationPerPage={25}
      columns={columns}
      data={searchData}
      defaultSortFieldID={1}
      responsive={true}
      pagination
      footer={
        <div>
          <TotalFooter data={searchData} field="total_order_amount" />{" "}
          <TotalFooter data={searchData} field="total_items_amount" />
          <TotalFooter data={searchData} field="shipping_amount" />
          <TotalFooter data={searchData} field="tax_amount" />
        </div>
      }
    />
    );
  }
  else {
    return (
      <p className="bg-body-secondary text-center">No data found. Please select a search filters.</p>
    )
  }
}