import React, { useState, useEffect } from "react";
import { Button, Col, Breadcrumb, Row, Form, InputGroup, Card } from "react-bootstrap";
import { useParams, useNavigate, Link } from 'react-router-dom';
import ProductService from "../../api/precision/ProductService";
import { FieldValidation } from "common/functions";
import appConstant from "constant";
import swal from 'sweetalert';
import DepartmentService from "../../api/precision/DepartmentService";

function ProductDetail() {
  const navigation = useNavigate();
  let { pagekey } = useParams();
  const [deptoptions, setdeptoptions] = useState([]);
  const breadcrumbText = pagekey === 0 || pagekey === undefined ? 'AddProduct' : `Modify Product / ${pagekey}`;
  const headertext = pagekey === 0 || pagekey === undefined ? 'Add a New Product' : `Modify Product`;
  const [buttonname, Setbuttonname] = useState('Save');
  const [selected, setSelected] = useState([]);
  const [formData, SetFormData] = useState({
    id: 0,
    customer_id: appConstant.CUSTOMER_ID,
    name: null,
    additional_info: null,
    quickbooks_type_id: 0,
    customer_sku: null,
    min_quantity: 0,
    // 1 = Product, 2 = manager, 3 = admin
    max_quantity: 0,
    package_quantity: 0,
    is_customizable: 0,
    is_mailable: 0,
    no_upload: 0,
    show_inventory: 0,
    department_list: [] // Initialize as an empty array
  });
  //#region Bind DropDown
  useEffect(() => {
    DepartmentBind();
  }, []);

  const DepartmentBind = () => {
    DepartmentService.list({ name: null })
      .then((response) => {
        // Extract the deptoptions from the API response
        const { data } = response.data;
        const departmentOptions = data.map((department) => ({
          value: department.id.toString(),
          label: department.name,
        }));

        // Prepend the "ALL" option to the list
        //const allOption = { value: '0', label: 'ALL' };
        setdeptoptions([...departmentOptions]);

      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }
  //#endregion

  //#region  GET
  useEffect(() => {
    if (pagekey > 0) {
      setProduct();
      SetFormData({ ...formData, id: pagekey });
      Setbuttonname('Update');
    }
  }, [pagekey]);

  function setProduct() {
    if (pagekey === undefined) {
      return;
    }
    ProductService.query(pagekey)
      .then((response) => {

        if (response.data.result === 1 && response.data.data.id > 0) {
          SetFormData(response.data.data);

          if (Array.isArray(response.data.data.department_list) && response.data.data.department_list.length > 0) {
            const selectedValues = response.data.data.department_list.map((dept) => dept.id.toString()); // Assuming ID is a string
            setSelected(selectedValues);
            SetFormData({ ...response.data.data, department_list: selectedValues });
            console.log("Product GET Successfully.");
          }

        }
      })
      .catch((error) => {
        console.error("Error fetching Product  data:", error);
      });
  }
  //#endregion 
  //#region  Update/Add
  const handleChanged = (evt) => {
    const value = evt.target.value;

    const field = evt.target.name;
    if (field == "department_list") {
      const selectedValues = Array.from(evt.target.selectedOptions, (option) => option.value);
      setSelected(selectedValues);
      SetFormData({ ...formData, department_list: selectedValues });
    }
    else {
      SetFormData({
        ...formData,
        [evt.target.name]: value,
      });
    }
  };

  const ProductUpdate = (ev) => {
    ev.stopPropagation();
    let requiredFields = ["name"]
    let res = FieldValidation(requiredFields);

    if (res.result) {
      if (pagekey > 0) {//update
        if (res.result) {
          const article = {
            "id": formData.id,
            "customer_id": formData.customer_id,
            "name": formData.name,
            "customer_sku": formData.customer_sku,
            "department_list": formData.department_list
          }
          ProductService.productUpdate(article)
            .then((res) => {
              if (res.data.result == 1) {
                swal('Product Updated!', '', 'success');
              }
            })
            .catch((error) => {
              console.error("Error in  Product update api:", error);
            });
        }
      }

    }

  };

  //#endregion

  return (
    <>
      <Breadcrumb className="p-3">
        <Breadcrumb.Item href="/Product/ProductReport">Products</Breadcrumb.Item>
        <Breadcrumb.Item active>{breadcrumbText}</Breadcrumb.Item>
      </Breadcrumb>
      <h3 className="userheader">{headertext}</h3>
      <Card>
        <Card.Body className="">
          <Row className="mb-2">
            <Col sm={4} className="">
              <Form.Label htmlFor="name">
                Name*
              </Form.Label>

            </Col>
            <Col sm={8} className="">
              <Form.Control
                type="text"
                className="col-sm-5 form-control form-control-sm"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChanged}
                required
              />
              <div className="invalid-feedback">Product name is required</div>
            </Col>

          </Row>
          <Row className="mb-2">
            <Col sm={4} className="" htmlFor="customer_sku">
              <Form.Label htmlFor="customer_sku">
                Customer Sku
              </Form.Label>

            </Col>
            <Col sm={8} className="">
              <Form.Control
                type="text"
                className="form-control form-control-sm"
                id="customer_sku"
                name="customer_sku"
                value={formData.customer_sku}
                onChange={handleChanged}
                required
              />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col sm={4} className="" htmlFor="department_list">
              <Form.Label htmlFor="department_list">
                Departments
              </Form.Label>

            </Col>
            <Col sm={8} className="">
              <Form.Select
                multiple
                className="col-md-9"
                id="department_list"
                name="department_list"
                selected={selected}
                value={formData.department_list}
                onChange={handleChanged}
              >
                {deptoptions && deptoptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
          <Row className="row mt-4">
            <Col
              sm={9}
              className=""
            >
              &nbsp;
            </Col>
            <Col sm={2} className="d-flex ">
              <div className="d-flex gap-3 flex-end">
                <Button
                  className="btn btn-primary"
                  onClick={ProductUpdate}
                >
                  {buttonname}
                </Button>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default ProductDetail;
