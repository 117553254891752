import React, { useState, useRef, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';

const ProductDescription = ({ productId, categoryId, description }) => {
  const popoverRef = useRef();

  useEffect(() => {
    if (productId) {
      var popover = new bootstrap.Tooltip(popoverRef.current)
    }
  }, [productId, categoryId])

  const handleImageError = (e) => {
    e.stopPropagation();
    e.preventDefault();
    e.target.src = '/dist/img/noimages.png';
  };
  
  const swapImage = (pId) => {
    if (pId == 144048) {
      return (categoryId == 2104) ? `${pId}_b` : `${pId}_a`;
    }
    return pId;
  }

  return (
      <div className="p-4">
      <a  className="" ref={popoverRef} title={description} href={'/product/' + productId} >
        <img src={"/dist/img/product/thumb_" + swapImage(productId) + ".jpg"}
          className="img-fluid"
          onError={handleImageError} 
        />
      </a>
      </div>
    )
}
export default ProductDescription;