import React, { useEffect, useState } from "react";
import { useShoppingCart } from './ShoppingCartContext';
import "./cart.css";
import { Link, useNavigate } from 'react-router-dom';
import { removeSpecialCharacters } from '../../common/functions'
import { getProfile } from '../../services/AuthService';
import { ShowToast, FieldValidation } from "common/functions";
import CustomerService from "api/precision/CustomerService";
import OrderService from "api/precision/OrderService";
import appConstant from "constant";
 
const Shipping = () => {
  const navigation = useNavigate();
  const { totalItem, cartItems, emptyCart } = useShoppingCart();
  const [item, setItem] = useState([]);
  const [deliveryMethods, setDeliveryMethods] = useState([]);

  const userDetail = JSON.parse(getProfile());
  const [data, setdata] = useState({
    shipping_instruction: "",
    Email: userDetail.email,
    Contact_Phone: userDetail.phone,
    Contact_Name: userDetail.first_name + " " + userDetail.last_name,
    Delivery_Method: 1
  });


  // useEffect(() => {
  //   setItem(cartItems.map(item => {
  //     const { id, ...rest } = item;
  //     return { product_id: id, ...rest };
  //   }));
  // }, [item]);

  useEffect(() => {
    // Set your desired page title here
    document.title = 'Shipping';
    // console.log(userDetail);

    // Admin has more shipping methods
    if (userDetail.user_id == 217082) {
      CustomerService.query([appConstant.DELIVERYMETHOD])
        .then((response) => {
          if (response.data.result == 1) {
            setDeliveryMethods(response.data.data.delivery_methods);
          }
        })
        .catch((error) => {
          console.error("Error fetching report data:", error);
        });
    }
    else {
      setDeliveryMethods([{ id: 1, name: "UPS Ground", remarks: "up to 5 business days" }]);
    }

  }, []);

  function handleChange(evt) {
    const value = evt.target.value;
    setdata({
      ...data,
      [evt.target.name]: value,
    });
  }

  const SaveOrder = (ev) => {
    ev.stopPropagation();
    let requiredFields = [
      "Contact_Name",
      "Contact_Phone",
      "Delivery_Method",
      "Email"
    ]
    let res = FieldValidation(requiredFields);
    if (res.result) {
      const article = {
        user_id: userDetail.user_id,
        customer_id: appConstant.CUSTOMER_ID,
        first_name: userDetail.first_name,
        last_name: userDetail.last_name,
        email: data.Email,
        delivery_method_id: data.Delivery_Method,
        payment_method_id: 1,
        shipping_address_id: userDetail.address.address_id,
        shipping_instruction: data.shipping_instruction,
        shipping_address: {
          title: userDetail.address.title,
          name: userDetail.address.title,
          line1: userDetail.address.line1,
          line2: userDetail.address.line2,
          city: userDetail.address.city,
          state: userDetail.address.state,
          zip_code: userDetail.address.zip_code,
          country: userDetail.address.country,
        },
        items: cartItems
      }

      OrderService.addorder(article)
        .then((res) => {
          if (res.data.result == 1) {
            emptyCart();
            navigation('/order/' + res.data.data + "/1");
          }
        })
        .catch((error) => {
          console.error("Error fetching report data:", error);
        });
    }
  };

  const handleImageError = (e) => {
    e.target.src = '/dist/img/noimages.png'; // Provide the path to your default or empty image
  };
  return (
    <>
      <section className="preview_order_section login_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="preview_order_main">
                <h1>Shipping</h1>
                <br></br>
                <table className="table-blue"  >
                  <thead>
                    <tr>
                      <th style={{ width: "60%", paddingLeft: "20px" }}>Product/Description</th>
                      <th className="text-center" style={{ width: "20%" }}>Image</th>
                      <th className="text-center" style={{ width: "20%" }}>Quantity</th>
                    </tr>
                  </thead>
                </table>
                <table className="table table-borderless rounded-table ">
                  <tbody>
                    {cartItems.map((product) => (
                      <tr key={product.id}>
                        <td className="table-data" style={{ width: "60%" }}>
                          {product.name}</td>
                        <td className="table-data text-center" style={{ width: "20%" }}>
                          <img className="img-fluid w-50" src={"/dist/img/product/thumb_" + product.id + ".jpg"}
                            onError={handleImageError}
                            alt="iamge" /></td>
                        <td className="table-data text-center" style={{ width: "20%" }}>{totalItem(product.id)} </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

              </div>
            </div>
          </div>
          <div className="login_main_left">
            <br></br>
            <br></br>
            <h2 className="Delivery_header">Delivery To:</h2>
            <form name="login_form" id="login_form" >
              <div className="account_main_wrapper">
                <div className="account_main_left">
                  <div className="address-line">
                    <div >
                      <span>{userDetail.title}</span>
                    </div>
                    <div >
                      <span >{userDetail.address.line1}</span>
                    </div>
                    <div >
                      <span >{userDetail.address.city}, {userDetail.address.state}, {userDetail.address.zip_code}</span>
                    </div>
                  </div>

                  <div className="login_wrapper">
                    <label htmlFor="Contact_Name">Contact Name*</label>
                    <input type="text"
                      id="Contact_Name"
                      name="Contact_Name"
                      className="form-control form-control-sm"
                      required
                      value={data.Contact_Name}
                      onChange={handleChange}
                    ></input>
                  </div>
                  <div className="login_wrapper">
                    <label htmlFor="Contact_Phone">Contact Phone*</label>
                    <input type="tel"
                      id="Contact_Phone"
                      name="Contact_Phone"
                      className="form-control form-control-sm"
                      required
                      value={data.Contact_Phone}
                      onChange={handleChange}
                    ></input>
                  </div>

                </div>
                <div className="account_main_left shipping_wrapper"   >
                  <div className="login_wrapper" style={{ margin: 0 }}>
                    <label htmlFor="Delivery_Method">Delivery Method*</label>
                    <select
                      id="Delivery_Method"
                      name="Delivery_Method"
                      className="form-control form-control-sm"
                      required
                      value={data.Delivery_Method}
                      onChange={handleChange}
                    >
                      {
                        deliveryMethods.map((method) => {
                          if (method.id != 43) {
                            return <option key={method.id} value={method.id}>{method.name}</option>
                          }
                        })
                      }
                    </select>
                  </div>
                  <div className="login_wrapper">
                    <label htmlFor="Email">Email*</label>
                    <input type="email"
                      id="Email"
                      name="Email"
                      className="form-control form-control-sm"
                      required
                      value={data.Email}
                      onChange={handleChange}
                    ></input>
                  </div>
                  <div className="login_wrapper">
                    <label htmlFor="shipping_instruction">Order Notes</label>
                    <input type="text"
                      id="shipping_instruction"
                      className="form-control form-control-sm"
                      name="shipping_instruction"
                      value={data.shipping_instruction}
                      onChange={handleChange}
                    ></input>
                  </div>
                </div>

              </div>
              <div className="wrapper_button create_button">
                <button className="log_in" type="button" onClick={SaveOrder}
                >Place Order
                </button>

              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
export default Shipping;