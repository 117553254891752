import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row, Form, InputGroup, Breadcrumb } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import appConstant from "constant";
import DepartmentService from "api/precision/DepartmentService";
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

function DepartmentReport() {
  const [searchData, setSearchData] = useState([]);
  const [buttonname, Setbuttonname] = useState('Search');

  useEffect(() => {
    document.title = 'Department';
    handleSearch();
  }, []);

  const handleDelete = (id, deptname) => {
    swal({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete the department ' + deptname + '?',
      icon: 'warning',
      buttons: {
        cancel: 'No, cancel',
        confirm: 'Yes, Delete',
      },
    }).then((result) => {
      if (result) {
        const article = {
          "id": id,
          "customer_id": appConstant.CUSTOMER_ID,
          "permanent": 0
        }
        DepartmentService.DepartmentDelete(article)
          .then((res) => {

            if (res.data.length > 10) {
              console.log("Department Deleted Successfully.");
              handleSearch();
            }
          })
          .catch((error) => {
            console.error("Error in  Department delete api:", error);
          });
        swal('Department Deleted!', '', 'success');
      }
    });
  };

  //#endregion
  //#region  Search

  const [filter, SetFilter] = useState({
    customer_id: appConstant.CUSTOMER_ID,
    is_active: 1,
    name: null,
  });

  const filterChanged = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    const field = ev.target.name;
    if (field == "is_active") {
      SetFilter({ ...filter, [field]: ev.target.checked });
    }
    else {
      filter[field] = ev.target.value;
      SetFilter({ ...filter, [field]: ev.target.value });
    }
  };

  const handleSearch = () => {
    Setbuttonname('Please wait...');
    DepartmentService.list(filter)
      .then((response) => {
        if (response.data.result == 1) {
          setSearchData(response.data.data);
          Setbuttonname('Search');
        }
        else {
          setSearchData([]);
          Setbuttonname('Search');
        }
      })
      .catch((error) => {
        console.error("Error in  Department report api:", error);
      });
  };

  const columns = [
    {
      name: "",
      selector: (row) => (
        <div className="">
          <Link to={"/Departments/DepartmentTabs/" + row.id} className="me-2">Modify</Link>
          <a href="#" className="" onClick={() => handleDelete(row.id, row.email)}>Delete</a>
        </div>
      ),
      sortable: true,
      width: "15rem", // Set the width to 150 pixels
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "15rem", // Set the width to 150 pixels
    },
    {
      name: "Code",
      selector: (row) => row.department_code,
      sortable: true,
      width: "10rem", // Set the width to 150 pixels
    },

  ];
  //#endregion

  return (

    <Container className="pt-4 pb-4 small">
      <Breadcrumb>
        <Breadcrumb.Item active>Departments</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col>
        <Accordion key="0" defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Filter</Accordion.Header>
            <Accordion.Body>
              <Row className="mb-2">
                <Col sm={3} className="">
                <Form.Label htmlFor="name">
                Department Name
                </Form.Label>
                  
                </Col>
                <Col sm={6}>
                  <Form.Control
                    type="text"
                    className="col-sm-5"
                    id="name"
                    name="name"
                    value={filter.name}
                    onChange={filterChanged}
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col sm={3}> </Col>
                <Col lg={9} className="text-start">
                  <div className="">
                    <Button
                      className="btn btn-primary me-2"
                      onClick={handleSearch}
                    >
                      {buttonname}
                    </Button>

                    <Link to={"/Departments/DepartmentDetails"} role="button" className="btn btn-primary">Add a New Department</Link>
                  </div>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Col>
          <DataTable
            condense
            paginationPerPage={50}
            columns={columns}
            data={searchData}
            defaultSortFieldID={1}
            responsive={true}
            pagination
          />
        </Col>
        </Col>
      </Row>
    </Container>

  );
}

export default DepartmentReport;
