import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import ProductService from "api/precision/ProductService";
import { toast } from "react-toastify";
import { getProfile } from '../../services/AuthService';

function Category() {
  const userDetail = JSON.parse(getProfile());

  useEffect(() => {
    document.title = 'Products'; // Set your desired page title here
  }, []);
  const [category, setcategory] = useState(null)
  const [filter, setfilter] = useState({
    "customer_id": 1850,
    "exclude_no_product": 1
  });

  const handleImageError = (e) => {
    e.stopPropagation();
    e.target.src = '/dist/img/noimages.png'; // Provide the path to your default or empty image
  };


  useEffect(() => {
    ProductService.productcategory(filter)
      .then((response) => {
        if (response.data.result == 1) {
          let cats = response.data.data.filter((cat) => {
            // Remove CA
            if (userDetail.address.state == "NY") {

              if (cat.id == 2217) {
                return false;
              }
              return true;
            }
            else {
              if (cat.id == 2100) {
                return false;
              }
              return true;
            }
          });

          // Nationwide reorder category
          cats.sort((a, b) => {
            return (a.id == 2214 || b.id == 2104) ? 1 : -1;
          });
          setcategory(cats);
          toast.success("Category Get Successfully!", {
            position: toast.POSITION.TOP_RIGHT,
            role: "alert",
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching report data:", error);
      });
  }, []);


  return (
    <>
      <section>
        <div className="container"></div>
        <div className="mt-1">

          <div className="px-2 pb-3">
            <img
              src="/dist/img/FPO.png"
              alt="image"
              className="img-fluid"
              style={{ width: 'auto', cursor: 'pointer', "paddingLeft": "3%", "paddingRight": "3%" }}
              onClick={() => window.open("https://www.petinsurance.com/veterinarians/", "_blank")}
            />
          </div>

          { /*
          <div >
            <div className="col-12 p-0">
              <div className="title-overlay">
                <div className="title-overlay-h">
                  <h2 className="bannerheader"> Printed Materials for</h2>
                  <h2 className="bannerheader">Your Clients</h2>
                </div>
                <div className="title-overlay-d">
                  <br />
                  Go to  <a style={{ "color": "yellow" }} target="_blank" href="https://www.petinsurance.com/veterinarians/">petinsurance.com/veterinarians </a>  <br />
                  to power up your digital client <br />
                  communications  <br />

                </div>
              </div>
              <div className="col-12 p-0 no-vs-search">
                <img className="img-fluid w-100" src="/dist/img/banner.jpg" />
              </div>
            </div>
          </div>
          */}


          <div className="row my-125 mx-3 centered-col">
            {category && (Array.isArray(category)) && category.map((product, index) => (
              <div
                className="col-lg-4 col-md-6 col-sm-10 centered-col"
                style={{ marginBottom: "10%" }}
                key={index}
              >
                <div className="insurance_main_bottom text-center">
                  <Link to={"/category/" + product.id}>
                    <div className="no-vs-search">
                      <img
                        src={
                          "/dist/img/categories/thumb_" +
                          product.id +
                          ".jpg"
                        }
                        className="img-fluid"
                        onError={handleImageError}
                      />
                    </div>
                  </Link>
                  <div className="insurance_a">
                    <Link
                      className="education_a button-product-list p-2"
                      to={"/category/" + product.id}
                    >
                      {product.name}
                    </Link>
                  </div>
                </div>
              </div>

            ))}

          </div>
        </div>
      </section>
      {/* <!-- end :: insurance --> */}
    </>
  );

}
export default Category;
