import React, { useState, useRef, useEffect } from "react";
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { TotalFooter } from '../hooks/usePagination';


export default function InventoryTable({ searchData, setActiveView }) {

  const columns = [
    {
      name: "Product ID",
      selector: (row) => <a href="#" onClick={(e) => {
        e.preventDefault(); e.stopPropagation();
        setActiveView({ view_type: "inventory_detail", owner_id: row.product_id })
      }
      } >{row.product_id}</a>,
      sortable: true,
    },
    {
      name: "Product Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Start Inventory",
      selector: (row) => row.start_inventory,
      sortable: true,
    },
    {
      name: "Replenished",
      selector: (row) => row.replenished,
      sortable: true,
    },
    {
      name: "Removed",
      selector: (row) => row.removed,
      sortable: true,
    },
    {
      name: "Sold",
      selector: (row) => row.sold,
      sortable: true,
    },
    {
      name: "Canceled",
      selector: (row) => row.canceled,
      sortable: true,
    },
    {
      name: "Ending Inventory",
      selector: (row) => row.ending_inventory,
      sortable: true,
    }
  ];

  if (searchData && searchData.length > 0) {
    return (<DataTable
      className="overflow-visible"
      paginationPerPage={25}
      columns={columns}
      data={searchData}
      defaultSortFieldID={1}
      responsive={true}
      pagination={true}
      dense
      footer={{"total": 100 }} />
    );
  }
  else if (activeView.view_type == "inventory_details" && activeView.product_id) {
    <InventoryDetailTable productId={activeView.product_id} />
  }
  else {
    return (
      <p className="bg-body-secondary text-center">No data found. Please select a search filters.</p>
    )
  }
}