import React, { useEffect } from "react";
import { Container, Breadcrumb, Card, Tabs, Tab } from "react-bootstrap";
import UserPersonalInfo from "./UserPersonalInfo";
import UserAddress from "./UserAddress";
import UserChangePswd from "./UserChangePswd";

function Account() {
  useEffect(() => {
    document.title = 'Account'; // Set your desired page title here
  }, []);
  return (
    <div className="container-lg">
      <Breadcrumb className="p-3">
        <Breadcrumb.Item active>Account</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <Card.Body className="p-3">
          <Tabs
            defaultActiveKey="personalinfo"
            transition={false}
            id="uncontrolled-tab-example"
            className="mb-3 no-print"
          >
            <Tab eventKey="personalinfo" title="Personal Info">
              <UserPersonalInfo></UserPersonalInfo>
            </Tab>

            <Tab eventKey="chgpassword" title="Security" className="pb-5">
              <UserChangePswd></UserChangePswd>
            </Tab>
            <Tab eventKey="newaddress" title="Addresses" className="pb-5">
              <UserAddress></UserAddress>
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Account;
