
import swal from 'sweetalert';
import UserAdminService from 'api/precision/UserAdminService';
import {
  formatError,
  login,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
  saveProfile
} from '../../services/AuthService';

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const NAVTOGGLE = 'NAVTOGGLE';
export const SET_TOKEN_EXPIRATION = "[Refresh Toekn]"

export function signupAction(username, password, navigate) {
  return (dispatch) => {
    signUp(username, password)
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        runLogoutTimer(
          dispatch,
          response.data.expiresIn * 96000,
          //history,
        );
        dispatch(confirmedSignupAction(response.data));
        navigate('/authenticate/Login.JS');
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(signupFailedAction(errorMessage));
      });
  };
}

export function Logout(navigate) {
  localStorage.removeItem('userDetails');
  localStorage.removeItem('privileges');
  navigate('/login');
  return {
    type: LOGOUT_ACTION,
  };
}

export function loginAction(practiceame, address, postalCode, navigate) {
  return (dispatch) => {
    UserAdminService.login(practiceame, address, postalCode)
      .then((response) => {
        if (response.data.result === 1) {
          saveProfile(response.data.profile);
          var data = {
            displayName: ""
            , username: practiceame
            , expireDate: "Fri Jun 8 2024 21:07:08 GMT+0530"
            , expiresIn: "996000"
            , userid: response.data.profile.user_id
            , idToken: response.data.access_token
            , kind: "identitytoolkit#VerifyPasswordResponse"
            , localId: "qmt6dRyipIad8UCc0QpMV2MENSy1"
            , refreshToken: response.data.data
            , registered: true
          }
          saveTokenInLocalStorage(data);
          dispatch(loginConfirmedAction(data));
          navigate('/');
        }
        else {
          swal("Oops", "Invalid username or password", "error");
        }
      })
      .catch((error) => {
        debugger;
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}
export function refreshTokenAction(data) {
  return (dispatch) => {
    dispatch({
      type: SET_TOKEN_EXPIRATION,
      payload: data,
    });
  }
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
export const navtoggle = () => {
  return {
    type: 'NAVTOGGLE',
  };
};