import React, { useState, useEffect } from "react";
import { Button, Col, Row, Form, InputGroup, Card } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import UserService from "../../api/precision/UserService";
import DepartmentService from "../../api/precision/DepartmentService";
import { FieldValidation } from "common/functions";
import { getProfile } from '../../services/AuthService';
import swal from 'sweetalert';
import InputMask from 'react-input-mask';

function UserPersonalInfo() {
  const userDetail = JSON.parse(getProfile());

  const navigation = useNavigate();
  let pagekey = userDetail.user_id;
  const [deptoptions, setdeptoptions] = useState([]);
  const [emailError, setEmailError] = useState('');
  const [formData, SetFormData] = useState({
    id: 0,
    first_name: null,
    last_name: null,
    email: null,
    password: '',
    phone: null,
    cell: null,
    // 1 = user, 2 = manager, 3 = admin
    user_level_id: 0,
    title: null,
    is_active: 0,
    representation: null
  });
  //#region Bind DropDown
  useEffect(() => {
    DepartmentBind();
  }, []);

  const DepartmentBind = () => {
    DepartmentService.list({ name: null})
      .then((response) => {
        // Extract the deptoptions from the API response
        const { data } = response.data;
        const departmentOptions = data.map((department) => ({
          value: department.id.toString(),
          label: department.name,
        }));

        // Prepend the "ALL" option to the list
        //const allOption = { value: '0', label: 'ALL' };
        setdeptoptions([...departmentOptions]);

      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }
  //#endregion
  //#region  GET
  useEffect(() => {

    if (pagekey > 0) {
      SetFormData({
        ...formData, id: pagekey,
        first_name: userDetail.first_name,
        last_name: userDetail.last_name,
        email: userDetail.email,
        password: userDetail.password,
        phone: userDetail.phone,
        cell: userDetail.cell,
        // 1 = user, 2 = manager, 3 = admin
        user_level_id: userDetail.user_level_id,
        title: userDetail.title,
        is_active: userDetail.is_active,
        representation: userDetail.representation

      })
    }
  }, [pagekey]);
  //#endregion 

  //#region  Update
  const handleChanged = (evt) => {
    const value = evt.target.value;
    const name = evt.target.name;
    if (name === 'email') {
      if (!isValidEmail(value)) {
        setEmailError('Invalid email format');
      } else {
        setEmailError('');  // Clear error message
      }
    }
    SetFormData({
      ...formData,
      [evt.target.name]: value,
    });
   
    if (name === "password") {
      handlePasswordChange(evt);
    }
  };
  const isValidEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
  };
  const UserUpdate = (ev) => {

    ev.stopPropagation();
    let requiredFields = [
      "first_name",
      "last_name",
      "title",
      "email"
    ]
    let res = FieldValidation(requiredFields);
    if (res.result) {
      const article = {
        "id": formData.id,
        "first_name": formData.first_name,
        "last_name": formData.last_name,
        "email": formData.email,
        // "password": formData.password,
        "phone": formData.phone,
        "cell": formData.cell,
        // 1 = user, 2 = manager, 3 = admin
        "user_level_id": formData.user_level_id,
        "title": formData.title,
        "is_active": formData.is_active,
        "representation": formData.representation
      }
      UserService.UserUpdate(article)
        .then((res) => {
          if (res.data.result == 1) {
            swal('User personal info updated!', '', 'success');
          }
        })
        .catch((error) => {
          console.error("Error in  User update api:", error);
        });
    }
  };

  //#endregion

  //#region Pswd validate and chk length
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isValid, setIsValid] = useState(true);

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    {
      SetFormData({
        ...formData,
        password: newPassword,
      })
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const newPassword = e.target.value;
    setConfirmPassword(newPassword);
  };

  // Function to validate the passwords
  const validatePasswords = () => {
    const { password } = formData;
    return (
      password.length >= 7 &&
      /\d/.test(password) &&
      /[a-zA-Z]/.test(password) &&
      password === confirmPassword
    );
  };

  // Update isValid whenever the passwords change
  React.useEffect(() => {
    // if(formData.password.length>0){
    //     setIsValid(validatePasswords());
    // }
    {
      formData.password &&
        setIsValid(validatePasswords())
    }

  }, [formData, confirmPassword]);
  
  //#endregion
  return (
    <>
      <br></br>
      <h3 className="userheader">Modify My Personal Information</h3>
      <Card>
        <Card.Body className="col-lg-12 row">
          <Col lg={3}></Col>
          <Col lg={9}>
            <Col className="pb-4 order-report-form small">
              <h6 className="usertitle">Contact Information</h6>
              <Row className="mb-2">
                <Col lg={3} className="">
                  <Form.Label htmlFor="first_name">
                    First Name
                  </Form.Label>

                </Col>
                <Col lg={9} className="">
                  <Form.Control
                    type="text"
                    className="col-sm-5  form-control form-control-sm"
                    id="first_name"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChanged}
                    required
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={3} className="">
                  <Form.Label htmlFor="last_name">
                    Last Name
                  </Form.Label>

                </Col>
                <Col lg={9} className="">
                  <Form.Control
                    type="text"
                    className="col-sm-5  form-control form-control-sm"
                    id="last_name"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChanged}
                    required
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={3} className="">
                  <Form.Label htmlFor="title">
                    Title
                  </Form.Label>

                </Col>
                <Col lg={9} className="">
                  <Form.Control
                    type="text"
                    className="col-sm-5  form-control form-control-sm"
                    id="title"
                    name="title"
                    value={formData.title}
                    onChange={handleChanged}
                    required
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={3} className="">
                  <Form.Label htmlFor="email">
                    Email
                  </Form.Label>

                </Col>
                <Col lg={9} className="">
                    <input 
                    type="email" 
                    name="email" 
                    className="form-control-sm form-control"
                    placeholder="email@example.com"
                    value={formData.email}
                    onChange={handleChanged}
                  />
                  {emailError && <div style={{ color: 'red' }}>{emailError}</div>}
                  
                </Col>
              </Row>

              <span className="muted-text-small message">Note: If you change your email address, be sure to use this new email address when logging in to PSG.</span>
              <Row className="mb-2">
                <Col lg={3} className="">
                  <Form.Label htmlFor="phone">
                    Phone
                  </Form.Label>

                </Col>
                <Col lg={9} className="">
                  <InputMask
                  mask="999-999-9999"
                  name="phone"
                  id="phone"
                  className="form-control-sm form-control"
                  placeholder="Phone"
                  value={formData.phone}
                  onChange={handleChanged}
                />
                
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={3} className="">
                  <Form.Label htmlFor="Fax">
                    Fax
                  </Form.Label>

                </Col>
                <Col lg={9} className="">
                  <InputMask
                  mask="999-999-9999"
                  name="Fax"
                  id="Fax"
                  className="form-control-sm form-control"
                  placeholder="Phone"
                  // value={formData.Fax}
                  // onChange={handleChanged}
                />
                 
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={3} className="">
                  <Form.Label htmlFor="cell">
                    Cell
                  </Form.Label>

                </Col>
                <Col lg={9} className="">
                  <InputMask
                  mask="999-999-9999"
                  name="cell"
                  id="cell"
                  className="form-control-sm form-control"
                  placeholder="cell"
                  value={formData.cell}
                  onChange={handleChanged}
                />
                 
                </Col>
              </Row>

              {pagekey === 0 || pagekey === undefined && <Row className="mb-2">
                <Col lg={3} className="">
                  <Form.Label htmlFor=" representation">
                    representation
                  </Form.Label>

                </Col>
                <Col lg={9} className="">
                  <Form.Control
                    type="text"
                    className="col-sm-5"
                    id="representation"
                    name="representation"
                    value={formData.representation}
                    onChange={handleChanged}
                  />
                </Col>
              </Row>}


              <h3 className="usertitle">Security</h3>
              {pagekey === 0 || pagekey === undefined &&
                <>
                  <i><b>Passwords must be at least 7 characters and contain one number and one letter.</b></i>
                  <br></br>
                  <br></br>
                  <br></br>
                  <Row className="mb-2">
                    <Col lg={3} className="">
                      <Form.Label htmlFor="password">
                        Password
                      </Form.Label>

                    </Col>
                    <Col lg={9}>
                      <Form.Control

                        type="password"
                        className="col-sm-5"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handlePasswordChange}
                        required
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col lg={3} className="">
                      <Form.Label htmlFor="confirmPassword">
                        Confirm Password
                      </Form.Label>

                    </Col>
                    <Col lg={9}>
                      <Form.Control
                        type="password"
                        className="col-sm-5"
                        id="confirmPassword"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                      />
                    </Col>
                  </Row>
                  {!isValid && (
                    <p className="invalid text-danger">
                      Passwords must match and be at least 7 characters long, containing one number and one letter.
                    </p>
                  )}
                </>
              }
              <Row className="mb-2">

                <Col
                  lg={3}
                  className=" mb-1 mb-lg-0"
                >
                  <Form.Label htmlFor="user_level_id">
                    Security Level
                  </Form.Label>

                </Col>
                <Col lg={5} className="">
                  <Form.Select
                    disabled
                    aria-label="Default select example"
                    id="user_level_id"
                    name="user_level_id"
                    value={formData.user_level_id}
                    onChange={handleChanged}
                  >

                    <option key={1} value={1}>User</option>
                    <option key={2} value={2}>Manager</option>
                    <option key={3} value={3}>Admin</option>
                  </Form.Select>
                </Col>
              </Row>

              <h3 className="usertitle">Company</h3>
              <Row className="mb-2">
                <Col
                  lg={3}
                  className=" mb-1 mb-lg-0"
                >
                  <Form.Label htmlFor="department_id">
                    Department
                  </Form.Label>

                </Col>
                <Col lg={5} className="">
                  <Form.Select
                    disabled
                    aria-label="Default select example"
                    className="col-md-9"
                    id="department_id"
                    name="department_id"
                    value={formData.department}
                    onChange={handleChanged}
                  >

                    {deptoptions && deptoptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
              <Row className="row mt-4">
                <Col lg={9}
                  className="d-flex justify-content-between d-none d-lg-flex"
                >
                  &nbsp;
                </Col>
                <Col lg={2} className="d-flex ">
                  <div className="d-flex gap-3 flex-end">
                    <Button
                      className="btn btn-primary"
                      onClick={UserUpdate}
                    >
                      Save
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Col>

        </Card.Body>
      </Card>
    </>
  );
}

export default UserPersonalInfo;
