import React, { useState, useRef, useEffect } from "react";
import { Button, Col, Container, Row, Form, InputGroup } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import CustomerService from "api/precision/CustomerService";
import ReportService from "api/precision/ReportService";
import appConstant from "constant";
import { FormatMysqlDate, ShowToast, ExportCsvFile } from 'common/functions';
import moment from 'moment';
import InventoryReport from './InventoryReport';
import OrderTable from './OrderTable';
import ProductTable from './ProductTable';
import UserTable from './UserTable';
import ItemTable from './ItemTable';
import InventoryTable from './InventoryTable';
import InventoryDetailTable from './InventoryDetailTable';
import OrderDetailTable from "./OrderDetailTable";
import ProductDetailTable from "./ProductDetailTable";
import Accordion from 'react-bootstrap/Accordion';

function OrderReport() {

  const [deptoptions, setdeptoptions] = useState([]);
  const [cost_centeroptions, setCost_centerptions] = useState([]);
  const [useroptions, setUserptions] = useState([]);
  const [searchData, setSearchData] = useState([]);

  // Each report has summary and detail view
  const [activeView, setActiveView] = useState({ "view_type": "", "owner_id": null });
  const [parentView, setParentView] = useState("");

  // Fetch data from your API using Axios when the component mounts
  useEffect(() => {
    DepartmentBind();
    UserBind();
    Cost_centerBind();
  }, []);

  //#region Bind DropDown
  const DepartmentBind = () => {
    CustomerService.query([appConstant.DEPARTMENTS])
      .then((response) => {
        // Extract the deptoptions from the API response
        const { data } = response.data;
        const departmentOptions = data.departments.map((department) => ({
          value: department.id.toString(),
          label: department.name,
        }));

        // Prepend the "ALL" option to the list
        const allOption = { value: '0', label: 'ALL' };
        setdeptoptions([allOption, ...departmentOptions]);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }

  const UserBind = () => {

    return;
    // Commented out because the list is 5000 records
    CustomerService.query([appConstant.PRACTICE_LIST])
      .then((response) => {
        const { data } = response.data;
        const UserOptions = data.practice_list.map((user) => ({
          value: user.id.toString(),
          label: user.name,
        }));

        // Prepend the "ALL" option to the list
        const allOption = { value: '0', label: 'ALL' };
        setUserptions([allOption, ...UserOptions]);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

  }

  const Cost_centerBind = () => {
    CustomerService.query([appConstant.COST_CENTERS])
      .then((response) => {
        const { data } = response.data;
        const CostOptions = data.cost_centers.map((cost) => ({
          value: cost.id.toString(),
          label: cost.name,
        }));

        const allOption = { value: '0', label: 'ALL' };
        setCost_centerptions([allOption, ...CostOptions]);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }
  //#endregion


  //#region  Search
  const [filter, SetFilter] = useState({
    id: null,
    customer_id: appConstant.CUSTOMER_ID,
    department_id: null,
    user_id: null,
    user_text: null,  // Search user by first name, last name, representation or email
    cost_center_id: null,
    order_status_id: null,
    date_from: FormatMysqlDate(),
    date_to: FormatMysqlDate(),
    payment_method_id: null,
    order_type_id: null,
    department_code: false,
    date_shipped: false,
    ship_to: false,
    tracking_number: false,
    email: false,
    phone: false,
    title: false,
    search_by: 1,
    keyword_search: null
  });

  const filterChanged = (ev) => {
    ev.stopPropagation();

    const field = ev.target.name;
    // Validate required field before sending request
    if (field == "date_to" && filter.date_from > ev.target.value) {
      ShowToast("Errors!", "End date must be greater than or equal to start date.");
      return;
    }
    if (ev.target.type == "checkbox") {
      filter[field] = ev.target.checked;
      SetFilter({ ...filter, [field]: ev.target.checked });
    }
    else {
      filter[field] = ev.target.value;
      SetFilter({ ...filter, [field]: ev.target.value });
    }
  };

  const handleSearch = () => {
    ReportService.orderFrontEnd(filter)
      .then((response) => {
        if (response.data.result == 1) {
          setSearchData(response.data.data);
          if (filter.search_by == 1) {
            setActiveView({ "view_type": "order_summary", "owner_id": null });
            setParentView("order_summary");
          }
          else if (filter.search_by == 2) {
            setActiveView({ "view_type": "product_summary", "owner_id": null });
            setParentView("product_summary");
          }
          else if (filter.search_by == 3) {
            setActiveView({ "view_type": "user_summary", "owner_id": null });
            setParentView("user_summary");
          }
          else if (filter.search_by == 4) {
            setActiveView({ "view_type": "item_summary", "owner_id": null });
            setParentView("item_summary");
          }
          else if (filter.search_by == 5) {
            setActiveView({ "view_type": "inventory_summary", "owner_id": null });
            setParentView("inventory_summary");
          }
        }
        else {

        }
      })
      .catch((error) => {
        console.error("Error fetching report data:", error);
      });
  };

  const handleExport = () => {
    let headers = {};
    let filename = "";
    if (parentView == "order_summary") {
      headers = {
        id: "Order ID",
        order_status: "Status",
        created: "Created",
        customer_name: "Organization",
        department_name: "Department",
        cost_center_name: "Cost Center",
        po_number: "PO Number",
        total_items_amount: "Subtotal",
        tax_amount: "Tax",
        shipping_amount: "Shipping",
        processing_amount: "Processing",
        total_order_amount: "Total"
      };
      if (filter.department_code) {
        headers["department_code"] = "Department Code";
      }
      if (filter.date_shipped) {
        headers["date_shipped"] = "Date Shipped";
      }
      if (filter.ship_to) {
        headers["ship_to"] = "Ship to";
      }
      // console.log(headers);
      filename = `orders_${filter.date_from}_${filter.date_to}.csv`;
    }
    else if (parentView == "product_summary") {
      headers = {
        product_id: "Product ID",
        product_name: "Name",
        quantity: "Sold",
        total_price: "Price",
        shipping_cost: "Shipping",
        tax_amount: "Tax",
        pick_fee: "Handling",
        subtotal: "Total"
      };
      filename = `products_sold_${filter.date_from}_${filter.date_to}.csv`;
    }
    else if (parentView == "user_summary") {
      headers = {
        user_name: "Name",
        department_name: "Department",
        total_price: "Price",
        shipping_cost: "Shipping",
        tax_amount: "Tax",
        processing_amount: "Handling",
        subtotal: "Total"
      };
      filename = `user_${filter.date_from}_${filter.date_to}.csv`;
    }
    else if (parentView == "item_summary") {
      headers = {
        department_name: "Department",
        user_name: "Created By",
        order_id: "Order ID",
        order_product_id: "Item ID",
        product_name: "Item Description",
        quantity: "Sold",
        total_price: "Price"
      };
      if (filter.tracking_number) {
        headers["tracking_number"] = "Tracking Number";
      }
      filename = `item_${filter.date_from}_${filter.date_to}.csv`;
    }
    else if (parentView == "inventory_summary") {
      headers = {
        product_id: "Product ID",
        name: "Product Name",
        start_inventory: "Start Inventory",
        replenished: "Replenish",
        removed: "Remove",
        sold: "Sold",
        ending_inventory: "Ending Inventory",
      };
      if (filter.email) {
        headers["email"] = "Email";
      }
      if (filter.phone) {
        headers["phone"] = "Phone";
      }
      if (filter.title) {
        headers["title"] = "Title";
      }
      filename = `inventory_${filter.date_from}_${filter.date_to}.csv`;
    }
    ExportCsvFile(headers, searchData, filename);
  }

  //#endregion  

  return (
    <Container className="pt-4 pb-4 order-report-form small">
      <Tabs
        defaultActiveKey="report"
        transition={false}
        id="uncontrolled-tab-example"
        className="mb-3 no-print"
      >
        <Tab eventKey="report" title="Reports">
          <Col>
            <Accordion key="0" defaultActiveKey="0" className="no-print">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Filter</Accordion.Header>
                <Accordion.Body>
                  <Row className="mb-3">
                    <Col lg={3} className="">
                      <Form.Label htmlFor="id">
                        Order ID
                      </Form.Label>
                    </Col>
                    <Col lg={5} className="">
                      <Form.Control
                        type="text"
                        className="col-md-9"
                        id="id"
                        name="id"
                        value={filter.id}
                        onChange={filterChanged}
                      />
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col
                      lg={3}
                      className=" mb-1 mb-lg-0"
                    >
                      <Form.Label htmlFor="Department_id">
                        Department
                      </Form.Label>
                    </Col>
                    <Col lg={5} className="">
                      <Form.Select
                        aria-label="Department"
                        className="col-md-9"
                        id="Department_id"
                        name="Department_id"
                        value={filter.department_id}
                        onChange={filterChanged}
                      >
                        {deptoptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col
                      lg={3}
                      className=" mb-1 mb-lg-0"
                    >
                      <Form.Label htmlFor="User_id">
                        User Profile:
                      </Form.Label>
                    </Col>
                    <Col lg={5} className="">
                      <input id="user_text" name="user_text" className="form-control"  value={filter.user_text} onChange={filterChanged}  />

                      { /*
                      <Form.Select
                        aria-label="User"
                        id="user_id"
                        name="user_id"
                        value={filter.user_id}
                        onChange={filterChanged}
                      >
                        {useroptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Form.Select>
                        */}
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col
                      lg={3}
                      className=" mb-1 mb-lg-0"
                    >
                      <Form.Label htmlFor="cost_center_id">
                        Cost Center:
                      </Form.Label>
                    </Col>
                    <Col lg={5} className="">
                      <Form.Select
                        aria-label=""
                        className=""
                        id="cost_center_id"
                        name="cost_center_id"
                        value={filter.cost_center_id}
                        onChange={filterChanged}
                      >
                        {
                          cost_centeroptions && cost_centeroptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))
                        }
                      </Form.Select>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col lg={3} className="">
                      <Form.Label htmlFor="order_status_id">
                        Order Status:
                      </Form.Label>
                    </Col>
                    <Col lg={9} className="">
                      <Row className="align-items-center">
                        <Col md={7} className="">
                          <Form.Select
                            aria-label=""
                            className=""
                            id="order_status_id"
                            name="order_status_id"
                            value={filter.order_status_id}
                            onChange={filterChanged}
                          >
                            <option value="0" selected="selected">
                              ALL
                            </option>
                            <option value="1">Active</option>
                            <option value="19">Back Ordered</option>
                            <option value="14">Bindery</option>
                            <option value="12">Customer Service</option>
                            <option value="16">Fullfillment</option>
                            <option value="17">In Production</option>
                            <option value="13">Mailing</option>
                            <option value="18">On Hold</option>
                            <option value="6">Pending Approval</option>
                            <option value="21">Pending Artwork</option>
                            <option value="7">Pending Payment</option>
                            <option value="20">
                              Pending Precision Services Group Approval
                            </option>
                            <option value="8">Pending Proof Approval</option>
                            <option value="11">Plating</option>
                            <option value="10">Pre Press</option>
                            <option value="9">Printing</option>
                            <option value="5">Shipped</option>
                            <option value="15">Shipping</option>
                          </Form.Select>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col lg={3} className="">
                      <Form.Label htmlFor="date_from">
                        Order Date From:
                      </Form.Label>
                    </Col>
                    <Col lg={5}>
                      <div className="input-group">
                        <input
                          type="date"
                          value={filter.date_from}
                          className="form-control"
                          onChange={filterChanged}
                          id="date_from"
                          name="date_from"
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col lg={3} className="">
                      <Form.Label htmlFor="date_to">
                        Order Date Through:
                      </Form.Label>
                    </Col>
                    <Col lg={5}>
                      <div className="input-group">
                        <input
                          type="date"
                          className="form-control"
                          onChange={filterChanged}
                          id="date_to"
                          name="date_to"
                          value={filter.date_to}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col
                      lg={3}
                      className=" mb-1 mb-lg-0"
                    >
                      <Form.Label htmlFor="payment_method_id">
                        Payment Method:
                      </Form.Label>
                    </Col>
                    <Col lg={5}>
                      <Form.Select
                        aria-label=""
                        id="payment_method_id"
                        name="payment_method_id"
                        value={filter.payment_method_id}
                        onChange={filterChanged}
                      >
                        <option value="0" selected="selected">
                          ALL
                        </option>
                        <option value="1">On Invoice</option>
                      </Form.Select>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col
                      lg={3}
                      className=" mb-1 mb-lg-0"
                    >
                      <Form.Label htmlFor="order_type_id">
                        Order Type:
                      </Form.Label>
                    </Col>
                    <Col lg={5}>
                      <Form.Select
                        aria-label=""
                        className=""
                        id="order_type_id"
                        name="order_type_id"
                        value={filter.order_type_id}
                        onChange={filterChanged}
                      >
                        <option value="0" selected="selected">
                          ALL
                        </option>
                        <option value="1">Online Orders</option>
                        <option value="2">Commercial Orders</option>
                      </Form.Select>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col
                      lg={3}
                      className=" mb-1 mb-lg-0"
                    >
                      <Form.Label htmlFor="search_by">
                        Search By:
                      </Form.Label>
                    </Col>
                    <Col lg={5}>
                      <Form.Select
                        aria-label=""
                        className=""
                        id="search_by"
                        name="search_by"
                        value={filter.search_by}
                        onChange={filterChanged}
                      >
                        <option value="1" selected="selected">Order</option>
                        <option value="2">Product</option>
                        <option value="3">User</option>
                        <option value="4">Item</option>
                        <option value="5">Inventory</option>
                      </Form.Select>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col lg={3} className=" mb-1 mb-lg-0">
                      Field Options:

                    </Col>

                    <Col lg={9} className="d-flex flex-wrap gap-2">
                      {
                        filter.search_by == 1 &&
                        <>
                          <Form.Check label="Dept Code" className="align-items-md-center" inline name="department_code" id="department_code" onChange={filterChanged} />
                          <Form.Check label="Date Shipped" className="align-items-md-center" name="date_shipped" id="date_shipped" inline onChange={filterChanged} />
                          <Form.Check label="Ship To" className="align-items-md-center" name="ship_to" id="ship_to" inline onChange={filterChanged} />
                        </>
                      }
                      {
                        filter.search_by == 4 &&
                        <Form.Check label="Tracking Number" className="align-items-md-center" name="tracking_number" id="tracking_number" inline onChange={filterChanged} />
                      }
                      {
                        filter.search_by == 3 &&
                        <>
                          <Form.Check label="Email" className="align-items-md-center" name="email" id="email" inline onChange={filterChanged} />
                          <Form.Check label="Title" className="align-items-md-center" name="title" id="title" inline onChange={filterChanged} />
                          <Form.Check label="Phone" className="align-items-md-center" name="phone" id="phone" inline onChange={filterChanged} />
                        </>
                      }
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col lg={3} className="">
                      <Form.Label htmlFor="keyword_search">
                        Keyword Search:
                      </Form.Label>
                    </Col>
                    <Col lg={9} className="">
                      <Row className="align-items-center">
                        <Col lg={6} className="">
                          <Form.Control
                            type="text"
                            className=""
                            id="keyword_search"
                            name="keyword_search"
                            value={filter.keyword_search}
                            onChange={filterChanged}
                          />
                        </Col>
                        <Col lg={6} className="">
                          <Form.Label className="mb-0 text-mute">
                            (PO #, product name, or product description)
                          </Form.Label>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className="row mb-4">
                    <Col
                      lg={3}
                      className=""
                    >
                      &nbsp;
                    </Col>
                    <Col lg={9} className="justify-content-start">
                      <Button
                        className="button-product btn me-3"
                        onClick={handleSearch}
                      >Search</Button>
                      <Button
                        className="button-product btn"
                        onClick={handleExport}
                      >Export</Button>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Col className="mt-1">
              {
                (activeView.view_type == "order_summary" && <OrderTable searchData={searchData} setActiveView={setActiveView} />) ||
                (activeView.view_type == "product_summary" && <ProductTable searchData={searchData} setActiveView={setActiveView} />) ||
                (activeView.view_type == "user_summary" && <UserTable searchData={searchData} setActiveView={setActiveView} />) ||
                (activeView.view_type == "item_summary" && <ItemTable searchData={searchData} setActiveView={setActiveView} />) ||
                (activeView.view_type == "inventory_summary" && <InventoryTable searchData={searchData} setActiveView={setActiveView} />) ||

                (activeView.view_type == "inventory_detail" && <InventoryDetailTable productId={activeView.owner_id} setActiveView={setActiveView} />) ||
                (activeView.view_type == "order_detail" && <OrderDetailTable orderId={activeView.owner_id} parentView={parentView} setActiveView={setActiveView} />) ||
                (activeView.view_type == "product_detail" && <ProductDetailTable productId={activeView.owner_id} setActiveView={setActiveView} />)

              }
            </Col>
          </Col>
        </Tab>

        <Tab eventKey="inventory" title="Inventory" className="pb-5">
          <InventoryReport />
        </Tab>
      </Tabs>
    </Container>
  );
}

export default OrderReport;
