import React, { useState, useRef, useEffect } from "react";
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { TotalFooter } from '../hooks/usePagination'; // Path to your custom hook

export default function ItemTable({ searchData, setActiveView }) {

  const columns = [
    {
      name: "Department(s)",
      selector: (row) => row.department_name,
      sortable: true,
      width: "9rem",
    },
    {
      name: "Created By",
      selector: (row) => row.user_name,
      sortable: true,
    },
    {
      name: "Order ID",
      selector: (row) => <a href="#" onClick={(e) => { e.preventDefault(); e.stopPropagation(); setActiveView({ view_type: "order_detail", owner_id: row.order_id }) }}>{row.order_id}</a>,
      sortable: true,
      width: "7rem",
    },
    {
      name: "Item ID",
      selector: (row) => row.order_product_id,
      sortable: true,
      width: "6rem",
    },
    {
      name: "Item Description",
      selector: (row) => row.product_name,
      sortable: true,
      width: "6rem",
    },
    {
      name: "Qnty Sold",
      selector: (row) => row.quantity,
      sortable: true,
      width: "6rem",
    },
    {
      name: "Price",
      selector: (row) => row.total_price && "$" + row.total_price.toFixed(2),
      sortable: true,
      width: "6rem",
    }
  ];

  if (searchData && searchData[0].hasOwnProperty("tracking_number")) {
    columns.push({
      name: "Tracking Number",
      selector: (row) => row.tracking_number ? row.tracking_number : "",
      sortable: true,
      width: "8rem",
    });
  }

  if (searchData && searchData.length) {
    return (<DataTable
      className="overflow-visible"
      paginationPerPage={50}
      title=""
      columns={columns}
      data={searchData}
      defaultSortFieldID={1}
      responsive
      pagination
      footer={
        <div>
          <TotalFooter data={searchData} field="total_order_amount" />{" "}
          <TotalFooter data={searchData} field="total_items_amount" />
          <TotalFooter data={searchData} field="shipping_amount" />
          <TotalFooter data={searchData} field="tax_amount" />
        </div>
      }
    />
    );
  }
  else {
    return (
      <p className="bg-body-secondary text-center">No data found. Please select a search filters.</p>
    )
  }
}