import React, { useState, useRef, useEffect, useMemo } from "react";
import { Button, Col, Container, Row, Form, InputGroup } from "react-bootstrap";
import { FormatMysqlDate, ShowToast, ExportCsvFile } from 'common/functions';
import ProductService from "api/precision/ProductService";
import ReportService from "api/precision/ReportService";
import appConstant from "constant";
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { TotalFooter } from '../hooks/usePagination';
import Chart from "react-google-charts";

export default function InventoryReport() {
  const [productCategory, setProductCategory] = useState(null);
  const [invData, setInvData] = useState(null);
  const [exportCode, setExportCode] = useState(null);

  const [invFilter, SetInvFilter] = useState({
    id: null,
    customer_id: appConstant.CUSTOMER_ID,
    date_to: FormatMysqlDate(),
    status: "ACTIVE",
    product_category_id: [],
    customer_sku: false
  });

  const [curProductId, setCurProductId] = useState(null);
  const [curOptions, setCurOptions] = useState(null);

  useEffect(() => {
    // Get product category
    ProductService.productcategory({
      customer_id: appConstant.CUSTOMER_ID,
      "exclude_no_product": 0
    })
      .then((res) => {
        if (res.data.result == 1) {
          setProductCategory(res.data.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  // Event handler for when the select value changes
  const invFilterChanged = (ev) => {
    ev.stopPropagation();
    const field = ev.target.name;
    if (ev.target.type == "checkbox") {
      invFilter[field] = ev.target.checked;
      SetInvFilter({ ...invFilter, [field]: ev.target.checked });
    }
    else if (field == "product_category_id") {
      // console.log(ev.target.options);
      let newVals = [];
      const selectedOpt = ev.target.selectedOptions;
      for (let ii = 0; ii < selectedOpt.length; ii++) {
        newVals.push(selectedOpt[ii].value);
      };
      SetInvFilter({ ...invFilter, [field]: newVals });
    }
    else {
      invFilter[field] = ev.target.value;
      SetInvFilter({ ...invFilter, [field]: ev.target.value });
    }
  };

  const handleInvSearch = (ev) => {
    ev.stopPropagation();
    ReportService.inventoryFrontEnd(invFilter)
      .then((response) => {
        if (response.data.result == 1) {
          setInvData(response.data.data);
          setExportCode(response.data.export_code);
        }
      })
      .catch((error) => {
        console.error("Error fetching report data:", error);
      });
  };

  const handleExportInv = () => {
    /* 
    // Shot Export 
    let headers = {
      product_id: "Product ID",
      category_name: "Category",
      product_name: "Product",
      quantity: "Quantity",
      palettes: "Palettes",
      is_active: "Status",
      last_activity: "Last Activity",
      last_activity_date: "Last Activity Date",
      unit_price: "Unit Price",
      value: "Dollar Value"
    };
    let filename = `inventory_${invFilter.date_to}`;
    ExportCsvFile(headers, invData, filename);
    */
    // De do long export for inventory
    window.open("https://admin.psggo.com/api/download/" + exportCode, "_blank");
  }

  const columns = [
    {
      name: "Product ID",
      selector: (row) => <a href="#" onClick={ (ev) => setCurProductId(row.product_id)}>{row.product_id}</a>,
      sortable: true,
      width: "8rem",
    },
    {
      name: "",
      selector: (row) => (<img src={"https://www.precisionservicesgroup.com/img/products/thumbs/" + row.product_id + ".jpg"} />),
      sortable: true,
      width: "12rem",
    },
    {
      name: "Category",
      selector: (row) => row.category_name,
      sortable: true,
      width: "8rem",
    },
    {
      name: "Product",
      selector: (row) => row.product_name,
      sortable: true,
      width: "8rem",
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      sortable: true,
      width: "8rem",
    },
    {
      name: "Palletes",
      selector: (row) => row.palettes,
      sortable: true,
      width: "8rem",
    },
    {
      name: "Status",
      selector: (row) => row.is_active,
      sortable: true,
      width: "8rem",
    },
    {
      name: "Last Fill",
      selector: (row) => row.last_fill_date,
      sortable: true,
      width: "8rem",
    },
    {
      name: "Last Sold",
      selector: (row) => row.last_sold_date,
      sortable: true,
      width: "8rem",
    },
    {
      name: "Unit Price",
      selector: (row) => row.unit_price,
      sortable: true,
      width: "8rem",
    },
    {
      name: "Value",
      selector: (row) => row.value.toFixed(2),
      sortable: true,
    },
  ];
  // Optional add sku column
  if (invData && invData[0].hasOwnProperty("customer_sku")) {
    columns.push({
      name: "SKU",
      selector: (row) => row.customer_sku ? row.customer_sku : "",
      sortable: true,
      width: "8rem",
    });
  }

  const chartData = useMemo(() => {
    if (invData && curProductId) {
      // debugger;
      // Get product and build series
      const product = invData.filter((item) => item.product_id == curProductId)[0];
      let rv = [
        ['Month', 'Resplenish', 'Sales', 'Threhold']
      ];
      for (var month in product.fill) {
        rv.push([month, product.fill[month], product.sales[month], product.low_inventory_amount]);
      }

      setCurOptions(
        {
          title: product.product_id + " - " + product.product_name,
          pointsVisible: true,
          curveType: 'function',
          legend: {
            position: 'right'
          },
          hAxis: {
            slantedText: true,
            slantedTextAngle: 90
          },
        });
      return rv;
    }
    return [];
  }, [invData, curProductId]);



  return (
    <>
      <Row className="mb-1">
        <Col lg={3} className="or-form-label mb-1 mb-lg-0">
          <Form.Label htmlFor="date_to">
            Date
          </Form.Label>
        </Col>
        <Col lg={5} className="">
          <input
            type="date"
            className="form-control"
            id="date_to"
            name="date_to"
            value={invFilter.date_to}
            onChange={invFilterChanged}
          />
        </Col>
      </Row>
      <Row className="mb-3 mt-2 mt-lg-0">
        <Col lg={3} className="align-self-sm-center or-form-label mb-1 mb-lg-0" >
          <Form.Label htmlFor="status">
            Current Status
          </Form.Label>
        </Col>
        <Col lg={5} className="">
          <Form.Select aria-label="Default select example" className="" value={invFilter.status} onChange={invFilterChanged}>
            <option value="ACTIVE" selected="selected">Active Only</option>
            <option value="INACTIVE">Inactive Only</option>
            <option value="BOTH">Both</option>
          </Form.Select>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col lg={3} className="align-self-sm-center or-form-label mb-1 mb-lg-0"
        >
          <Form.Label htmlFor="product_category_id">
            Product Category
          </Form.Label>
        </Col>
        <Col lg={5} className="">
          <Form.Select name="product_category_id" id="product_category_id" multiple={true} size={5} value={invFilter.product_category_id} onChange={invFilterChanged}>
            {
              productCategory && productCategory.map((item) => (
                <option value={item.id}>{item.name}</option>
              )
              )
            }
          </Form.Select>
        </Col>
      </Row>
      <Row className="mb-3 mt-2 mt-lg-0">
        <Col lg={3} className="align-self-sm-center or-form-label mb-1 mb-lg-0">
          <Form.Label htmlFor="customer_sku">
            Field Options:
          </Form.Label>
        </Col>
        <Col lg={5} className="">
          <Form.Check label="SKU" className="align-items-md-center" inline id="customer_sku" name="customer_sku" onChange={invFilterChanged} />
        </Col>
      </Row>
      <Row className="row">
        <Col lg={3} className="d-flex justify-content-between d-none" >
          &nbsp;
        </Col>

        <Col lg={9} className="d-flex gap-4 mt-2 mt-lg-0">
          <Button className="button-product h-auto px-3 px-lg-4" onClick={handleInvSearch}>
            Search
          </Button>
          <Button
            className="button-product h-auto px-3 px-lg-4"
            onClick={handleExportInv}
          >Export</Button>
        </Col>
      </Row>

      {
        curProductId &&
        (<Row className="mb-1">
          <Col sm={12}>
            <Chart
              options={curOptions}
              width={"100%"}
              height={400}
              data={chartData}
              chartType="LineChart"
              loader={<div>Loading Chart...</div>}
            />
          </Col>
        </Row>)
      }
      {
        invData && invData.length > 0 && (
          <Row className="row">
            <Col>
                <DataTable
                  className="overflow-visible"
                  paginationPerPage={25}
                  columns={columns}
                  data={invData}
                  defaultSortFieldID={1}
                  responsive={true}
                  pagination
                />
            </Col>
          </Row>
        )
      }

    </>
  );

}