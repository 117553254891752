import { useState, useEffect } from "react";
import { ArrowUpShort } from "react-bootstrap-icons";

export default function ScrollToTop() {
  const [scrollTop, SetScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = event => {
      const backtotop = document.getElementById("back-to-top");
      if (window.scrollY > 100) {
        backtotop.classList.add('active')
      } else {
        backtotop.classList.remove('active')
      }
      SetScrollTop(window.scrollY);
    };
    window.addEventListener('scroll', handleScroll);
    // Tell react to destroy event
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollStart = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <a id="back-to-top" href="#" onClick={scrollStart} className="back-to-top d-flex justify-content-center">
    <ArrowUpShort />
  </a >
  );
}