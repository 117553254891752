import { toast } from "react-toastify";

/**
 * Validate Dom fields
 * Next sibbling element must be a <div class="invalid-feedback"></div>
 * Validate form fields
 * @param [string] fieldIds 
 */
export const FieldValidation = (fieldIds) => {
  let frmValid = true;
  let frmData = {};
  fieldIds.map((id) => {
    const el = document.getElementById(id);
    if (el) {
      el.classList.remove('is-invalid');
      if (!el.checkValidity()) {
        el.classList.add('is-invalid');
        frmValid = false;
      }
      else {
        el.classList.replace('is-invalid', 'is-valid');
        frmData[el.name] = el.value;
      }
    }
  });
  return { result: frmValid, "data": frmData };
};

/**
 * Validate email address
 * @param {*} email 
 * @returns Boolean
 */
export const isValidEmail = (email) => {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return regex.test(email);
};

// Function to validate the passwords
export const validatePasswords = () => {
  const { password } = formData;
  return (
    password.length >= 7 &&
    /\d/.test(password) &&
    /[a-zA-Z]/.test(password) &&
    password === confirmPassword
  );
};

/**
* Format Currency 
*/
export const ToCurrency = (value) => {
  if (value === null || value === '') return '';
  value = Number(value);
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
}

/**
 * Format Mysql date
 * @param {*} value = default today
 * @returns 
 */
export const FormatMysqlDate = (value) => {
  var str = new Date().toISOString();
  if (value) {
    var str = new Date(value).toISOString();
  }
  return str.substring(0, 10);
}

export const FormatUsDate = (value) => {
  var date = new Date(value);
  return date.getMonth() + '/' + date.getDate() + '/' + date.getFullYear();
}

/**
 * Check if a date time older than how many hours
 * @param string past - date string 
 * @param int hours 
 * @return bool
 */
export const OlderThan = (past, hours) => {
  let old = new Date(past);
  let cur = new Date();
  return (old.getTime() + (hours * 60 * 60 * 1000)) < cur.getTime();
}


export const ShowToast = (message, logged) => {
  if (message.includes("Success")) {
    toast.success(logged, {
      position: toast.POSITION.TOP_RIGHT,
      role: "alert",
    });
  }
  else if (message.includes("Error")) {
    toast.error(logged, {
      position: toast.POSITION.TOP_RIGHT,
      role: "alert",
    });
  }
}

/**
 * Export to csv from data
 * @param {*} headers - { fieldname: title, ... }
 * @param [*] data - [{ fieldname: value, ... }, ... ]
 * @param string filename (no extention). E.g. prebilling-2023-01
 */
export function ExportCsvFile(headers, data, filename) {
  let csv = [];
  if (headers) {
    csv.push(Object.values(headers).join(","));
    // console.log(csv);

    const cols = Object.keys(headers);
    data.map((item) => {
      const remain = cols.map(key => '"' + item[key] ? item[key] : "" + '"');
      csv.push(remain.join(","));
    });
  }

  const outFilename = filename + '.csv' || 'export.csv'
  var blob = new Blob([csv.join("\n")], { type: 'text/csv;charset=utf-8;' })
  var link = document.createElement('a')
  // Browsers that support HTML5 download attribute
  if (link.download !== undefined) {
    var url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', outFilename)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}

export function removeSpecialCharacters(input) {
  return input;
  // return input.replace(/^Nationwide.+?\s/gi, 'Nationwide\u00AE ');
}

export function encodeHtml(html) {
  return { __html: html };
}

export function showHideTabs() {
  const currentURL = window.location.href;
  if (currentURL.includes('UserRegistration')) {
    return false;
  } else if (currentURL.includes('login')) {
    return true;
  } else {
    return true;
  }
  return false;
}

