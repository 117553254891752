import React, { useState, useRef, useEffect } from "react";
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { TotalFooter } from '../hooks/usePagination'; // Path to your custom hook

// https://datatables.net/manual/styling/classes
export default function OrderTable({ searchData, setActiveView }) {
  useEffect(() => {

  }, []);

  let columns = [
    {
      name: "Order",
      selector: (row) => <a href="#" onClick={(e) => { e.preventDefault(); e.stopPropagation(); setActiveView({ view_type: "order_detail", owner_id: row.id }) }}>{row.id}</a>,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.order_status,
      sortable: true,
    },
    {
      name: "Created",
      selector: (row) => moment(row.created).format('MM/DD/YYYY'),
      sortable: true,
      width: "7rem",
    },
    {
      name: "Organization",
      selector: (row) => row.customer_name,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Department",
      selector: (row) => row.department_name,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Cost Center",
      selector: (row) => row.cost_center_name,
      sortable: true,
      width: "8rem",
    },
    {
      name: "PO Number",
      selector: (row) => row.Po_number,
      sortable: true,
      width: "8rem",
    },
    {
      name: "Subtotal",
      selector: (row) => row.total_items_amount && "$" + row.total_items_amount.toFixed(2),
      sortable: true,
      width: "8rem",
    },
    {
      name: "Tax",
      selector: (row) => row.tax_amount && "$" + row.tax_amount.toFixed(2),
      sortable: true,
      width: "8rem",
    },
    {
      name: "Shipping",
      selector: (row) => row.shipping_amount && "$" + row.shipping_amount.toFixed(2),
      sortable: true,
      width: "8rem",
    },
    {
      name: "Processing",
      selector: (row) => (row.processing_amount).toFixed(2),
      sortable: true,
      width: "8rem",
    },
    {
      name: "Total",
      selector: (row) => row.total_order_amount && "$" + row.total_order_amount.toFixed(2),
      sortable: true,
      width: "8rem",
    }
  ];

  if (searchData && searchData[0].hasOwnProperty("department_code")) {
    columns.push({
      name: "Department Code",
      selector: (row) => row.department_code? row.department_code : "",
      sortable: true,
      width: "8rem",
    });
  }

  if (searchData && searchData[0].hasOwnProperty("date_shipped")) {
    columns.push({
      name: "Date Shipped",
      selector: (row) => row.date_shipped ? row.date_shipped : "",
      sortable: true,
      width: "8rem",
    });
  }
  if (searchData && searchData[0].hasOwnProperty("ship_to")) {
    columns.push({
      name: "Ship to",
      selector: (row) => row.ship_to ? row.ship_to : "",
      sortable: true,
      width: "8rem",
    });
  }

  if (searchData && searchData.length) {
    return (<DataTable
      className="overflow-visible"
      striped
      paginationPerPage={25}
      columns={columns}
      data={searchData}
      defaultSortFieldID={1}
      responsive={true}
      pagination
      footer={
        <div>
          <TotalFooter data={searchData} field="total_order_amount" />
          <TotalFooter data={searchData} field="total_items_amount" />
          <TotalFooter data={searchData} field="shipping_amount" />
          <TotalFooter data={searchData} field="tax_amount" />
        </div>
      }
    />
    );
  }
  else {
    return (
      <p className="bg-body-secondary text-center">No data found. Please select a search filters.</p>
    )
  }
}