import React, { useEffect, useState, lazy, Suspense } from "react";
import Index from "./components";
import { connect, useDispatch } from 'react-redux';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
// action
import { checkAutoLogin, isLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
import { loginAction } from 'store/actions/AuthActions';
import { ShoppingCartProvider } from "./components/cart/ShoppingCartContext";
import UserRegistration from "components/cart/UserRegistration";

const Login = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import('./components/authenticate/Login')), 500);
  });
});

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

function App(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    checkAutoLogin(dispatch, navigate);
  }, []);


  let routeblog = (
    <Routes>
      <Route path='/login' element={<Login />} />
      <Route path='/UserRegistration' element={<UserRegistration />} />
    </Routes>
  );
  if (props.isAuthenticated) {
    return (
      <>
        <Suspense fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
        >
          <div className="wrapper">
            <ShoppingCartProvider>
              <Index />
            </ShoppingCartProvider>
          </div>
        </Suspense>
      </>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
        >
          {routeblog}
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(App));
//export default App;
