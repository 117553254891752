import React, { useState, useEffect } from "react";
import { Col, Container, Row, Breadcrumb } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import appConstant from "constant";
import CostCenterService from "api/precision/CostCenterService";
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

function CostCenterReport() {
  const [searchData, setSearchData] = useState([]);
  useEffect(() => {
    document.title = `Cost Center Report`;

  }, []);
  //#region Delete

  const handleDelete = (id, name) => {
    swal({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete the Cost Center ' + name + '?',
      icon: 'warning',
      buttons: {
        cancel: 'No, cancel',
        confirm: 'Yes, Delete',
      },
    }).then((result) => {
      if (result) {
        const article = {
          "id": id,
          "customer_id": appConstant.CUSTOMER_ID,
          "permanent": 0
        }
        CostCenterService.CostDelete(article)
          .then((res) => {
            if (res.data.length > 10) {
              console.log("Cost Center Deleted Successfully.");
            }
          })
          .catch((error) => {
            console.error("Error in  Cost Center delete api:", error);
          });
        swal('Cost Center Deleted!', '', 'success');
      }
    });
  };
  //#endregion
  //#region  Search
  useEffect(() => {
    SearchCost();
  }, []);
  const SearchCost = () => {
    var article = {
      "filter": {
        "customer_id": appConstant.CUSTOMER_ID
      },
      "return": [
        "id",
        "name",
        "department_list"
      ]
    }
    CostCenterService.costcenterlist(article)
      .then((response) => {
        if (response.data.result == 1) {
          setSearchData(response.data.data);
        }
        else {
          setSearchData([]);
        }
      })
      .catch((error) => {
        console.error("Error in  Cost Center report api:", error);
      });
  };

  const columns = [
    {
      name: "",
      selector: (row) => (
        <div className="">
          <Link to={"/CostCenter/CostCenterDetail/" + row.id} className="text-primary me-2">Modify</Link>
          <a href="#" className="text-primary" onClick={() => handleDelete(row.id, row.name)}>Delete</a>
        </div>
      ),
      sortable: false,
      width: "15rem", // Set the width to 150 pixels
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "15rem", // Set the width to 150 pixels
    },


  ];
  //#endregion

  return (

    <Container className="pt-4 pb-4 order-report-form small">
      <Breadcrumb>
        <Breadcrumb.Item active>Cost Centers</Breadcrumb.Item>
      </Breadcrumb>
      <Col>
        <Accordion key="0" defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Filter</Accordion.Header>
            <Accordion.Body>
              <Row className="mb-2">

                <Col lg={4} className="d-flex justify-content-between">
                  <div className="d-flex gap-3 flex-wrap">
                    <Link to={"/CostCenter/CostCenterDetail"} className="btn btn-primary"> Add a New Cost Center</Link>
                  </div>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Col>
          <DataTable
            paginationPerPage={50}
            title=""
            columns={columns}
            data={searchData}
            defaultSortFieldID={1}
            responsive={true}
            pagination
          />
        </Col>
      </Col>
    </Container>

  );
}

export default CostCenterReport;
