import React, { useState, useRef, useEffect } from "react";
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { TotalFooter } from '../hooks/usePagination'; // Path to your custom hook

export default function ProductTable({ searchData, setActiveView }) {

  useEffect(() => {
  }, []);

  const columns = [
    {
      name: "Product #",
      selector: (row) => <a href="#" onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setActiveView({ view_type: "product_detail", owner_id: row.product_id })
      }}>{row.product_id}</a>,
      sortable: true,
      width: "6rem"
    },
    {
      name: "Name",
      selector: (row) => row.product_name,
      sortable: true,
    },
    {
      name: "Sold",
      selector: (row) => row.quantity,
      sortable: true,
      width: "6rem", // Set the width to 150 pixels
    },
    {
      name: "Price",
      selector: (row) => row.total_price,
      sortable: true,
      width: "6rem", // Set the width to 150 pixels
    },
    {
      name: "Shipping",
      selector: (row) => row.shipping_cost,
      sortable: true,
      width: "6rem", // Set the width to 150 pixels
    },
    {
      name: "Tax",
      selector: (row) => row.tax_amount,
      sortable: true,
      width: "6rem", // Set the width to 150 pixels
    },
    {
      name: "Handling",
      selector: (row) => row.pick_fee,
      sortable: true,
      width: "6rem", // Set the width to 150 pixels
    },
    {
      name: "Total",
      selector: (row) => row.subtotal && "$" + row.subtotal.toFixed(2),
      sortable: true,
      width: "6rem", // Set the width to 150 pixels
    }
  ];

  if (searchData && searchData.length) {
    return (<DataTable
      className="overflow-visible"
      paginationPerPage={25}
      columns={columns}
      data={searchData}
      defaultSortFieldID={1}
      responsive={true}
      pagination
      footer={
        <div>
          <TotalFooter data={searchData} field="total_order_amount" />{" "}
          <TotalFooter data={searchData} field="total_items_amount" />
          <TotalFooter data={searchData} field="shipping_amount" />
          <TotalFooter data={searchData} field="tax_amount" />
        </div>
      }
    />
      
    
    );
  }
  else {
    return (
      <p className="bg-body-secondary text-center">No data found. Please select a search filters.</p>
    )
  }
}