import React, { useState, useEffect, useMemo } from 'react'
import moment from 'moment';
import OrderService from 'api/precision/OrderService';


export default function OrderDetailTable({ orderId, parentView, setActiveView }) {
  const [orderDetail, setOrderDetail] = useState(null);


  useEffect(() => {
    if (orderId) {
      OrderService.query(orderId)
        .then((response) => {
          if (response.data.result == 1) {
            // console.log(response.data.data);
            setOrderDetail(response.data.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching product data:", error);
        });
    }
  }, [orderId]);


  const handlePrint = (e) => {
    e.stopPropagation();
    e.preventDefault();
    window.print();
  }

  const handleNav = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveView({ view_type: parentView, owner_id: orderId });
  }

  const parentTitle = useMemo(() => {
    if (parentView == "order_summary") {
      return "Orders";
    }
    else if (parentView == "product_summary") {
      return "Products";
    }
    else if (parentView == "item_summary") {
      return "Items";
    }
  }, [parentView]);

  return (
    <>
      <nav className="pt-3 no-print" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="#" onClick={handleNav}>{parentTitle}</a></li>
          <li className="breadcrumb-item active" aria-current="page">{orderId}</li>
        </ol>
      </nav>

      <section className="preview_order_section order_submitted mt-3">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="preview_main">
                <h1>Order Detail</h1>
              </div>
            </div>

            <div className="container-1" >
              <div className="col-11 d-flex justify-content-between">
                <div className="button-group">
                  <div className="preview_delivery">
                    <h3>Delivery To:</h3>
                    <div>
                      <p>{orderDetail && orderDetail.shipping_address.name}</p>
                    </div>
                    <div >
                      <p >{orderDetail && orderDetail.shipping_address.line1}</p>
                    </div>
                    <div >
                      <p >{orderDetail && orderDetail.shipping_address.city}, {orderDetail && orderDetail.shipping_address.state}, {orderDetail && orderDetail.shipping_address.zip_code}</p>
                    </div>
                  </div>
                </div>


                <div className="preview_delivery">
                  <h3>Order Number: <span> {orderId}
                  </span></h3>
                  <p>Order Date: {orderDetail && moment(orderDetail.created).format('MM/DD/YYYY H:MM A')}</p>
                  <p>Order Status: {orderDetail && orderDetail.order_status}</p>
                  <p>Note: {orderDetail && orderDetail.shipping_instruction}</p>
                  <br></br>
                  <br></br>
                </div>
              </div>

            </div>
            <div className="col-lg-12">
              <div className="preview_order_main">
                <table className="table-blue"  >
                  <thead>
                    <tr>
                      <th style={{ width: "40%", paddingLeft: "20px" }}>Product/Description</th>
                      <th className="text-center" style={{ width: "10%" }}>Item ID</th>
                      <th className="text-center" style={{ width: "25%" }}>Estimated Ship Date</th>
                      <th className="text-center" style={{ width: "25%" }}>Quantity</th>
                    </tr>
                  </thead>
                </table>

                <table className="table table-borderless rounded-table">
                  <tbody>
                    {orderDetail && orderDetail.order_products && orderDetail.order_products.map((product) => (
                      <tr key={product.id}>
                        <td className="table-data" style={{ width: "40%" }}>
                          {product.product_name}</td>
                        <td className="table-data text-center" style={{ width: "10%" }}>
                          {product.id}</td>
                        <td className="table-data text-center" style={{ width: "25%" }}>
                          {moment(product.estimated_delivery_date).format('MM-DD-YYYY')}
                        </td>
                        <td className="table-data text-center" style={{ width: "25%" }}>{product.quantity} </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="container-1 no-print">
                  <div className="row">
                    <div className="col-12 d-flex justify-content-between">
                      <div className="button-group">
                      </div>
                      <div className="ml-auto">
                        {orderDetail && orderDetail.order_status == "Shipped" &&
                          orderDetail.order_status_name == "Shipped" &&
                          <button title="Place order item(s) in your cart" className="button-product m-1 text-bold" onClick={handleReorder}><b>Reorder</b></button>
                        }
                        <button title="Print Order" className="button-product m-1 text-bold" onClick={handlePrint}><b>Print Receipt</b></button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
