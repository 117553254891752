import { useState, useEffect } from "react";
import { Modal, Card } from 'react-bootstrap';

export default function ZoomBox({ srcSmall, srcLarge, showModal, handleCloseModal }) {

  const transalteZoom = (e) => {
    e.stopPropagation();
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = (e.pageX - left) / width * 100;
    const y = (e.pageY - top) / height * 100;
    e.target.style.backgroundPosition = `${x}% ${y}%`;
    e.target.style.backgroundSize = "auto";
    document.getElementById("zoom-image").style.opacity = 0;
  }

  const handleReset = (e) => {
    e.stopPropagation();
    const { left, top, width, height } = e.target.getBoundingClientRect();
    e.target.style.backgroundPosition = `-${left}% -${top}%`;
    e.target.style.backgroundSize = "cover";
  }

  return (
    <div>
      <Modal show={showModal} onHide={handleCloseModal} size={"fullscreen"}>
        <Modal.Body className="">
          <button
            type="button" 
            className="btn-close" 
            aria-label="Close"
            style={{"position": "absolute", "top": "5px", "right": "5px", "fontSize": "12px"}}
            onClick={handleCloseModal}
          >
          </button>
          <figure onMouseMove={transalteZoom} onMouseLeave={handleReset}
            style={{ "backgroundImage": `url(${srcLarge})`, "backgroundRepeat": "no-repeat" }}>
            <img id="zoom-image" className="img-fluid" src={srcSmall} 
              style={{ "display": "block", "width": "100%", "pointerEvents": "none" }}
            />
          </figure>
        </Modal.Body>
      </Modal>
    </div>
  );
}
