import React, { useState, useEffect } from 'react'
import swal from 'sweetalert';
import { Modal, Button, Col, Row, Form, InputGroup, Card, FormLabel } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import ProductService from "../../api/precision/ProductService";
import appConstant from 'constant';
import { useShoppingCart } from '../cart/ShoppingCartContext';

const ProductDestroy = ({ showModal, handleCloseModal, idToDelete }) => {
  const navigation = useNavigate();
  const { addToCart, cartItems } = useShoppingCart();

  const [formData, SetformData] = useState({
    id: idToDelete,
    name: '',
    initial_grouping_date: '',
    is_in_inventory: 0,
  });

  //#region  GET
  useEffect(() => {
    if (idToDelete > 0) {
      setProduct();
      SetformData({ ...formData, id: idToDelete });
    }
  }, [idToDelete]);

  function setProduct() {
    if (idToDelete === undefined) {
      return;
    }
    ProductService.query(idToDelete)
      .then((response) => {
        if (response.data.result == 1 && response.data.data.id > 0) {
          SetformData((response.data.data));
        }
      })
      .catch((error) => {
        console.error("Error fetching Product get data:", error);
      });
  }
  //#endregion 


  const handleDestroy = (e) => {
    // Check cart is empty and only destroy products
    let okToDestroy = true;
    cartItems.map((item) => {
      if (item.description_1 && item.description_1.indexOf("TO BE DESTROYED") > 0) {

      }
      else {
        okToDestroy = false;
        swal('Your shopping cart must contain only destroy products!', '', 'error');
      }
    });
    if (okToDestroy) {
      formData.description_1 = "Ship to Precision Inventory - TO BE DESTROYED";
      addToCart(formData, formData.actual_inventory);
      handleCloseModal();
    }
  };
  const handleCancel = () => {
    navigation('/Product/ProductReport/');
    handleCloseModal();
  };

  return (
    <div>
      <Modal show={showModal} onHide={handleCloseModal} size="md">
        <Modal.Header closeButton>
          <Modal.Title style={{ "color": "#2950a3" }}>Destroy Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Header>
              <h6>
                Please review the following product information:</h6>
            </Card.Header>
            <Card.Body>
              <Col className="pb-4 order-report-form small">

                <Row className="mb-2">
                  <Col lg={3} className="">
                    Id
                  </Col>
                  <Col lg={9} className="">
                    <label>{formData.id}</label>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col lg={3} className="">
                    Name
                  </Col>
                  <Col lg={9} className="">
                    <label>{formData.name}</label>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col lg={3} className="">
                    Created
                  </Col>
                  <Col lg={9} className="">
                    <label>{formData.created}</label>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col lg={3} className="">
                    Inventory
                  </Col>
                  <Col lg={9} className="">
                    <label>{formData.actual_inventory}</label>
                  </Col>
                </Row>

                <div className="text-muted small mt-3">
                  By clicking "OK" button, the product will be placed into your cart.
                  You must check-out to complete the process.<br /><br />
                  Are you sure you want to destroy the product?
                </div>

                <Row className="row mt-4">
                  <Col lg={2} className="d-flex ">
                    <div className="d-flex gap-3 flex-end">
                      <Button
                        className="btn btn-primary"
                        onClick={handleDestroy}
                      >
                        OK
                      </Button>
                    </div>
                  </Col>
                  <Col lg={2} className="d-flex ">
                    <div className="d-flex gap-3 flex-end">
                      <Button
                        className="btn btn-primary"
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>

            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default ProductDestroy;