import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from 'react-router-dom';
import ProductService from "api/precision/ProductService";
import { toast } from "react-toastify";
import ProductDescription from "./ProductDescription";
import { removeSpecialCharacters, encodeHtml } from '../../common/functions'


function ProductListByCategory() {

  const [catName, setCateName] = useState('');
  const [subCatName, setSubCateName] = useState("");
  const { categoryId } = useParams("");
  const [products, setproducts] = useState([])
  const [filter, setfilter] = useState({
    customer_id: 1850,
    name: "",
    // Optional
    product_category_id: categoryId,
    is_active: 1
  });

  const [showModal, setShowModal] = useState(false);
  const [description, setDescription] = useState("");

  const handleLinkClick = (desc) => {
    setShowModal(true);
    setDescription(desc);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    document.title = 'Product List'; // Set your desired page title here

    if (Number.isInteger(parseInt(categoryId))) {

      setfilter({ ...filter, product_category_id: categoryId });
      if (categoryId == 2100) {
        setSubCateName("Ideal for puppy/kitten or new clients kits");
      }
      else if (categoryId == 2214) {
        setSubCateName("Ideal for sharing the Pet HealthZone with your clients and team");
      }

      ProductService.productcategory(filter)
        .then((response) => {
          //  let cat=response.data.data.find(item => item.id === parseInt(categoryId));
          setCateName(response.data.data.find((category) => category.id === parseInt(categoryId)).name);
          // console.log(catName);
        })
        .catch((error) => {
          console.error("Error fetching report data:", error);
        });
    }
    else {
      setfilter({ ...filter, name: categoryId, product_category_id: null });
      setCateName(categoryId);
      filter.name = categoryId;
      filter.product_category_id = null;
    }

  }, []);


  useEffect(() => {
    ProductService.productlist(filter)
      .then((response) => {
        if (response.data.result = 1 && response.data.data) {
          setproducts(response.data.data);
        }
        else {
          setCateName("No current products for " + categoryId);
        }
        toast.success("Product Get Successfully!", {
          position: toast.POSITION.TOP_RIGHT,
          role: "alert",
        });
      })
      .catch((error) => {
        console.error("Error fetching report data:", error);
      });
  }, []);


  const handleImageError = (e) => {
    e.stopPropagation();
    e.target.src = '/dist/img/noimages.png'; // Provide the path to your default or empty image
  };


  return (
    <>
      <section >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center">
                <h2 className="categoryName">{catName}</h2>
                <p className="categoryName-subtitle">{subCatName}</p>
              </div>
            </div>
          </div>

          <div className="row mb-5">
            {products.map((product, index) => (
              <div className="col-lg-3 col-md-4 col-sm-12" key={index}>
                <div className="insurance_main_bottom text-center">
                  <div className="">
                    <ProductDescription productId={product.id} categoryId={categoryId} description={product.additional_info} >
                    </ProductDescription>
                  </div>

                  <div className="insurance_a ">
                    <Link
                      className="education_a button-productbycategory-list"
                      to={'/product/' + product.id}
                    >
                      {product.product_category_id == 2214 && product.name == "Top 10 Canine Health Conditions PHZ" ? "Top 10 Canine Health Conditions" : product.name}
                    </Link>
                  </div>

                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>

  );

}

export default ProductListByCategory;
