import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import {Provider} from 'react-redux';
import {store} from './store/store';
import App from "./App";
import { createRoot } from 'react-dom/client';
// import reportWebVitals from "./reportWebVitals";
// import * as serviceWorker from "./serviceWorker";
import 'react-toastify/dist/ReactToastify.css';
import './styles/App.css';
 

 

const root = document.getElementById('root');
const rootElement = (
  <React.StrictMode>
  <Provider store = {store}>
    <BrowserRouter basename='/'>        
        <App />          
    </BrowserRouter>
  </Provider>
</React.StrictMode>
);

const container = createRoot(root);
container.render(rootElement);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
// serviceWorker.unregister();