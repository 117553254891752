import http from "./Endpoint";
import appConstant from "constant";

class AddressService {

  projection = {
    "order_by": {
      "name": "ASC"
    },
    "limit": 100,
    "offset": 0
  }

  addressValidate(data) {
    return http().post("/address/addressValidate", data);
  }


  query(id) {
    let article = {
      "filter": {
        "id": id
      },
      "return": [
        "id",
      ]
    }
    return http().post("/address/query", article);
  }

}

export default new AddressService();