import React, { useState, useEffect } from "react";
import { Button, Col, Breadcrumb, Row, Form, InputGroup, Card } from "react-bootstrap";
import { useParams, useNavigate } from 'react-router-dom';
import UserService from "../../api/precision/UserService";
import { FieldValidation } from "common/functions";
import { getProfile } from '../../services/AuthService';
import swal from 'sweetalert';
function UserChangePswd() {
  const userDetail = JSON.parse(getProfile());
  const navigation = useNavigate();

  const [formData, SetFormData] = useState({
    password: null,
  });
  useEffect(() => {
    document.title = 'User Change Password'; // Set your desired page title here
  }, []);

  //#region  Change Password
  const handleChanged = (evt) => {
    const value = evt.target.value;
    SetFormData({
      ...formData,
      [evt.target.name]: value,
    });

  };

  const ChangePasword = (ev) => {
    ev.stopPropagation();
    let requiredFields = [
      "password",
      "confirmPassword",
    ]
    let res = FieldValidation(requiredFields);
    if (res.result) {
      const article = {
        "id": userDetail.user_id,
        "is_active": 0,
      }
      UserService.UserUpdate(article)
        .then((res) => {
          if (res.data.length > 10) {
            navigation('/Users/Account');
            swal('User Password change successfully!', '', 'success');
          }
          else {
            console.log("Error in  User update api:");
          }
        })
        .catch((error) => {
          console.error("Error in  User update api:", error);
        });
    }
  };

  //#endregion
  //#region Pswd validate and chk length
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isValid, setIsValid] = useState(true);

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    {


      SetFormData({
        ...formData,
        password: newPassword,
      })
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const newPassword = e.target.value;
    setConfirmPassword(newPassword);
  };

  // Function to validate the passwords
  const validatePasswords = () => {
    const { password } = formData;
    return (
      password.length >= 7 &&
      /\d/.test(password) &&
      /[a-zA-Z]/.test(password) &&
      password === confirmPassword
    );
  };

  // Update isValid whenever the passwords change
  React.useEffect(() => {
    // if(formData.password.length>0){
    //     setIsValid(validatePasswords());
    // }
    {
      formData.password &&
        setIsValid(validatePasswords())
    }

  }, [formData, confirmPassword]);
  //#endregion

  return (
    <>
      <br></br>
      <h3 className="userheader">Change My Password</h3>
      <Card>
        <Card.Body className="col-lg-12 row">
          <Col lg={3}></Col>
          <Col lg={9}>
            <Col className="pb-4 order-report-form small">
              <Row className="mb-2">
                <Col lg={3} className="">

                  <Form.Label htmlFor="CurrentPassword">
                    Current Password
                  </Form.Label>
                </Col>
                <Col lg={9}>
                  <Form.Control
                    type="CurrentPassword"
                    className="col-sm-5"
                    id="CurrentPassword"
                    name="CurrentPassword"
                    value={formData.CurrentPassword}
                    required
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={3} className="">

                  <Form.Label htmlFor="password">
                    New Password
                  </Form.Label>
                </Col>
                <Col lg={9}>
                  <Form.Control
                    type="password"
                    className="col-sm-5  form-control form-control-sm"
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handlePasswordChange}
                    required
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={3} className="">

                  <Form.Label htmlFor="confirmPassword">
                    Repeat New Password
                  </Form.Label>
                </Col>
                <Col lg={9}>
                  <Form.Control
                    type="password"
                    className="col-sm-5  form-control form-control-sm"
                    id="confirmPassword"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    required
                  />
                </Col>
              </Row>
              {!isValid && (
                <p className="invalid text-danger">
                  Passwords must match and be at least 7 characters long, containing one number and one letter.
                </p>
              )}


              <Row className="row mt-4">
                <Col
                  lg={9}
                  className=""
                >

                </Col>
                <Col lg={2} className="d-flex ">
                  <div className="d-flex gap-3 flex-end">
                    <Button
                      className="btn btn-primary"
                      onClick={ChangePasword}
                    >
                      Save
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Col>

        </Card.Body>
      </Card>
    </>
  );
}

export default UserChangePswd;
