import axios from 'axios';
import swal from "sweetalert";
import secureLocalStorage from "react-secure-storage";
import appConstant from "constant";

import {
  loginConfirmedAction,
  Logout
} from '../store/actions/AuthActions';

export function signUp(email, password) {
  //axios call
  const postData = {
    email,
    password,
    returnSecureToken: true,
  };
  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData,
  );
}

export function login(username, password) {
  const loginFormData = new FormData();
  loginFormData.append("username", username)
  loginFormData.append("password", password)
  return axios.post("/auth", loginFormData);
}

export function formatError(errorResponse) {
  switch (errorResponse.error.message) {
    case 'EMAIL_EXISTS':
      swal("Oops", "Email already exists", "error");
      break;
    case 'EMAIL_NOT_FOUND':
      swal("Oops", "Email not found", "error", { button: "Try Again!", });
      break;
    case 'INVALID_PASSWORD':
      swal("Oops", "Invalid Password", "error", { button: "Try Again!", });
      break;
    case 'USER_DISABLED':
      return 'User Disabled';

    default:
      return '';
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  tokenDetails.expireDate = new Date(
    new Date().getTime() + tokenDetails.expiresIn * 1000,
  );
  secureLocalStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}

export function saveProfile(data) {
  secureLocalStorage.setItem(appConstant.CUSTOMER_PROFILE, JSON.stringify(data));
}

export function getProfile() {
  return secureLocalStorage.getItem(appConstant.CUSTOMER_PROFILE);
}


export function runLogoutTimer(dispatch, timer, navigate) {
  setTimeout(() => {
    dispatch(Logout(navigate));
  }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
  const tokenDetailsString = secureLocalStorage.getItem('userDetails');
  let tokenDetails = '';
  if (!tokenDetailsString) {
    dispatch(Logout(navigate));
    return;
  }

  tokenDetails = JSON.parse(tokenDetailsString);
  let expireDate = new Date(tokenDetails.expireDate);
  let todaysDate = new Date();

  if (todaysDate > expireDate) {
    dispatch(Logout(navigate));
    return;
  }

  dispatch(loginConfirmedAction(tokenDetails));

  const timer = expireDate.getTime() - todaysDate.getTime();
  runLogoutTimer(dispatch, timer, navigate);
}

export function isLogin() {
  const tokenDetailsString = secureLocalStorage.getItem('userDetails');

  if (tokenDetailsString) {
    return true;
  } else {
    return false;
  }
}

