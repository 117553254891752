import React, { useContext, useState } from "react";
import LoadingBar from "react-top-loading-bar";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Outlet } from "react-router-dom";
import Page404 from "./error_pages/Page404";
import NavBarMenu from "./navigation/NavBarMenu";
import ScrollToTop from "./navigation/ScrollToTop";
import Category from './cart/Category.js'
import UserRegistration from './cart/UserRegistration'
// import Pet_Care_Education from './cart/Pet_Care_Education'
import ProductListByCategory from './cart/ProductListByCategory'
import Product from './cart/Product'
import Preview from './cart/Preview'
import Order from './cart/Order'
import Orders from './cart/Orders'
import Shipping from './cart/Shipping'
import { useLocation } from 'react-router-dom';
import Footer from "./navigation/Footer";
import OrderReport from './reports/OrderReport';
import UserReport from './userreports/UserReport';
import UserEdit from './userreports/UserEdit';
import Account from './userreports/Account';
import UserPersonalInfo from './userreports/UserPersonalInfo';
import UserAddress from './userreports/UserAddress';
import UserChangePswd from './userreports/UserChangePswd';
import DepartmentReport from './department/DepartmentReport';
import DepartmentDetails from './department/DepartmentDetails';
import DepartmentTabs from './department/DepartmentTabs';
import DepartmentAddress from './department/DepartmentAddress';
import CostCenterDetail from './costcenter/CostCenterDetail';
import CostCenterReport from './costcenter/CostCenterReport';
import DepartmentAddressReport from './department/DepartmentAddressReport';
import ProductReport from './productreport/ProductReport';
import ProductDetail from './productreport/ProductDetail';

const allroutes = [
  { url: "", component: <Category /> },
  { url: "/cart/UserRegistration", component: <UserRegistration /> },
  { url: "/category/:categoryId", component: <ProductListByCategory /> },
  { url: "/product/:productId", component: <Product /> },
  { url: "/cart/preview", component: <Preview /> },
  { url: "/cart/shipping", component: <Shipping /> },
  { url: "/order/:orderId?/:orderType?", component: <Order /> },
  { url: "/orders", component: <Orders /> },
  { url: "/reports/orderreport", component: <OrderReport /> },
  { url: "/userreport", component: <UserReport /> },
  { url: "/Users/ModifyUser/:pagekey?", component: <UserEdit /> },
  { url: "/Users/AddUser", component: <UserEdit /> },
  { url: "/Users/Account", component: <Account /> },
  { url: "/Account/UserPersonalInfo", component: <UserPersonalInfo /> },
  { url: "/Account/UserAddress", component: <UserAddress /> },
  { url: "/Account/UserChangePswd", component: <UserChangePswd /> },
  { url: "/Departments/DepartmentReport", component: <DepartmentReport /> },
  { url: "/Departments/DepartmentDetails/:pagekey?", component: <DepartmentDetails /> },
  { url: "/Departments/DepartmentTabs/:pagekey?/:deptname?", component: <DepartmentTabs /> },
  { url: "/Departments/DepartmentAddress", component: <DepartmentAddress /> },
  { url: "/CostCenter/CostCenterDetail/:pagekey?", component: <CostCenterDetail /> },
  { url: "/CostCenter/CostCenterReport", component: <CostCenterReport /> },
  // { url: "/Departments/DepartmentAddressReport", component: <DepartmentAddressReport /> },
  { url: "/Product/ProductReport", component: <ProductReport /> },
  { url: "/Product/ProductDetail/:pagekey?", component: <ProductDetail /> },
];



const Markup = () => {
  function NotFound() {
    const url = allroutes.map((route) => route.url);
    const path = useLocation().pathname.split('/').pop();

    if (!url.includes(path)) {
      return <Page404 />;
    }
  }

  return (
    <>
      <Routes>
        <Route element={<MainLayout />}>
          {allroutes.map((data, i) => (
            <Route
              key={`${data.url}`}
              exact
              path={data.url}
              element={data.component}
            />
          ))}
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  const sideMenu = useSelector((state) => state.sideMenu);

  return (
    <>
      <LoadingBar height={3} color="#28a745" progress={100} />
      <NavBarMenu></NavBarMenu>
      <div className="main">
        <Outlet />
      </div>
      <Footer />
    </>
  );
}

export default Markup;
