import http from "./Endpoint";
import appConstant from "constant";

class OrderService {

  projection = {
    "order_by": {
      "id": "DESC"
    },
    "limit": 100,
    "offset": 0
  }

  /**
   * List order for a user
   */
  list(data) {
    let article = {
      "filter": {
        "id": data.id,
        "customer_id": appConstant.CUSTOMER_ID,
        "user_id": data.user_id,
        "order_status_id_id": data.order_status_id,
        "date_from": data.date_from,
        "date_to": data.date_to,
        "cost_center_id": data.cost_center_id,
        "department_id": data.Department_id,
        "payment_method_id": data.payment_method_id,
        "date_by": data.date_by,
        "search_by": data.search_by,
        "order_type_id_id": data.order_type_id,
        "po_number": ""
      },
      "projection": {
        "order_by": {
          "id": "DESC",
        },
        "limit": 1000,
        "offset": 0
      },
      "extra": [
        "found_rows"
      ]
    }
    return http().post("/order/list", article);
  }

  listOrderProducts(productId) {
    let article = {
      "filter": {
        "customer_id": appConstant.CUSTOMER_ID,
        "product_id": productId,
        "date_from": "2023-09-22",
        "date_to": "2023-09-22"
      },
      "projection": {
        "order_by": {
          "order_id": "DESC",
          "created": "ASC"
        },
        "limit": 500,
        "offset": 0
      },
      "return": []
    }
    return http().post("/order/listOrderProducts", article);
  }


  query(id) {
    let article = {
      "filter": {
        "id": id
      },
      "return": [
        "id",
        "customer_id",
        "customer_name",
        "user_id",
        "user_name",
        "order_status_name",
        "order_type_name",
        "delivery_method_name",
        "payment_method_name",
        "order_type_name",
        "created",
        "order_products",
        "shipping_address",
        "shipping_instruction"
      ]
    }
    return http().post("/order/query", article);
  }

  addorder(article) {
    return http().post("/order/add", article);
  }

  cancel(article) {
    return http().post("/order/cancel", article);
  }

}

export default new OrderService();