import http from "./Endpoint";
import appConstant from "constant";

class CustomerService {

  projection = {
    "order_by": {
      "first_name": "ASC"
    },
    /* "limit": 100, */
    "offset": 0
  };

  schema() {
  }

  /**
   * Get customer information
   * @param {*} article 
   * @returns 
  //  */
  // query() {
  //   const data = {
  //     "filter": {
  //       "id": appConstant.CUSTOMER_ID,
  //     },
  //     "return": [
  //       "delivery_methods",
  //     ]
  //   }
  //   return http().post("/customer/query", data);
  // }
  query(returnOption) {
    const data = {
      "filter": {
        "id": appConstant.CUSTOMER_ID,
      },
      "return": returnOption
    }
    return http().post("/customer/query", data);
  }

  list(data) {

  }

  update() {

  }

  add(data) {

  }

}

// eslint-disable-next-line  
export default new CustomerService();