import http from "./Endpoint";

class CostCenterService {
  costcenterlist(article) {
    return http().post("/costcenter/list", article);
  }
  CostDelete(data) {
    return http().post("/costcenter/delete", data);
  }
  CostAdd(data) {
    return http().post("/costcenter/add", data);
  }
  CostUpdate(data) {
    return http().post("/costcenter/update", data);
  }
 
  CostGet(costid) {
    
    let article = {
      "filter": {
          "id" : costid
      },
      "return": [
          "id",
          "name",
          "department_list"
      ]
  }
    return http().post("/costcenter/query", article);
  }
}

export default new CostCenterService();