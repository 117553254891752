import React from 'react'

function Footer() {
  return (
    <div className="no-print">
      <footer className="main-footer fixed-bottom">
      </footer>
    </div>
  )
}

export default Footer;
