import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form, Breadcrumb } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import appConstant from "constant";
import ProductService from "api/precision/ProductService";
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import ProductDestroy from "components/productreport/ProductDestroy";
import { ExportCsvFile } from 'common/functions';

function ProductReport() {
  const [CatOptions, setCatOptions] = useState([]);
  const [EnumOptions, setEnumOptions] = useState([]);
  const [searchData, setSearchData] = useState([]);

  useEffect(() => {
    document.title = 'Product Report'; // Set your desired page title here
  }, []);

  //#region Bind DropDown
  useEffect(() => {
    CategoryBind();
    EnumBind();
  }, []);

  const CategoryBind = () => {
    ProductService.categoriesdropdownlist()
      .then((response) => {
        // Extract the CatOptions from the API response
        const { data } = response.data;
        const catList = data.map((cat) => ({
          value: cat.id.toString(),
          label: cat.name,
        }));

        // Prepend the "ALL" option to the list
        //const allOption = { value: '0', label: 'ALL' };
        setCatOptions([...catList]);

      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }
  
  const EnumBind = () => {
    ProductService.productEnumdropdown()
      .then((response) => {
        // Extract the CatOptions from the API response
        const { data } = response.data;
        const enumlist = data.map((cat) => ({
          value: cat.id.toString(),
          label: cat.name,
        }));

        // Prepend the "ALL" option to the list
        //const allOption = { value: '0', label: 'ALL' };
        setEnumOptions([...enumlist]);

      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }
  
  //#endregion

  //#region Delete
  const [idToDelete, setIdToDelete] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const ModalDelete = (id) => {
    setShowModal(true);
    setIdToDelete(id);
  };
  //#endregion

  //#region  Search
  const [filter, SetFilter] = useState({
    product_category_id: 0,
    is_active: 1,
    quickbooks_type_id: 0,
    exclude_no_product: null,
  });

  useEffect(() => {
    // debugger
    handleSearch();
  }, [filter.product_category_id, filter.quickbooks_type_id]);

  const filterChanged = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    const field = ev.target.name;
    if (field == "is_active") {
      SetFilter({ ...filter, [field]: ev.target.checked });
    }
    else {
      filter[field] = ev.target.value;
      SetFilter({ ...filter, [field]: ev.target.value });
    }
  };

  const handleSearch = () => {
    ProductService.productlist(filter)
      .then((response) => {
        if (response.data.result == 1 && Array.isArray(response.data.data)) {
          setSearchData(response.data.data);
        }
        else {
          setSearchData([]);
        }
      })
      .catch((error) => {
        console.error("Error in  Productlist report api:", error);
      });
  };

  const exportProduct = (ev) => {
    let headers = {
      id: "ID",
      name: "Name",
      customer_sku: "SKU"
    };
    let filename = `nationwide_vet_products.csv`;
    ExportCsvFile(headers, searchData, filename);
  }


  const columns = [
    {
      name: "",
      selector: (row) => (
        <div className="">
          <Link to={"/Product/ProductDetail/" + row.id} className="text-primary me-2">Modify</Link>
          {
            row.is_in_inventory == 1 && row.quickbooks_type_id == 5 &&
            <a href="#" className="text-primary" onClick={() => ModalDelete(row.id)}>Destroy</a>
          }
        </div>
      ),
      sortable: true,
      width: "10rem",
    },
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      width: "6rem",
    },
    {
      name: "Product Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "SKU",
      selector: (row) => row.customer_sku,
      sortable: true,
    },
  ];
  //#endregion

  return (
    <Container className="pt-4 pb-4 small">
      <Breadcrumb>
        <Breadcrumb.Item active>Products</Breadcrumb.Item>
      </Breadcrumb>
      <Col>
        <Accordion key="0" defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Filter</Accordion.Header>
            <Accordion.Body>
              <Row className="mb-2">
                <Col lg={1} className="">
                  <Form.Label htmlFor="product_category_id">
                    Category:
                  </Form.Label>

                </Col>
                <Col lg={4}>
                  <Form.Select
                    aria-label="Default select example"
                    id="product_category_id"
                    name="product_category_id"
                    value={filter.product_category_id}
                    onChange={filterChanged}
                  >
                    <option key={0} value={0}>ALL</option>
                    {CatOptions && CatOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                </Col>

                <Col lg={1} className="mb-1 mb-lg-0 hidden" >
                  {/* //  htmlFor="practice_name"> */}
                  <Form.Label htmlFor="product_category_id">
                    Type:
                  </Form.Label>
                </Col>
                <Col lg={4} className="hidden">
                  <Form.Select
                    aria-label="Product type"
                    id="quickbooks_type_id"
                    name="quickbooks_type_id"
                    value={filter.quickbooks_type_id}
                    onChange={filterChanged}
                  >
                     <option key={0} value={0}>ALL</option>
                    {EnumOptions && EnumOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                </Col>

                <Col lg={2}>
                  <button type="button" className="btn btn-primary" onClick={exportProduct}>Export</button>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Col>
          <DataTable
            paginationPerPage={50}
            condense
            columns={columns}
            data={searchData}
            defaultSortFieldID={1}
            responsive={true}
            pagination
          />
        </Col>
      </Col>
      <ProductDestroy showModal={showModal} handleCloseModal={handleCloseModal} idToDelete={idToDelete} />
    </Container>

  );
}

export default ProductReport;
